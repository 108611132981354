import {
    Form,
    Button,
    useUpdate,
    useNotify,
    TextInput,
    useGetOne,
    useGetList,
    SaveButton,
    useGetMany,
    BooleanInput,
    useListContext,
    ReferenceInput,
    useRecordContext,
    AutocompleteInput,
} from "react-admin";
import React, { useState, useEffect } from 'react';
import IconCancel from '@mui/icons-material/Cancel';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

export const ReposicionExpo = ({ title }) => {

    const notify = useNotify();
    const [update] = useUpdate();
    const record = useRecordContext();
    const expId = record?.expID || [];
    const [motivo, setMotivo] = useState('');
    const { selectedIds } = useListContext();
    const [formValid, setFormValid] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const { data: expo } = useGetMany('exposicions', { ids: expId });
    const [reposicionChecked, setReposicionChecked] = useState(false);
    const [selectedUser, setSelectedUser] = useState({ id: '', name: '' });
  
    useEffect(() => {
        validateForm(reposicionChecked, selectedUser, motivo);
    }, [reposicionChecked, selectedUser, motivo]);
  
    const handleClick = () => {
        setShowDialog(true);
    };
  
    const handleCloseClick = () => {
        setShowDialog(false);
    };
  
    const handleSubmit = () => {
        const selectedExpo = expo?.filter(expoItem => selectedIds?.includes(expoItem?.id));
        selectedExpo?.forEach(expoItem => {
            if (expoItem?.reposicion) {
                update('ordenSalidas', { 
                    id: record?.id, 
                    data: { 
                        id: record?.id, 
                        reposicion: true 
                    },
                    previousData: record 
                }, {
                    onSuccess: () => notify(`Reposición para la orden ${record?.op}-${record?.version} aplicada con éxito.`, { type: 'success' }),
                    onFailure: () => notify(`Error al reponer la orden ${record?.op}-${record?.version}.`, { type: 'error' }),
                });
            } else {
                const dataUpdate = {
                    id: expoItem?.id, 
                    reposicion: true,
                    pendienteSalida: true,
                    motivo: motivo || expoItem?.motivo,
                    reposicionName: selectedUser?.name || expoItem?.reposicionName,
                    responsableReposicion: selectedUser?.id || expoItem?.responsableReposicion,
                };
                update('exposicions', { 
                    id: expoItem?.id, 
                    data: dataUpdate, 
                    previousData: expoItem
                }, {
                    onSuccess: () => notify('Solicitud y motivo agregados correctamente.', { type: 'success' }),
                    onFailure: () => notify('Error al reponer la exposición.', { type: 'error' }),
                });
        
                update('ordenSalidas', { 
                    id: record?.id, 
                    data: { 
                        id: record?.id, 
                        reposicion: true 
                    },
                    previousData: record 
                }, {
                    onSuccess: () => notify(`Reposición de la Orden ${record?.op}-${record?.version} exitosa.`, { type: 'success' }),
                    onFailure: () => notify(`Error al reponer la orden ${record?.op}-${record?.version}.`, { type: 'error' }),
                });
            }
        });
        handleCloseClick();
    };
  
    const handleMotivoChange = (e) => {
        setMotivo(e.target.value);
    };
  
    const handleReposicionChange = (e) => {
        setReposicionChecked(e.target.checked);
    };
  
    const validateForm = (reposicionChecked, selectedUser, motivo) => {
        if (reposicionChecked) {
            setFormValid(motivo && selectedUser?.id !== '');
        } else {
            setFormValid(true);
        };
    };
  
    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };
  
    return (
        <>
            <Button variant="contained" onClick={handleClick} label='Reponer Exposición'>
                <FlipCameraAndroidIcon />
            </Button>
            <Dialog fullWidth maxWidth={reposicionChecked ? 'sm' : 'xs'} open={showDialog} onClose={handleCloseClick}>
                <DialogTitle style={{ background: 'black', color: 'white' }}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography fontWeight='bold' variant="h5">{title}</Typography>
                    </Box>
                </DialogTitle>
                <Form resource="exposicions" id="create-exposicion" onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: reposicionChecked ? 'space-evenly' : 'center', mt: '1em' }}>
                            <Grid item sx={{ ml: reposicionChecked ? '12em' : '7em' }} xs={12}>
                                <BooleanInput label={reposicionChecked ? 'Cancelar Reposición' : 'Agregar Reposición'} source='reposicion' onChange={handleReposicionChange} />
                            </Grid>
                                {reposicionChecked && (
                                    <>
                                        <Grid item xs={6}>
                                            <ResponsableReposicion onUserSelect={handleUserSelect}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput fullWidth multiline maxRows={6} size='medium' label='Motivo' source='motivo' onChange={handleMotivoChange}/>
                                        </Grid>
                                    </>
                                )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseClick} label='ra.action.cancel' color='primary'>
                            <IconCancel/>
                        </Button>
                        <SaveButton disabled={formValid && reposicionChecked} />
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    );
};
  
const ResponsableReposicion = ({ onUserSelect }) => {

    const user = localStorage?.getItem('user');
    const { data: usuario } = useGetOne('usuarios', { id: user });
    const { data: users } = useGetList('usuarios', { pagination: { page: 1, perPage: 50 }, meta: { searchable: 'true' } });
    const sedeUser = usuario?.sedes || [];
    const filteredUsers = users?.filter(user => {
        const roles = user?.roles || [];
        const sedes = user?.sedes || [];
        const filterRole =
            roles?.includes('admin') ||
            roles?.includes('coord') ||
            roles?.includes('diseno') ||
            roles?.includes('calidad') ||
            roles?.includes('produccion') ||
            roles?.includes('diseno_externo');
        const filterSede = sedes?.some(sede => sedeUser?.includes(sede));
        return filterRole && filterSede;
    });
    
    const userChoices = filteredUsers?.map(user => ({
        id: user?.id,
        nombres: user?.nombres,
        apellidos: user?.apellidos,
    }));
    
    const handleUserChange = (userId) => {
        const user = filteredUsers?.find(user => user?.id === userId);
        if (user) {
            const nameResponsable = `${user.nombres} ${user?.apellidos}`;
            onUserSelect({ id: user?.id, name: nameResponsable });
        } else {
            onUserSelect({ id: '', name: '' });
        }
    };
    
    return (
        <ReferenceInput fullWidth source='responsableReposicion' reference='usuarios' required perPage={50}>
            <AutocompleteInput
                fullWidth
                label="Reposicion"
                onChange={handleUserChange}
                source='responsableReposicion'
                choices={userChoices ? userChoices : []}
                optionText={user => `${user?.nombres} ${user?.apellidos}`}
            />
        </ReferenceInput>
    );
};