import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import CheckIcon from '@mui/icons-material/Check';
import IconCancel from '@mui/icons-material/Cancel';
import { DialogCaja } from '../style/ExposicionStyles';
import TableContainer from '@mui/material/TableContainer';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Form, Button, useUpdate, useNotify, SaveButton, useGetList, useRecordContext } from 'react-admin';

export const DialogCajaStock = ({ responsableId }) => {

    const notify = useNotify();
    const [update] = useUpdate();
    const record = useRecordContext();
    const referencia = record?.referencia;
    const [showDialog, setShowDialog] = useState(false);
                                                                                                                                                                                                                                                                               
    const { data: stock } = useGetList('stocks', {
        meta: { searchable: 'true' },
        filter: { materialID: { eq: referencia }},
        sort: { field: 'consecutivo', order: 'DESC' },
    });
    
    const filterCajaActivaIngresada = stock?.filter(caja => caja?.activa === true && caja?.ingresada === true) || [];
    const filterCajaActiva = stock?.filter(caja => caja?.activa === true && (caja?.ingresada === false || caja?.ingresada === null || caja?.ingresada === undefined)) || [];
    const filterCajaIngresada = stock?.filter(caja => caja?.activa === false && caja?.ingresada === true) || [];
    const maxConsecutivoIngresada = filterCajaActivaIngresada?.length > 0 ? Math.max(...filterCajaActivaIngresada?.map(caja => caja?.consecutivo)) : -1;
    let nextConsecutiveBox = filterCajaActiva?.find(caja => caja?.consecutivo === maxConsecutivoIngresada + 1);
  
    if (!nextConsecutiveBox) {
        nextConsecutiveBox = filterCajaIngresada?.find(caja => caja?.consecutivo === maxConsecutivoIngresada + 1);
    };
  
    if (!nextConsecutiveBox && filterCajaActiva?.length > 0) {
        nextConsecutiveBox = filterCajaActiva[0];
    };
  
    const cajaId = nextConsecutiveBox?.id;
    const activa = nextConsecutiveBox?.activa;
    const cantidad = nextConsecutiveBox?.cantidad;
    const areaTotal = nextConsecutiveBox?.areaTotal;
    const ingresada = nextConsecutiveBox?.ingresada;
    const materialID = nextConsecutiveBox?.materialID;
    const anchoLamina = nextConsecutiveBox?.anchoLamina;
    const largoLamina = nextConsecutiveBox?.largoLamina;
    const consecutivo = nextConsecutiveBox?.consecutivo;

    const filterConsecutivo = consecutivo !== null && consecutivo !== undefined;

    const handleClick = () => setShowDialog(true);
    const handleCloseClick = () => setShowDialog(false);
  
    const handleSubmit = async () => {
        if (!filterCajaActiva) {
            notify('No hay cajas disponibles para ingresar', { type: 'warning' });
            console.log('No hay cajas disponibles para ingresar');
            return;
        };
  
        const lastActiveIngresadaCaja = filterCajaActivaIngresada?.reduce((maxCaja, caja) => {
            return caja?.consecutivo > maxCaja?.consecutivo ? caja : maxCaja;
        }, { consecutivo: -1 });
  
        console.log('Última caja activa e ingresada', lastActiveIngresadaCaja);
  
        const nextConsecutiveBox = 
            filterCajaActiva?.find(caja => caja?.consecutivo === lastActiveIngresadaCaja?.consecutivo + 1) ||
            filterCajaIngresada?.find(caja => caja?.consecutivo === lastActiveIngresadaCaja?.consecutivo + 1) ||
            filterCajaActiva[0];
            console.log('Siguiente caja consecutiva para actualizar', nextConsecutiveBox);
  
        if (!nextConsecutiveBox) {
            notify('No se encontró la siguiente caja consecutiva para actualizar', { type: 'error' });
            return;
        }
  
        const cajaConsecutivoId = nextConsecutiveBox?.id;
        console.log('cajaConsecutivoId', cajaConsecutivoId);
        const responsablePinId = responsableId ?? nextConsecutiveBox?.responsableId;
  
        if (!responsableId) {
            notify('Digite el PIN del responsable para ingresar la caja', { type: 'warning' });
            return;
        }
  
        let dataToUpdate;
        if (nextConsecutiveBox?.ingresada === false || nextConsecutiveBox?.ingresada === null || nextConsecutiveBox?.ingresada === undefined) {
            dataToUpdate = {
                ingresada: true,
                usuarioIngresa: responsablePinId,
            };
        } else if (nextConsecutiveBox?.activa === false && nextConsecutiveBox?.ingresada === true) {
            dataToUpdate = {
                activa: true,
                ingresada: true,
                usuarioActiva: responsablePinId,
                usuarioIngresa: responsablePinId,
            };
        } else {
            notify('La caja ya está en el estado deseado', { type: 'warning' });
            return;
        }
  
        update('stocks', {
            id: cajaConsecutivoId,
            data: {
                id: cajaConsecutivoId,
                ...dataToUpdate,
            },
            previousData: nextConsecutiveBox,
        },
        {
            onSuccess: () => {
                setShowDialog(false);
                notify('Caja actualizada con éxito');
            },
            onFailure: (error) => {
                console.error('Error al actualizar la caja:', error);
                notify('Error al actualizar la caja:', { type: 'error' });
            }
        });
    };

    return (
        <>
            {filterCajaActivaIngresada && filterCajaActivaIngresada?.length > 0 ? ( 
                <Button startIcon={<AddBoxRoundedIcon style={DialogCaja?.iconButton}/>} variant='rounded' onClick={handleClick} sx={DialogCaja}/>
            ) : (
                <span/>
            )}
            {filterCajaActivaIngresada && filterCajaActivaIngresada?.length > 0 ? ( 
                <Dialog fullWidth maxWidth={filterConsecutivo ? 'md' : 'sm'} open={showDialog} onClose={handleCloseClick}>
                    <Form resource="stocks" onSubmit={handleSubmit} sanitizeEmptyValues>
                        <>
                            {filterConsecutivo ? (
                                <DialogTitle style={{ background: 'black', color: 'white' }}>
                                    <div style={{ fontWeight: 'bold', textAlign:'center' }}>
                                        {'Gestión de Caja'}
                                    </div>
                                </DialogTitle>
                            ):(
                                <span/>
                            )}
                            <DialogContent>
                                <DialogContentText>
                                    {filterConsecutivo ? (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 450 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center' style={{ fontWeight: 'bold' }}>Caja</TableCell>
                                                        <TableCell align='center' style={{ fontWeight: 'bold' }}>Material</TableCell>
                                                        <TableCell align='center' style={{ fontWeight: 'bold' }}>Ancho</TableCell>
                                                        <TableCell align='center' style={{ fontWeight: 'bold' }}>Largo</TableCell>
                                                        <TableCell align='center' style={{ fontWeight: 'bold' }}>No. Láminas</TableCell>
                                                        <TableCell align='center' style={{ fontWeight: 'bold' }}>Área Total</TableCell>
                                                        <TableCell align='center' style={{ fontWeight: 'bold' }}>Activa</TableCell>
                                                        <TableCell align='center' style={{ fontWeight: 'bold' }}>Ingresada</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={cajaId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell align='center'>{consecutivo}</TableCell>
                                                        <TableCell align='center'>{materialID}</TableCell>
                                                        <TableCell align='center'>{anchoLamina}</TableCell>
                                                        <TableCell align='center'>{largoLamina}</TableCell>
                                                        <TableCell align='center'>{cantidad}</TableCell>
                                                        <TableCell align='center'>{areaTotal}</TableCell>
                                                        <TableCell align='center'>{activa ? <CheckIcon/> : ''}</TableCell>
                                                        <TableCell align='center'>{ingresada ? <CheckIcon/> : ''}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : (
                                        <div>
                                            {`No existe una caja para ingresar, espere a que haya una activa.`}
                                        </div>
                                    )}
                                </DialogContentText>
                            </DialogContent>
                            {filterConsecutivo ? (
                                <DialogActions style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
                                    <Button label="ra.action.cancel" onClick={handleCloseClick}>
                                        <IconCancel/>
                                    </Button>
                                    <SaveButton icon={<SendAndArchiveIcon/>} label='Ingresar' alwaysEnable />
                                </DialogActions>
                            ) : (
                                <span/>
                            )}
                        </>
                    </Form>
                </Dialog>
            ):(
                <span/>
            )}
        </>
    );
};