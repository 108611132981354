import { useFormContext } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import IconCancel from '@mui/icons-material/Cancel';
import OutputIcon from '@mui/icons-material/Output';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { UserAnulacion, UserReposicion } from '../components/InputsMedidas';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, DialogContentText } from '@mui/material';
import { Form, Button, useNotify, TextInput, useUpdate, SaveButton, useRedirect, RadioButtonGroupInput } from 'react-admin';

export const AnulacionSolicitud = ({ expo }) => {

    const notify = useNotify();
    const redirect = useRedirect();
    const [motivo, setMotivo] = useState('');
    const { setValue, getValues } = useFormContext();
    const [update, { isLoading, error }] = useUpdate();
    const [showDialog, setShowDialog] = useState(false);
    const [tipoSolicitud, setTipoSolicitud] = useState('');
    const [selectedUser, setSelectedUser] = useState({ id: '', name: '' });
    const lenEmpty = Array?.isArray(expo?.len) && expo?.len?.length === 0;
      
    useEffect(() => {
      if (lenEmpty) {
        setTipoSolicitud('anulada');
      }
    }, [setTipoSolicitud]);
  
    useEffect(() => {
      if (expo?.motivo) {
        setMotivo(expo?.motivo);
      };
    }, [expo]);
  
    useEffect(() => {
      setSelectedUser({ id: expo?.responsableId, name: expo?.responsableName });
    }, [expo]);  
  
    const handleClick = () => setShowDialog(true);
    const handleCloseClick = () => setShowDialog(false);
    const handleMotivoChange = (e) => setMotivo(e?.target?.value);
    const handleActionChange = (e) => {
      setTipoSolicitud(e?.target?.value);
      setSelectedUser({ id: '', name: '' });
    };
  
    const handleSubmit = async (values) => {
      try {
        const isAnulada = tipoSolicitud === 'anulada';
        const isReposicion = tipoSolicitud === 'reposicion';
        const motivo = values?.motivo !== '' ? values?.motivo : expo?.motivo;
    
        if (!expo?.id) {
          notify('Error: El ID del registro es nulo o indefinido.', { type: 'error' });
          return;
        };
    
        if (!motivo) {
          notify('El motivo es obligatorio.', { type: 'error' });
          return;
        };
    
        if (isAnulada && (!selectedUser?.id || !selectedUser?.name)) {
          notify('El responsable para la anulación es obligatorio.', { type: 'error' });
          return;
        };
    
        if (isReposicion && (!selectedUser?.id || !selectedUser?.name)) {
          notify('El responsable para la reposición es obligatorio.', { type: 'error' });
          return;
        };
    
        const dataToUpdate = {
          id: expo?.id,
          motivo: motivo,
          anulada: isAnulada,
          reposicion: isReposicion,
          responsableId: isAnulada ? selectedUser?.id : expo?.responsableId,
          responsableName: isAnulada ? selectedUser?.name : expo?.responsableName,
          reposicionName: isReposicion ? selectedUser?.name : expo?.reposicionName,
          responsableReposicion: isReposicion ? selectedUser?.id : expo?.responsableReposicion,
        };
    
        if (!dataToUpdate?.id) {
          notify('Error: No se puede actualizar sin un ID válido.', { type: 'error' });
          return;
        };
    
        await update('exposicions', {
          id: expo?.id,
          data: dataToUpdate,
          previousData: expo,
        },{
          onSuccess: async () => {
            if (isAnulada) {
              setValue('motivo', motivo);
              setValue('anulada', true);
              setValue('responsableId', selectedUser?.id || expo?.responsableId);
              setValue('responsableName', selectedUser?.name || expo?.responsableName);
              setShowDialog(false);
              redirect('list', 'exposicions');
              notify('Se agregó el motivo y el responsable para anulación.');
            } else if (isReposicion) {
              setValue('motivo', motivo);
              setValue('reposicion', true);
              setValue('responsableReposicion', selectedUser?.id || expo?.responsableReposicion);
              setValue('reposicionName', selectedUser?.name || expo?.reposicionName);
              setShowDialog(false);
              notify('Se agregó el motivo y el responsable para reposición.');
            }
          },  
          onFailure: () => {
            redirect('list', 'exposicions');
            console.error('Error al agregar la solicitud:', error?.message || error);
          }}
        );
        console.log('Antes de Agregar el Motivo', expo);
        console.log('Después de Agregar el Motivo', values);
      } catch (error) {
        setShowDialog(false);
        console.error('Error al agregar la solicitud:', error?.message || error);
        notify('Error al agregar la solicitud. Intenta nuevamente.', { type: 'error' });
      };
    };
    
    const isSaveButtonDisabled = () => {
      if (lenEmpty) {
        return !motivo && tipoSolicitud === 'anulada';
      } else {
        if (tipoSolicitud === 'anulada') {
          return !motivo;
        } else if (tipoSolicitud === 'reposicion') {
          return !motivo || !selectedUser.id;
        };
      };
      return true;
    };
  
    if (error) {
      return <Button sx={{ color: 'white', fontWeight: 'bold', background: 'red' }}>
        {`Error`} {error?.message}
      </Button>
    };
  
    const valuesInput = getValues(['motivo', 'reposicion', 'responsableReposicion', 'reposicionName', 'anulada', 'responsableId', 'responsableName']);
    const filterButton = expo?.reposicion === true || valuesInput?.[1] === true;
  
    return (
      <>
        {filterButton ? (
          <span/>
        ):(
          <>
            <Button variant='contained' onClick={handleClick} sx={{ fontSize: 'small' }} label='Solicitar anulación' startIcon={<CancelScheduleSendIcon/>} style={{ background: 'black', color: 'white' }}/>
            <Dialog fullWidth maxWidth={ tipoSolicitud && lenEmpty ? 'xs' : tipoSolicitud ? 'sm' : 'xs' } open={showDialog} onClose={handleCloseClick}>
              <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'black', color: 'white' }}>
                <Typography style={{ fontWeight: 'bold' }}>
                  {lenEmpty ? 'Seleccionar el responsable y agregar un motivo' : 'Seleccionar solicitud y agregar motivo'}
                </Typography>
              </DialogTitle>
              <Form resource='exposicions' onSubmit={handleSubmit}>
                <DialogContent>
                  {!tipoSolicitud && (
                    <Grid item container>
                      <DialogContentText textAlign='center'>
                        <Typography variant='body2' display='block' textAlign={'center'}>
                          {`Por favor seleccione la solicitud, luego escoja el responsable y escriba el motivo de la solicitud.`}
                        </Typography>
                      </DialogContentText>
                    </Grid>
                  )}
                  <Grid container spacing={2} justifyContent='space-between' flexDirection={tipoSolicitud ? 'row' : 'column'}>
                    {lenEmpty ? (
                      <Grid container sx={{ marginTop: lenEmpty ? '2em' : '0em' }}>
                        <Grid item lg={12}>
                          <UserAnulacion onUserSelect={setSelectedUser}/>
                        </Grid>
                        <Grid item lg={12}>
                          <TextInput fullWidth multiline maxRows={4} size='small' label='Motivo' source='motivo' onChange={handleMotivoChange}/>
                        </Grid>
                      </Grid>
                    ):(
                      <>
                        <Grid 
                          item xs={ tipoSolicitud ? 2 : 11.5 }
                          sx={{
                            display: 'flex', 
                            marginTop: tipoSolicitud ? '0rem' : '1em', 
                            marginLeft: tipoSolicitud ? '0rem' : '1em', 
                            marginBottom: tipoSolicitud ? '0rem' : '-1em', 
                            justifyContent: tipoSolicitud ? 'flex-start' : 'center'
                          }}
                        >
                          <RadioButtonGroupInput
                            size='small'
                            source='TipoSolicitud'
                            onChange={handleActionChange}
                            row={tipoSolicitud ? false : true}
                            sx={{ marginTop: tipoSolicitud ? '-0.5rem' : '0rem' }}
                            choices={[ { id: 'anulada', name: 'Anulación' }, { id: 'reposicion', name: 'Reposición' }]}
                            label={<div style={{ marginLeft: tipoSolicitud ? '0rem' : '5.5em' }}>Tipo de Solicitud</div>}
                          />
                        </Grid>
                        <Grid item xs={8.5} sm={9.2} md={9.5} lg={9.5} xl={9.5} style={{marginTop: '0.8em'}}>
                          <Grid container>
                            {tipoSolicitud && (
                              <Grid item xs={12}>
                                <TextInput fullWidth multiline maxRows={4} size='small' label='Motivo' source='motivo' onChange={handleMotivoChange}/>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              {tipoSolicitud === 'anulada' && <UserAnulacion onUserSelect={setSelectedUser}/>}
                              {tipoSolicitud === 'reposicion' && <UserReposicion onUserSelect={setSelectedUser}/>}
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
                  <Button label='ra.action.cancel' onClick={handleCloseClick} disabled={isLoading}>
                    <IconCancel/>
                  </Button>
                  <SaveButton size='small' icon={<OutputIcon/>} label='Enviar Solicitud' disabled={isSaveButtonDisabled()}/>
                </DialogActions>
              </Form>
            </Dialog>
          </>
        )}
      </>
    );
  };