import { Sucursales } from '../../utils';
import React, { useEffect, useState } from 'react';
import { DialogSalida } from './components/SalidaOrden';
import { OrdenFilters } from './components/OrdenFilters';
import { ListStyled, StyledDatagridOrden } from './styles/OrdenSalidaStyles';
import { List, Datagrid, TextField, ArrayField, NumberField, useGetOne } from 'react-admin';
import { Detalles, DialogSalidaOrden, IconState, TopBarOrden } from './components/VariousComponents';

export const OrdenSalidaList = () => {
  
  const [record, setRecord] = useState(null);
  const [showFilters, setShowFilters] = useState(true);

  const toggleFilters = () => setShowFilters(!showFilters);

  const user = localStorage?.getItem('user');
  const [filterCity, setFilterCity] = useState({});
  const { data: usuario } = useGetOne('usuarios', { id: user });

  useEffect(() => {
    if (usuario && usuario?.sedes && usuario?.sedes?.length > 0) {
      const filterSedeUsuario = usuario?.sedes?.map(sede => {
        const ciudadSucursal = Sucursales?.find(sucursal => sucursal?.user === sede)?.id;
        return ciudadSucursal ? { ciudadOrden: { eq: ciudadSucursal }} : null;
      })?.filter(ciudad => ciudad !== null);
      if (filterSedeUsuario?.length > 0) {
        setFilterCity({ or: filterSedeUsuario });
        console.log('FilterCity set:', { or: filterSedeUsuario });
      } else {
        setFilterCity({});
        console.log('No se aplicó el filtro, no hay ciudades encontradas.');
      };
    };
  }, [usuario]);

  return (
    <>
      <List
        rowClick={false}
        filter={filterCity}
        render={(record) => setRecord(record)}
        aside={showFilters ? <OrdenFilters /> : null}
        actions={<TopBarOrden showFilters={showFilters} toggleFilters={toggleFilters}/>}
        // queryOptions={{ meta: { searchable: 'true', filterable: ['clienteOrden', 'clienteOrdenId']}}}
      >
        <Datagrid expandSingle expand={<Detalles/>} sx={StyledDatagridOrden} bulkActionButtons={false}>
          <IconState source='estado' label='ESTADO'/>
          <NumberField source='op' label='OP' sx={ListStyled?.componentFont} options={{ useGrouping: false }}/>
          <NumberField source='version' label='VERSIÓN' sx={ListStyled?.componentFont} options={{ useGrouping: false }}/>
          <TextField source='nameJob' label='TRABAJO' sx={ListStyled?.componentFont}/>
          <TextField source='tipoJob' label='TIPO TRABAJO' sx={ListStyled?.componentFont}/>
          <TextField source='clienteOrden' label='CLIENTE' sx={ListStyled?.componentFont}/>
          <NumberField source='totalPlanchasOrden' label='PLANCHAS (ORDEN)' sx={ListStyled?.componentFont}/>
          <NumberField source='totalPlanchasExp' label='PLANCHAS (EXP)' sx={ListStyled?.componentFont}/>
          <NumberField source='calibreOrden' label='CALIBRE (ORDEN)' sx={ListStyled?.componentFont}/>
          <ArrayField source='calibresExp' label='CALIBRE (EXP)'>
            <TextField source='calibresExp' sx={ListStyled?.componentFont}/>
          </ArrayField>
          <DialogSalidaOrden label='SALIDA' source='salidaOrdenes' setRecord={setRecord} sx={ListStyled?.componentFont}/>
        </Datagrid>
      </List>
      {record && <DialogSalida data={record}/>}
    </>
  );
};