import React, { useState } from "react";
import { IconButton, Popover, Typography, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useRecordContext } from "react-admin";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const ObservacionesCliente = () => {
    const record = useRecordContext();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton
                size='small'
                sx={{ pt: 1    }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                variant="outlined"
            >
                <RemoveRedEyeIcon/>
            </IconButton>
            <Popover
                sx={{
                    pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ p: 2, maxWidth: 300 }}>
                    <Typography variant="h6">Observaciones:</Typography>
                    <Typography variant="body2">
                        {record.clientData?.observaciones || "Sin Observaciones"}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Precios:
                    </Typography>
                    {record.clientData?.datosFacturacion?.precioOrden?.map((order, index) => (
                        <Typography key={index} variant="body2">
                            {order.tipoOrden}: ${order?.valor?.toFixed(2)||0}
                        </Typography>
                    ))}
                </Box>
            </Popover>
        </>
    );
};

export default ObservacionesCliente;
