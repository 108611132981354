import { ImageCDI } from './ImageCDI';
import React, { useState, useEffect } from 'react';
import ReportIcon from '@mui/icons-material/Report';
import { LenStyled } from '../style/ExposicionStyles';
import { ChatBubble } from '../../../utils/chatBubbleObserv';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'; 
import { checkOp, checkLen, compararArrays, findPantoneColor, extractColorLenId } from '../../../utils/utilsExposicion';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Tooltip, Fade, LinearProgress } from '@mui/material';
import { useList, Datagrid, useGetList, NumberField, FunctionField, useRecordContext, ListContextProvider } from 'react-admin';
  
export const LenContentView = () => {
    
    const record = useRecordContext();
    const calibreExp = record?.calibre
    const [lenData] = useState(record?.len || []);
    const { data: dataOrdenSalidas, isLoading: loadingOrdenSalidas } = useGetList('OrdenSalidas');
  
    const filterObsr = record?.len?.map(len => ({
        NUMERO: parseInt(len?.op),
        VERSIONOP: parseInt(len?.version),
    }));
    
    const { data: dataObs } = useGetList('sqlObservaciones', {
        filter: { or: filterObsr },
        pagination: { page: 1, perPage: 20 }
    });
  
    useEffect(() => {
        const functionLenContent = async () => {
            if (dataOrdenSalidas && dataOrdenSalidas.length > 0) {
                const lenDataArray = await checkLen(organizedLen, calibreExp);
                const opDataArray = await checkOp(dataOrdenSalidas);
                const showIcons = compararArrays(opDataArray, lenDataArray);
                console.log('ICONS ARRAY', showIcons);
            }
        };
  
        if (!loadingOrdenSalidas) {
            functionLenContent();
        }
    }, [loadingOrdenSalidas, dataOrdenSalidas, calibreExp]);
  
    const groupByOpAndVersion = (lenArray) => {
        return lenArray?.reduce((acc, curr) => {
            const key = `${curr?.op}-${curr?.version}`;
            if (!acc[key]) {
                acc[key] = {
                    data: [],
                    op: curr?.op,
                    version: curr?.version,
                    totalPlanchasExp: 0,
                };
            }
            acc[key].data.push(curr);
            acc[key].totalPlanchasExp = acc[key].data?.length;
            return acc;
        }, {});
    };
  
    const organizedLen = groupByOpAndVersion(lenData);  
    if (loadingOrdenSalidas) {
        return <LinearProgress/>;
    };
  
    return (
        <div style={{ marginTop: '2em' }}>
            {Object?.entries(organizedLen)?.map(([key, lenGroup]) => {
                const [op, version] = key?.split('-');
                const totalPlanchasExp = Array?.isArray(lenGroup?.data) ? lenGroup?.data?.length : 0;
                const shouldShowEditButton = Array?.isArray(lenGroup?.data) && lenGroup?.data?.some(item => !item?.op || !item?.version);
                const observaciones = dataObs?.filter(observacion => 
                    observacion?.NUMERO === parseInt(op) && observacion?.VERSIONOP === parseInt(version)
                );
                const dataLen = lenGroup?.data;
                const hasLenReposicion = dataLen?.some(item => item?.reposicion === true);
                return (
                    <Accordion square key={key} sx={LenStyled?.accordion}>
                        <AccordionSummary sx={LenStyled?.accordion?.accordionSummary} id={`${key}-header`} aria-controls={`${key}-content`} expandIcon={<ExpandMoreIcon/>}>
                            <Typography style={{ marginLeft: shouldShowEditButton ? '2.3em' : '0.5em' }} variant='h6' sx={LenStyled?.accordion?.typography}>
                                {`OP: ${op}-${version} - Total Planchas (${totalPlanchasExp})`}
                            </Typography>
                            {hasLenReposicion && (
                                <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title={'Hay len(s) marcados como reposición'}>
                                    <ReportIcon color='warning' sx={{ marginTop: '1.5px', marginLeft: '5px',  fontSize: '28px'}}/>
                                </Tooltip>
                            )}
                        </AccordionSummary>
                        <AccordionDetailsCustom newList={dataLen} obs={observaciones}/>
                    </Accordion>
                );
            })}
        </div>
    );
};
  
const AccordionDetailsCustom = (props) => {
  
    const { newList, obs } = props;
    const listContext = useList({ data: newList });  
    const hasRepo = listContext?.data?.every(item => item?.reposicion === true);
  
    return (
        <>
            <AccordionDetails>
                <ChatBubble observaciones={obs}/>
                    <Grid style={{ marginTop: '1rem' }}>
                        <ListContextProvider value={listContext}>
                            <Datagrid sx={LenStyled?.datagrid} bulkActionButtons={false}>
                                <ImageCDI source='image' label='IMAGEN'/>
                                <PantoneColor source='pantone' label='COLOR' dataLen={newList}/>
                                <NumberField source='uuid' label='UUID' sx={LenStyled?.componentStyled}/>
                                <NumberField source='name' label='NOMBRE' sx={LenStyled?.componentStyled}/>
                                <NumberField source='lenWidth' label='ANCHO' sx={LenStyled?.componentStyled}/>
                                <NumberField source='lenHeight' label='ALTURA' sx={LenStyled?.componentStyled}/>
                                {hasRepo ? (
                                    <NumberField source='area' label='ÁREA' sx={LenStyled?.componentStyled}/>
                                ):(
                                    <AreaLenWithRepo source='area' lens={listContext}/>
                                )}
                            </Datagrid>
                        </ListContextProvider>
                    </Grid>
            </AccordionDetails>
        </>
    );
};
  
const AreaLenWithRepo = () => {
    return (
        <FunctionField
            label='Reposición'
            source='area'
            render={record => 
                record?.reposicion === true ? (
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={1} md={1}>
                            <Tooltip title="Este len está marcado como reposición">
                                <PriorityHighIcon color="error" sx={{ marginLeft: '4.7em', position: 'relative'}}/>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={11} md={11} sx={{ marginTop: '-3.2em'}}>
                            <NumberField source="area" label="ÁREA" sx={LenStyled?.componentStyled}/>
                        </Grid>
                    </Grid>
                ) : <NumberField source='area' label='ÁREA' sx={LenStyled?.componentStyled}/>
            }
        />
    );
};
  
const PantoneColor = () => {
    return(
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <FunctionField 
                label='COLOR'
                source='color'
                render={(dataLen) => { 
                    const colorCode = extractColorLenId(dataLen?.uuid);
                    const pantoneColor = findPantoneColor(colorCode);
                    if (pantoneColor) {
                        const { R, G, B, name } = pantoneColor;
                        const colorStyle = {
                            width: '55px',
                            height: '60px',
                            borderRadius: '10px 100px / 120px',
                            backgroundColor: `rgb(${R}, ${G}, ${B})`
                        };
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={colorStyle}/>
                                <Typography variant='body2' sx={{ marginTop: '8px', textAlign: 'center', fontWeight: 'bold', fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'}}}>
                                    {name}
                                </Typography>
                            </div>
                        );
                    }
                return <span/>;
                }} 
            />
        </Grid>
    );
};