import * as React from 'react';

import { Box, Stack, Divider } from '@mui/material';

import { TextField, Datagrid, NumberField, BooleanField, useRecordContext, useGetList , ReferenceField,
    UrlField
} from 'react-admin';


const sort = { field: 'id', order: 'DESC' };

export default function ItemsDatagridShow() {

    const record = useRecordContext();
    
    //console.log('record inside datagrid', record);
    const { data, total, isPending, error } = useGetList(
        'productFacts',
        {   
            filter: { facturaID: {eq: record.id} },
            pagination: { page: 1, perPage: 1000 },
            meta: {searchable: 'true'},
            sort: { field: 'ordenVersion', order: 'DESC' }
        }
    );
    //console.log('data items datagrid show', data);  

    return (
        <Box sx={{ mt: '10px' }}>
            <Datagrid 
                data={data}
                total={total}
                sort={sort}
                bulkActionButtons={false}
            >
                <TextField source='ordenVersion' label='Orden'  />
                <ReferenceField source="remisionID"  reference='remissions'  label='Remision' sx={{color:'#000642'}}>
                    <TextField source="alegraNumeracion" sx={{ fontWeight:'bold'}}  />
                </ReferenceField>
                <TextField source="descripcion" />
                <TextField source="caja" />                
                <NumberField source="ancho" />
                <NumberField source="largo"/>
                <NumberField source="cantidad"/>
                <NumberField source="area"/>
                <NumberField source="valorUnitario" label='V. Unitario'/>
                <BooleanField source="iva" />
                <NumberField source="valorTotal" label='V. Total' />
           </Datagrid>
        </Box>
    );
}