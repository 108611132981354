import cities from './cities.json'
import countries from './countries.json'
import states from './states.json'
import SolidCoated from './pantoneSolidCoated.json'

export const CountryList = countries
export const StateList = states
export const CityList = cities
export const PSC = SolidCoated

export const mapUserGroupNames = [
    {id:'admin',name:'ADMINISTRADOR'}, 
    {id:'coord',name:'COORDINACIÓN'}, 
    {id:'diseno',name:'DISEÑO'}, 
    {id:'calidad',name:'CALIDAD'}, 
    {id:'agc',name:'ACG'}, 
    {id:'produccion',name:'PRODUCCIÓN'}, 
    {id:'facturacion',name:'FACTURACIÓN'}, 
    {id:'guest',name:'INVITADO'},
    {id:'diseno_externo',name:'DISEÑADOR EXTERNO'},
    {id:'root',name:'SU'},
    {id:'facturacion',name:'FACTURACION'},
    {id:'inventarios',name:'INVENTARIOS'},
    ]

export const FpsDedos =[
    {id:'pulgarIzq', name: "Pulgar Izquierdo"},
    {id:'pulgarDer', name: "Pulgar Derecho"},
    {id:'indiceIzq', name: "Indice Izquierdo"},
    {id:'indiceDer', name: "Indice Derecho"}
]

export const FpsDevices=[    
    {id:'fpsenv', name: "FPS_ENVIGADO"},
    {id:'fpsbaq', name: "FPS_BARRANQUILLA"},
    {id:'fpscali', name: "FPS_CALI"}
    ]


export const TiposDocumentos = [
    {id:'RC',name:'Registro Civil'},
    {id:'TI', name:'Tarjeta de Identidad'},
    {id:'CC', name:'Cedula de Ciudadania'},
    {id:'TE', name:'Tarjeta Extranjeria'},
    {id:'CE', name:'Cedula Extrangeria'},
    {id:'NIT', name:'NIT'},
    {id:'PP', name:'Pasaporte'},
    {id:'DIE', name:'Documento de identificación extranjero'},
    {id:'FOREIGN_NIT', name:'NIT Otro Pais'},
    {id:'NUIP', name:'NUIP'}
];

export const TiposPersonas = [
    { id: 'LEGAL_ENTITY' , name: 'PERSONA JURIDICA' },
    { id: 'PERSON_ENTITY' , name: 'PERSONA NATURAL' },
    { id: 'OTHER_ENTITY' , name: 'OTRO TIPO' },
];


export const Sectores = [
    {id:'FLEXIBLES',name:'FLEXIBLES'},
    {id:'ETIQUETAS',name:'ETIQUETAS'},
    {id:'CORRUGADOS',name:'CORRUGADOS'},
    {id:'SACOS',name:'SACOS'},
    {id:'SACHET',name:'SACHETS'},
    {id:'BLISTER',name:'BLISTER'},
    {id:'VASOS_CARTON',name:'VASOS_CARTON'},
    {id:'MARQUILLAS_TELAS',name:'MARQUILLAS_TELAS'},
    {id:'TAPAS',name:'TAPAS'},
    {id:'OTROS',name:'OTROS'},    
]

export const Lineatura = [
    {id: 37, name: "37"},
    {id: 49, name: "49"},
    {id: 62, name: "62"},
    {id: 74, name: "74"},
    {id: 87, name: "87"},
    {id: 99, name: "99"},
    {id: 112, name: "112"},
    {id: 124, name: "124"},
    {id: 136, name: "136"},
    {id: 149, name: "149"},
    {id: 161, name: "161"},
    {id: 198, name: "198"},
    ]
  
export const  Retenciones = [
    {
        idAlegra: 14,
        porcentaje: 4,
        name: "Rte Fte Servicios",
        idApp: 'servicios',
        base: 'subtotal'
    },
    {
        idAlegra: 15,
        porcentaje: 2.5,
        name: "Rte Fte Compras",
        idApp: 'compras',
        base: 'subtotal'
    },
    {
        idAlegra: 18,
        porcentaje: 15,
        name: "Rte IVA",
        idApp: 'reteIva',
        base: 'iva'
    },
    {
        idAlegra: 19,
        porcentaje: 1,
        name: "Rte ICA",
        idApp: 'reteIca1',
        base: 'subtotal'
    },
    {
        idAlegra: 22,
        porcentaje: 0.6,
        name: "Rte ICA",
        idApp: 'reteIva0.6',
        base: 'subtotal'
    },  
    {
        idAlegra: 23,
        porcentaje: 0.18,
        name: "Rte ICA",
        idApp: 'reteIva0.18',
        base: 'subtotal'
    },
    {
        idAlegra: 24,
        porcentaje: 1.16,
        name: "Rte ICA",
        idApp: 'reteIva1.16',
        base: 'subtotal'
    },
]

export const valoresPredeterminados = {
    'iva': 19,
    'ica': 0.01,
    'servicios': 4,
    'compras': 2.5,
    'reteiva': 15
}
export const BaseRetenciones = [
    {id:'servicios', name: 'RetefuenteServicios', valor:188000 },
    {id:'compras', name: 'RetefuenteCompras', valor: 1271000},
]

export const Sucursales = [
    {id:'ENVIGADO', name: 'Envigado', short: 'E', alegraID: 1, oldOP: "Mede", costCenterId: 1, user: 'env'},
    {id:'BARRANQUILLA', name: 'Barranquilla', short: 'B', alegraID: 3, oldOP: "Barr", costCenterId: 2, user: 'baq'},
    {id:'CALI', name: 'Cali', short: 'C', alegraID: 5, oldOP: "Cali", costCenterId: 3, user: 'cali'},
];

export const formatCurrencyString = (value, digits=1) => {
    return value?.toLocaleString('es-CO', {style: 'currency', currency: 'COP', maximumFractionDigits: digits })||0;
}

export const TiempoPago = [
    {id:'De_contado', name: 'CONTADO', value: 0},
    {id:'30_Dias', name: '30 DIAS', value: 30},
    {id:'45_Dias', name: '45 DIAS', value: 45},
    {id:'60_Dias', name: '60 DIAS', value: 60},
    {id:'90_Dias', name: '90 DIAS', value: 90},
];

export const Proveedores = [
    { id: "dupont", name: "PERFORMANCE SPECIALTY PRODUCTSCOLOMBIA S.A.S." },
    { id: "novaflex", name: "NOVAFLEX REPRESENTACIONES SAS" },
    { id: "preflex", name: "PREFLEX S.A.S." }, 
    { id: "comgraficas", name: "COMGRAFICAS S.A.S." }, 
    { id: "otro", name: "Otro" }
  ]

export const Calibres = [
    {id:'F45', name: '45', short: '45'},
    {id:'F67', name: '67', short: '67'},
    {id:'F100', name: '100', short: '100'},
    {id:'F112', name: '112', short: '112'},
    {id:'F155', name: '155', short: '155'},
    {id:'F250', name: '250', short: '250'},
    {id:'QF95', name: 'TOYOBO 95', short: '95'},
    {id:'WF80', name: 'WF 80', short: '80'},
    {id:'KM73', name: 'KM 73', short: '73'},
    {id:'WS94', name: 'WS 94', short: '94'},

];



export const Calibre = [
    {id:'45', name: '45'},
    {id:'67', name: '67'},
    {id:'100', name: '100'},
    {id:'112', name: '112'},
    {id:'155', name: '155'},
];

export const RegimenAdq = [
    {id:'COMMON_REGIME', name: 'Responsable de IVA (común)'},
    {id:'SIMPLIFIED_REGIME', name: 'No Responsable de IVA (Simplificado)'},
    {id:'NATIONAL_CONSUMPTION_TAX', name: 'Impuesto Nacional al Consumo - INC'},
    {id:'NOT_REPONSIBLE_FOR_CONSUMPTION', name: 'No responsable de INC'},
    {id:'INC_IVA_RESPONSIBLE', name: 'Responsable de IVA e INC'},
    {id:'SPECIAL_REGIME', name: 'Régimen especial'},
];


export const TiposOrdenes =[
    {
        id:'ARTE_ALTA',
        name:'Arte Alta Complejidad',
        valor: '',
        unidades: 'UNIDAD',
    },
    {
        id: 'ARTE_MEDIA',
        name: 'Arte Media Complejidad',
        valor: '',
        unidades: 'UNIDAD',
    },
    {
        id: 'ARTE_BAJA',
        name: 'Arte Baja Complejidad',
        valor: '',
        unidades: 'UNIDAD',
    },
    {
        id:'FINALIZACION_ALTA',
        name:'Finalizacion Alta Complejidad',
        valor: '',
        unidades: 'CENTIMETRO_CUADRADO',
    },
    {
        id:'FINALIZACION_BAJA',
        name:'Finalizacion Baja Complejidad',
        valor: '',
        unidades: 'CENTIMETRO_CUADRADO',
    },
    {
        id:'OPENPRINT',
        name:'OpenPrint',
        valor: '',
        unidades: 'CENTIMETRO_CUADRADO',
    },
    {
        id:'COPIADO',
        name:'Copiado',
        valor: '',
        unidades: 'CENTIMETRO_CUADRADO',
    },
    {
        id:'REPETICION',
        name:'Repeticion',
        valor: '',
        unidades: 'CENTIMETRO_CUADRADO',
    },
    {
        id:'PRUEBA_CONTRATO',
        name:'Prueba de Contrato',
        valor: '',
        unidades: 'CENTIMETRO_CUADRADO',
    },
    {
        id:'MONTAJE',
        name:'Montaje',
        valor: '',
        unidades: 'CENTIMETRO_CUADRADO',
    },
    {
        id:'MAQUILA',
        name:'Maquila',
        valor: '',
        unidades: 'CENTIMETRO_CUADRADO',
    },
    {
        id:'POSITIVO_NEGATIVO',
        name:'Positivo/Negativo',
        valor: '',
        unidades: 'CENTIMETRO_CUADRADO',
    },
    {
        id:'OTRO',
        name:'Otros',
        valor: '',
        unidades: 'UNIDAD',
    }

]