import UndoIcon from '@mui/icons-material/Undo';
import { ShowExposicion } from './ShowExposicion';
import React, { useState, useEffect } from 'react';
import IconCancel from '@mui/icons-material/Cancel';
import { ChatBubble } from '../../../utils/chatBubbleObserv';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { BubbleStyles, DialogSalidaStyled, ListStyled, ToolBarStyled, OrdenMaterialStyled, AddDiferencia } from '../styles/OrdenSalidaStyles';
import { Box, Grid, Card, Switch, Tooltip, Typography, CardContent, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableRow, TableBody, TableCell, TableHead } from '@mui/material';
import { useGetList, ListButton, TopToolbar, ExportButton, CreateButton, FunctionField, useRecordContext, EditButton, ReferenceField, Form, Button, TextInput, useUpdate, useNotify, SaveButton } from 'react-admin';

const LabelLayout = ({ showFilters }) => {
  return ( 
    showFilters ?
    <Typography sx={ToolBarStyled?.typography}>
      {`Ocultar Filtros`}
    </Typography>
    : 
    <Typography sx={ToolBarStyled?.typography}>
      {`Mostrar Filtros`}
    </Typography>
  )
};

const TopBarOrden = ({ showFilters, toggleFilters }) => {
  return (
    <TopToolbar> {showFilters ? <OrdenTopBar/> : null}
      <FormControlLabel
        sx={ToolBarStyled?.formControlLabel}
        onClick={toggleFilters}
        control={<Switch sx={ToolBarStyled?.toolBar}/>}
        label={<LabelLayout showFilters={showFilters}/>}
      />
    </TopToolbar>
  )
};

const OrdenTopBar = () => {
  return (
    <TopToolbar>
      <ExportButton/>
      <CreateButton/>
      <ListButton icon={<UndoIcon/>} resource='exposicions' label='Ver Exposiciones'/>
    </TopToolbar>
  )
};

const FunctionOrdenFiled = () => {
  return (
    <Card>
      <CardContent>
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <FunctionField render={record => record?.motivoDiferencia === null ? <AgregarDiferencia/> : null }/>
        </Box>
      </CardContent>
    </Card>
  )
};

const ResponsableView = () => {
  return (
    <FunctionField label='RESPONSABLE' render={r => {
      if (r?.salidaProd === true /*&& r?.reposicion === false*/) {
        return (
          <ReferenceField source='responsableSalida' reference='usuarios' link={false}>
            <FunctionField render={r => `${r?.nombres} ${r?.apellidos}`} sx={ListStyled?.componentFont}/>
          </ReferenceField>
        );
      } else if (r?.reposicion === true) {
        return (
          <ReferenceField source='responsableRepo' reference='usuarios' link={false}>
            <FunctionField render={r => `${r?.nombres} ${r?.apellidos}`} sx={ListStyled?.componentFont}/>
          </ReferenceField>
        );
      } else {
        return <span/>
      }
    }}/>
  )
};

const DialogSalidaOrden = ({ setRecord }) => {
  
  const record = useRecordContext();
  const reposicion = record?.reposicion;
  const salidaProd = record?.salidaProd;
  const totalExp = record?.totalPlanchasExp;
  const totalOrd = record?.totalPlanchasOrden;
  const calibreOrden = record?.calibreOrden;
  const calibresExp = record?.calibresExp?.[0] || 0;
  const motivoDiferencia = record?.motivoDiferencia;

  const buttonDisabled = salidaProd === true;
  const planchaCorrectly = totalOrd <= totalExp;
  const differentCalibre = calibreOrden === calibresExp;
  const buttonAble = salidaProd === false && (reposicion !== false || reposicion !== true);

  return (
    <>
    {motivoDiferencia === null && totalExp > totalOrd ? (
      <AgregarDiferencia/>
    ):(
      <FunctionField source='salidaOrdenes' label='SALIDA' render={record => record?.salidaProd === true && record?.reposicion === false ? ( 
        <ResponsableView/> ):(
          planchaCorrectly && buttonAble && differentCalibre ? (
          <EditButton
            size='small'
            label='SALIDA'
            disabled={buttonDisabled}
            style={{ alignItems: 'center', marginLeft: '-1em' }}
            icon={<ShoppingCartIcon/>}
            sx={DialogSalidaStyled?.button}
            onClick={() => setRecord(record)}
            color='inherit' variant='contained'
            startIcon={<ShoppingCartCheckoutIcon/>}
          />
          ):(<span/>)
        )}
      />
    )}
    </>
  );
};

const IconState = () => {
  return (
    <FunctionField
      render={r => {
        if (r?.salidaProd === true) {
          return (
            <Tooltip title='Orden finalizada'>
              <AssignmentTurnedInIcon sx={ListStyled?.iconStateSize} color='success'/>
            </Tooltip>
          );
        } else if (r?.reposicion === true) {
          return (
            <Tooltip title='Orden con reposición'>
              <AssignmentLateOutlinedIcon sx={ListStyled?.iconStateSize} color='warning'/>
            </Tooltip>
          );
        } else if (r?.calibreOrden !== r?.calibresExp?.[0]) {
          return (
            <Tooltip title='La orden tiene un calibre diferente al de la exposición'>
              <AssignmentLateOutlinedIcon sx={ListStyled?.iconStateSize} color='warning'/>
            </Tooltip>
          );
        } else if (r?.totalPlanchasExp > r?.totalPlanchasOrden) {
          if (r?.motivoDiferencia === null) {
            return (
              <Tooltip title={`Se añadieron ${r?.totalPlanchasExp} planchas, excediendo el total de ${r?.totalPlanchasOrden} plancha(s) requerida(s)`}>
                <LiveHelpOutlinedIcon sx={ListStyled?.iconStateSize} color='error'/>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title='Orden lista para darle salida'>
                <AssignmentTurnedInOutlinedIcon sx={ListStyled?.iconStateSize} color='action'/>
              </Tooltip>
            );
          }
        } else if (r?.totalPlanchasExp === r?.totalPlanchasOrden) {
          return (
            <Tooltip title='Orden lista para darle salida'>
              <AssignmentTurnedInOutlinedIcon sx={ListStyled?.iconStateSize} color='action'/>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title='Orden pendiente'>
              <AssignmentOutlinedIcon sx={ListStyled?.iconStateSize} color='disabled'/>
            </Tooltip>
          );
        }
      }}
    />
  )
};

const Detalles = () => {

  const record = useRecordContext();
  const op = parseInt(record?.op);
  const version = parseInt(record?.version);

  const filterObservaciones = {
    NUMERO: op,
    VERSIONOP: version
  }; 
  
  const { data: observaciones, isLoading: loadingObserv } = useGetList('sqlObservaciones', { 
    filter: filterObservaciones,
    pagination: { page: 1, perPage: 10 },
    sort: { 'field': 'VERSIONOP', 'order': 'DESC' }},
  );

  if (loadingObserv) {
    return <p>Loading...</p>;
  };

  const maxObs = observaciones?.length > 1;
  const minObs = observaciones?.length >= 0;

  return (
    <>
      {maxObs ? (
        <Grid container>
          <Grid item sm={6} md={6} lg={6} sx={{ marginTop: maxObs ? '1em' : '0em'}}>
            <Grid item sm={12} md={12} lg={12}>
              <OrdenMaterial/>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <ChatBubble observaciones={observaciones}/>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <ShowExposicion source='exposiciones' label='Exposiciones'/>
          </Grid>
        </Grid>
      ):(
        <>
          <Grid container spacing={1}>
            <Grid item sm={6} md={6} lg={6}>
              <Grid item sm={12} md={12} lg={12} sx={{ marginTop: minObs ? '2em' : '0em'}}>
                <OrdenMaterial/>
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <ChatBubble observaciones={observaciones}/>
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={6}>
              <ShowExposicion source='exposiciones' label='Exposiciones'/>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export const BubbleMotivo = () => {

  const record = useRecordContext();
  const motivoDiferencia = record?.motivoDiferencia;

  return (
    motivoDiferencia === null ? ( <span/> ):(
      <Box sx={BubbleStyles?.body}>
        <Typography variant="body3" sx={BubbleStyles?.textStyle}>{motivoDiferencia}</Typography>
      </Box>
    )
  );
};

const AgregarDiferencia = () => {

  const notify = useNotify();
  const record = useRecordContext();
  const [motivoDif, setMotivoDif] = useState('');
  const [update, { isLoading, error }] = useUpdate();
  const [showDialog, setShowDialog] = useState(false);
  const totalPlanchasExp = record?.totalPlanchasExp || 0;
  const totalPlanchasOrden = record?.totalPlanchasOrden || 0;
  const filterAble = totalPlanchasExp > totalPlanchasOrden;

  useEffect(() => {
    if (record?.motivoDiferencia && record?.motivoReposicion) {
      setMotivoDif(record?.motivoDiferencia);
    };
  }, [record]);

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);

  const handleMotivoChange = (e) => {
    const { name, value } = e.target;
    if (name === 'motivoDiferencia') {
      setMotivoDif(value);
    };
  };

  const handleSubmit = async (values) => {
    update('ordenSalidas', {
      id: record?.id,
      data: {
        id: record?.id,
        motivoDiferencia: values?.motivoDiferencia !== '' ? values?.motivoDiferencia : record?.motivoDiferencia,
      },
      previousData: record,
    },
    {
      onSuccess: () => notify('Solicitud y motivo agregados correctamente.'),
      onFailure: () => notify('Error al agregar el solicitud.', { type: 'error' })
    });
    console.log('Antes de Agregar el Motivo', record);
    console.log('Después de Agregar el Motivo', values);
  };

  if (error) {
    return (
      <Button style={AddDiferencia?.errorButton}>
        {`Error ${error?.message}`}
      </Button>
    );
  };

  return (
    filterAble ? (
      <>
        <Button size='small' label='Motivo' variant='contained' onClick={handleClick} sx={AddDiferencia?.sxButton} style={AddDiferencia?.styleButton} startIcon={<BorderColorOutlinedIcon/>}/>
        <Dialog fullWidth maxWidth='xs' open={showDialog} onClose={handleCloseClick} aria-label="Motivo Agregado">
          <DialogTitle style={AddDiferencia?.dialogTitleStyle}>
            <Typography style={AddDiferencia?.typography}>{`AGREGAR SOLICITUD`}</Typography>
          </DialogTitle>
          <Form resource="ordenSalidas" id="create-ordenSalid" onSubmit={handleSubmit}>
            <DialogContent>
              <Grid item xs={12}>
                <TextInput fullWidth multiline maxRows={5} size='large' label='Motivo Diferencia' source='motivoDiferencia' onChange={handleMotivoChange}/>
              </Grid>
            </DialogContent>
            <DialogActions style={AddDiferencia?.dialogActions}>
              <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
                <IconCancel/>
              </Button>
              <SaveButton label='Agregar Motivo' disabled={motivoDif === null}/>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    ) : (
      <span/>
    )
  );
};

export const OrdenMaterial = () => {

  const record = useRecordContext();
  const infoRetal = record?.infoRetal || [];
  const ordenMaterial = record?.ordenMaterial || [];

  const groupedMaterials = ordenMaterial?.reduce((acc, material) => {
    const { caja, opAncho: ancho, opLargo: largo, cantidad } = material;
    const [dim1, dim2] = [ancho, largo].sort((a, b) => a - b);
    const key = `${dim1}-${dim2}-${caja}`;

    if (!acc[key]) {
      acc[key] = {
        ancho: dim1,
        largo: dim2,
        caja,
        cantidad: 0,
        area: 0,
      };
    }

    acc[key].cantidad += cantidad;
    acc[key].area += dim1 * dim2 * cantidad;

    return acc;
  }, {});

  const materialArray = Object.values(groupedMaterials)?.map(group => {
    const opAncho = group?.ancho;
    const opLargo = group?.largo;
    const area = opAncho * opLargo * group?.cantidad;

    return {
      opAncho,
      opLargo,
      cantidad: group?.cantidad,
      cajaExpo: group?.caja,
      area,
      total: group?.cantidad,
    };
  });

  const totalArea = materialArray?.reduce((acc, item) => acc + item?.area, 0);
  const totalCantidad = materialArray?.reduce((acc, item) => acc + item?.cantidad, 0);

  return (
    <>
      <Box sx={{ minWidth: '100%' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`#Caja`}</b></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`Ancho (mm)`}</b></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`Largo (mm)`}</b></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`Cantidad`}</b></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`Área(mm)`}</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materialArray?.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell sx={OrdenMaterialStyled} align="center">{item?.cajaExpo}</TableCell>
                <TableCell sx={OrdenMaterialStyled} align="center">{item?.opAncho?.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                <TableCell sx={OrdenMaterialStyled} align="center">{item?.opLargo?.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                <TableCell sx={OrdenMaterialStyled} align="center">{item?.cantidad}</TableCell>
                <TableCell sx={OrdenMaterialStyled} align="center">
                  {item?.area?.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>Total</b></TableCell>
              <TableCell colSpan={2}></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center">{totalCantidad}</TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center" colSpan={2}>{`${totalArea?.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`Tipo Material`}</b></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`ID Material`}</b></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`Ancho`}</b></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`Largo`}</b></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`Desperdicio`}</b></TableCell>
              <TableCell sx={OrdenMaterialStyled} align="center"><b>{`Desperdicio %`}</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {infoRetal?.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell sx={OrdenMaterialStyled} align="center">{item?.typeMaterial}</TableCell>
                <TableCell sx={OrdenMaterialStyled} align="center">{item?.uuid}</TableCell>
                {item?.typeMaterial === 'Lamina Completa' ? (
                  <>
                    <TableCell sx={OrdenMaterialStyled} align="center">{item?.anchoLamina}</TableCell>
                    <TableCell sx={OrdenMaterialStyled} align="center">{item?.largoLamina}</TableCell>
                  </>
                ):(
                  <>
                    <TableCell sx={OrdenMaterialStyled} align="center">{item?.anchoRetal}</TableCell>
                    <TableCell sx={OrdenMaterialStyled} align="center">{item?.largoRetal}</TableCell>
                  </>
                )}
                <TableCell sx={OrdenMaterialStyled} align="center">{item?.desperdicio?.toFixed(1)}</TableCell>
                <TableCell sx={OrdenMaterialStyled} align="center">{`${item?.porcentajeDesp?.toFixed(2)}%`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export { LabelLayout, TopBarOrden, OrdenTopBar, FunctionOrdenFiled, ResponsableView, DialogSalidaOrden, IconState, Detalles, AgregarDiferencia };