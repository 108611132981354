import { Grid } from '@mui/material';
import { ListStyled } from '../style/ExposicionStyles';
import { Labeled, TextField, DateField, NumberField, useRecordContext } from 'react-admin';

export const ShowInfoExposicion = () => {

  const record = useRecordContext();
  const anchoRetal = record?.anchoRetal || 0;
  const largoRetal = record?.largoRetal || 0;
  const anchoLamina = record?.anchoLamina || 0;
  const largoLamina = record?.largoLamina || 0;
  const desperdicio = record?.desperdicio || 0;
  const desperdicioPerc = record?.desperdicioPerc || 0;

  const filterValuesRetal = anchoRetal && largoRetal && desperdicio && desperdicioPerc !== null;
  const filterValuesLamina = anchoLamina && largoLamina && desperdicio && desperdicioPerc !== null; 

  return (
    <>
      <Grid sx={{ textAlign: 'center' }} container spacing={{ xs: 1, md: 2 }} flexDirection='row' justifyContent='space-evenly'>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Labeled>
            <TextField source='ciudad' label='CIUDAD' sx={ListStyled?.medidasInfoFont}/>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Labeled>
            <TextField source='referencia' label='REFERENCIA' sx={ListStyled?.medidasInfoFont}/>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Labeled>
            <TextField source='areaTotalLen' label='ÁREA TOTAL' sx={ListStyled?.medidasInfoFont}/>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Labeled>
            <TextField source='calibre' label='CALIBRE' sx={ListStyled?.medidasInfoFont}/>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Labeled>
            <TextField source='plateName' label='NOMBRE PLANCHA' sx={ListStyled?.medidasInfoFont}/>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Labeled>
            <TextField source='plateType' label='TIPO DE PLANCHA' sx={ListStyled?.medidasInfoFont}/> 
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Labeled>
            <DateField source='dateExposed' label='FECHA EXPOSICIÓN' showTime={true} sx={ListStyled?.medidasInfoFont}/>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Labeled>
            <DateField source='updatedAt' label='FECHA ACTUALIZACIÓN' showTime={true} sx={ListStyled?.medidasInfoFont}/>   
          </Labeled>
        </Grid>
        {filterValuesRetal ? (
          <Grid container marginTop={'2em'} flexDirection='row' sx={{ textAlign: 'center'}} justifyContent='space-evenly' rowSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ marginTop: '1em' }}>
              <Labeled sx={{ fontSize: '14px'}}>
                <NumberField source='anchoRetal' label='ANCHO RETAL' sx={ListStyled?.medidasInfoFont}/>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ marginTop: '1em' }}>
              <Labeled sx={{ fontSize: '14px'}}>
                <NumberField source='largoRetal' label='LARGO RETAL' sx={ListStyled?.medidasInfoFont}/>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ marginTop: '1em' }}>
              <Labeled sx={{ fontSize: '14px'}}>
                <NumberField source='desperdicio' label='DESPERDICIO' sx={ListStyled?.medidasInfoFont}/>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ marginTop: '1em' }}>
              <Labeled sx={{ fontSize: '14px'}}>
                <NumberField source='desperdicioPerc' label='% DESPERDICIO' sx={ListStyled?.medidasInfoFont}/>
              </Labeled>
            </Grid>
          </Grid>
        ) : (
          <span/>
        )}
        {filterValuesLamina ? (
          <Grid container marginTop='2em' flexDirection='row' rowSpacing={{ xs: 1, md: 2 }} justifyContent='space-evenly' sx={{ textAlign: 'center'}}>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ marginTop: '1em'}}>
              <Labeled sx={{ fontSize: '14px'}}>
                <NumberField source='anchoLamina' label='LARGO LÁMINA' sx={ListStyled?.medidasInfoFont}/>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ marginTop: '1em'}}>
              <Labeled sx={{fontSize: '14px'}}>
                <NumberField source='largoLamina' label='LARGO LÁMINA' sx={ListStyled?.medidasInfoFont}/>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ marginTop: '1em'}}>
              <Labeled sx={{fontSize: '14px'}}>
                <NumberField source='desperdicio' label='DESPERDICIO' sx={ListStyled?.medidasInfoFont}/>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} sx={{ marginTop: '1em'}}>
              <Labeled sx={{fontSize: '14px'}}>
                <NumberField source='desperdicioPerc' label='% DESPERDICIO' sx={ListStyled?.medidasInfoFont}/>
              </Labeled>
            </Grid>
          </Grid>
        ) : (
          <span/>
        )}
      </Grid>
    </>
  );
};