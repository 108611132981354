

import React, { useState, useCallback } from "react";
import { Create, SimpleForm, TextInput, Toolbar, SaveButton,  
        TextField,  SelectInput, useNotify,
        useRegisterMutationMiddleware, useDataProvider, ReferenceField, Labeled,
    } from 'react-admin';
import {  Grid } from '@mui/material';
import { Sucursales } from "../../utils";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { OrdenesPendientesShow } from "../components/OrdenesPendientesShow";
import { EditableDatagridCustom } from "../components/EditableDatagridCustom";
import { TotalesComponent } from "../components/TotalesComponent";
import ObservacionesCliente from "../components/ObservacionesCliente";



const CustomToolbar = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const middleware = useCallback(async (resource, params, options, next) => {
        let { data } = params;
        //console.log('DATA INSIDE MIDDLEWARE', data);
        let newParams = {};
        
        try{
            const response = await createAlegraRemision(data);
            //console.log('RESPONSE inside middleware', response);
            if(response.data.id){
                notify('Remisión creada en Alegra', {type:'success'}, { smart_count: 1 });
            }
            const { clientData, ...fixedData } = data;
            newParams = { ...params, 
                data: { ...fixedData, 
                    alegraId: response.data.id,
                    alegraNumeracion: response.data.number,
                    pdf: `https://app.alegra.com/remission/print/id/${response.data.id}`,
                    url: `https://app.alegra.com/remission/view/id/${response.data.id}`,
                    estado: 'Emitida',
                    fechaCrea: new Date(),
                    fechaVence: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                    usuarioID: localStorage.getItem('user'),
                 } };
            console.log('NEW PARAMS', newParams);
        } catch (error) {
            if (error.message === 'El id del ítem es un campo obligatorio') {
                notify('Error: Caja No Seleccionada', {type:'error'}, { smart_count: 2 });
            }else{
                notify(`Error: ${error.message}` , {type:'error'}, { smart_count: 2 });
            }
            return;
        }
        let rem;
        try{
            //console.log('NEW PARAMS', newParams);
            rem = await next(resource, newParams, options);
        }catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, {type:'error'}, { smart_count: 4 });
            return;
        }

        try {            
            for (const orden of data.ordenes) {
                await dataProvider.update('sqlOrdenOut', {filter: {numero: orden.numero, versionop: orden.version, remision: newParams.data.alegraNumeracion.match(/\d+/)[0]}});
            }
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, {type:'error'}, { smart_count: 1 });
            return;
        }   
        try {            
            discountFromStock(data.itemsFact);
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, {type:'error'}, { smart_count: 1 });
        }   
        try{
            const res = await Promise.all(
                newParams.data.itemsFact.map(async item => {
                  const remision = await rem;
                  console.log('Remision-->', remision);
                  const newItem = { ...item, vendedorID: remision.vendedorID||'' , ciudad: remision.ciudadId, remisionID: remision.id, remisionNro: remision.alegraNumeracion, clienteID:remision.clienteId, clienteName: remision.clienteName};
                  return dataProvider.create('productFacts', { data: { ...newItem } });
                })
              );
            
            } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, {type:'error'}, { smart_count: 3 });
        }
    }, []);

    const discountFromStock = async (items) => {
        const cajas = items.reduce((acc, item) => {

            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0){
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        for (const caja of cajas) {
            if(caja === null || caja.area<=0 || caja.caja === undefined) continue;
            const stock = await dataProvider.getOne('stocks', { id: caja.caja });
            const newStock = {
                ...stock.data,
                areaRestante: stock.data.areaRestante - caja.area,
            }
            delete newStock.retales
            await dataProvider.update('stocks', { id: caja.caja, data: newStock });
        }
    }

    const createAlegraRemision = async (values) => {
       // const values = getValues(); 
       //console.log('VALUES', values);
        const alegraJson = {
            documentName: 'remission',
            date: new Date(),
            dueDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            anotation: values.observaciones,
            client: { id: values.alegraClienteId },
            seller: values.vendedorAlegraId ? { id: values.vendedorAlegraId } : undefined,
            items: values.itemsFact.map(item => {
                let descripcion = '';
                if(item.ancho>0&&item.largo>0 && item.area > 0){
                    descripcion =  `${item.descripcion}\n${item.ancho} x ${item.largo}   Cant: ${item.cantidad}`
                    if(item.odc && item.odc.trim().length > 0){
                        descripcion = `${descripcion} - ODC:${item.odc}`
                }} else {
                    descripcion = item.descripcion
                    if(item.odc){
                        descripcion = `${descripcion} - ODC:${item.odc}`
                    }
                }
                return({
                id: item.alegraItemId,
                reference: item.ordenVersion,
                description: descripcion,
                quantity: item.area > 0 ? item.area : item.cantidad,
                price: item.valorUnitario,
                tax: [{ id: item.iva ? 3 : 0 }],
            })}),
            costCenter: {id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).costCenterId},
            warehouse:  {id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).alegraID},

        };
        //console.log('ALEGRA JSON', alegraJson);
        return  dataProvider.create('alegraRemissions', { data: alegraJson })
    }
           
        // Use the `useCreate` hook or directly call the `dataProvider` with the prepared JSON
    useRegisterMutationMiddleware(middleware);

 

    return (
        <Toolbar>
            {/*<SaveButton key='borrador' label="Guardar Borrador"  variant="outlined" alwaysEnable />*/}
            <SaveButton
                key={'emitir'}
                label="Emitir Remisión"
                icon={<SendAndArchiveIcon />}
                alwaysEnable
            />
        </Toolbar>
    );
};





const RemisionCreate = () => {
    const [ refreshDatagrid, setRefreshDatagrid ] = useState();
    
    const refetchData = (v) => {
        setRefreshDatagrid(v)
    }

    return (
        <Create  redirect='edit' >
            <SimpleForm toolbar={<CustomToolbar/>} >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={9} container alignItems={'center'}>
                           < Grid item >
                                <Labeled>
                                    <ReferenceField source='clienteId' reference='clientes'>
                                        <TextField source="razonSocial" label="Cliente" sx={{ padding: '10px', fontSize: '25px' }} />
                                    </ReferenceField>
                                </Labeled>
                           </Grid>
                           < Grid item >
                                <ObservacionesCliente />
                           </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SelectInput source="ciudadId" label="Ciudad"  choices={Sucursales} optionText='name'/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <EditableDatagridCustom refreshDatagrid={refreshDatagrid}/>
                    </Grid>
                    <Grid item  xs={12} md={12} container  direction={'row'} justifyContent={'flex-end'} >
                        <Grid item xs={12} md={7}>
                            <TextInput source="observaciones"  fullWidth/>
                        </Grid>
                        <TotalesComponent changed={refetchData}/>
                    </Grid>
                </Grid>
            </SimpleForm>
            <OrdenesPendientesShow/>
        </Create>
    );
};




export default RemisionCreate;








