import { 
    Edit, 
    Button,
    useUpdate,
    TextInput,
    TopToolbar,
    ShowButton, 
    ArrayInput,
    SimpleForm,
    NumberInput,
    SelectInput,
    BooleanInput,
    DateTimeInput,
    useRecordContext,
    AutocompleteInput,
    SimpleFormIterator,
} from 'react-admin';
import { Calibre } from '../../utils';
import React, { useState } from 'react';
import { AmplifyImageInput } from '../../Amplify';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'; 
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';

const editTransform = ({data}) => {

    console.log(data)
    delete data?.imageExp?._url

    return(
        {   
            id: data.id,
            plateName: data.plateName,
            dateExposed: data.dateExposed,
            plateType: data.plateType,
            ciudad: data.ciudad,
            calibre: data.calibre,
            len: data.len?.map(item => ({
                lenWidth: item.lenWidth,
                lenHeight: item.lenHeight,
                name: item.name,
                uuid: item.uuid,
                area: item.area,
                image: {
                    bucket: item.image.bucket,
                    region: item.image.region,
                    key: item.image.key,
                },
                op: item.op,
                version: item.version,
            })),
            areaTotalLen: data.areaTotalLen,
            imageExp: {
                bucket: data.imageExp.bucket,
                region: data.imageExp.region,
                key: data.imageExp.key,
            },
            expoName: data.expoName,
            cajas: data.cajas,
            taras: data.taras,
            responsablePin: data.responsablePin,
            responsableId: data.responsableId,
            responsableName: data.responsableName,
            referencia: data.referencia,
            cajas: data.cajas,
            cajaId: data.cajaId,
            motivo: data.motivo,
            pendienteSalida: data.pendienteSalida,
            anulada: data.anulada,
        }
    )
};   

const PostEditionsActions = () => (
   <TopToolbar>
       <ShowButton/>
   </TopToolbar>
)

export const ExposicionEdit = () => {

    const record = useRecordContext();
    const [ lenData ] = useState(record?.len);

    const groupByOpAndVersion = (lenArray) => {
        return lenArray?.reduce((acc, curr) => {
          const key = `${curr?.op}-${curr?.version}`;
          if (!acc[key]) {
            acc[key] = {
              data: [],
              op: curr?.op,
              version: curr?.version,
              totalPlanchasExp: 0,
            };
          }
          acc[key].data?.push(curr);
          acc[key].totalPlanchasExp = acc[key].data?.length;
          return acc;
        }, {});
      };
    
    const organizedLen = groupByOpAndVersion(lenData);

   return(
        <Edit actions={<PostEditionsActions/>} transform={editTransform}>
            <SimpleForm>
                <Grid container spacing={{ xs: 2, md: 3 }} flexDirection="row" justifyContent="space-evenly">
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center'}} sx={{'img': { minHeight: 200, minWidth: '70%' }}}>
                        <AmplifyImageInput 
                            source="imageExp"
                            label="IMAGEN EXPOSICIÓN"
                            style={{ maxWidth: '70%', maxHeight: '60vh', objectFit: 'contain' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextInput fullWidth color="primary" source="plateName" label='Nombre Plancha'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <DateTimeInput fullWidth color="primary" source="dateExposed" label='Fecha Exposicion'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextInput fullWidth color="primary" source="plateType" label='Tipo Plancha'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2} style={{marginTop: '-8px'}}>
                        <SelectInput fullWidth source="ciudad" choices={[
                            { id: 'CALI', name: 'CALI' },
                            { id: 'ENVIGADO', name: 'ENVIGADO' },
                            { id: 'BARRANQUILLA', name: 'BARRANQUILLA' },
                        ]}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <AutocompleteInput size='small' fullWidth variant='outlined' source='calibre' label='Calibre' choices={Calibre}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextInput fullWidth color="primary" source="expoName" label='Nombre Exposición'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <NumberInput fullWidth source="areaTotalLen" label="Área Total Len" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <NumberInput fullWidth source="taras" label="Taras" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextInput fullWidth color="primary" source="responsableName" label='Nombre del Responsable'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextInput fullWidth color="primary" source="responsablePin" label='Nombre del Pin'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextInput fullWidth color="primary" source="responsableId" label='Nombre del ID'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <TextInput fullWidth color="primary" source="cajaId" label='Caja ID'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <BooleanInput fullWidth color="primary" source="pendienteSalida" label='Pendiente Salida'/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <BooleanInput fullWidth color="primary" source="anulada" label='Anulada'/>
                    </Grid>
                </Grid>
                <Grid container spacing={{ xs: 2, md: 3 }} flexDirection="row" justifyContent="space-evenly">
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Accordion square sx={{ boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" style={{ fontWeight: 'bold', marginLeft: '1rem' }}>
                                    {`LEN`}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ArrayInput record={{ len: organizedLen }} source="len" label=""> 
                                    <SimpleFormIterator inline linkType={false} fullWidth >
                                        <AmplifyImageInput 
                                            fullWidth
                                            source="image"
                                            label="IMAGEN EXPOSICIÓN"
                                            sx={{'img': { minHeight: 200, minWidth: '70%', marginRight: '20rem', }}}
                                            style={{
                                                maxWidth: '70%', 
                                                maxHeight: '60vh', 
                                                objectFit: 'contain', 
                                                textAlign: 'center', 
                                                marginRight: '20rem', 
                                            }}
                                        />
                                        <TextInput source="op" label="Op" />
                                        <TextInput source="version" label="Versión" />
                                        <TextInput source="name" label="Nombre Len"/>
                                        <NumberInput source="lenWidth" label="Ancho Len"/>
                                        <NumberInput source="lenHeight" label="Altura Len"/>
                                        <NumberInput source="area" label="Área"/>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

export const ReverseButtonExpo = () => {
    
    const record = useRecordContext();
    const [update, { error }] = useUpdate();
    const referencia = record?.referencia;
    const refMatch = referencia?.length > 0 ? referencia?.replace(/^./, 'B') : referencia;

    const handleSubmit = () => {
        update('exposicions', {
            id: record?.id,
            data: {
                id: record?.id,
                taras: null,
                cajaId: null,
                motivo: null,
                isPlate: null,
                anchoRetal: 0,
                largoRetal: 0,
                anchoLamina: 0,
                desperdicio: 0,
                largoLamina: 0,
                anulada: false,
                reposicion: false,
                desperdicioPerc: 0,
                reposicionName: null,
                responsableId: null,
                responsablePin: null,
                responsableName: null,
                pendienteSalida: true,
                responsableReposicion: null,
                /*
                ciudad: 'BARRANQUILLA',
                referencia: refMatch,
                */
            },
            previousData: record,
        });
    };

    return (
        <>
            <Button
                onClick={()=>handleSubmit()}
                size='small'
                variant='rounded'
                startIcon={<FlipCameraAndroidIcon />}
            >
                <span>
                    {`Reverse`}
                </span>
            </Button>
            {error && (
                <Typography color="error">
                    Error: {error.message}
                </Typography>
            )}
        </>
    );
};
