import { 
  Button,
  FilterList,
  FilterListItem,
  useUnselectAll,
  useListContext,
} from 'react-admin';
import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';
import Groups3Icon from '@mui/icons-material/Groups3';
import { FilterStyled } from '../styles/OrdenSalidaStyles';
import { Card, CardContent, Typography } from '@mui/material';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import { removeDuplicates } from '../../../utils/utilsExposicion';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

export const OrdenFilters = () => {

  const { data, filterValues, setFilters } = useListContext();
  const unselectAll = useUnselectAll('ordenSalidas');
  const ciudadOrden = removeDuplicates(data, 'ciudadOrden');
  const clienteOrden = removeDuplicates(data, 'clienteOrden');
    
  useEffect(() => {
    if (filterValues) {
      unselectAll();
    }
  }, [filterValues, data]);

  if (!data && data?.length === 0) {
    return (
      <Card sx={FilterStyled?.card}>
        <CardContent>
          <Button onClick={() => setFilters({})}>
            {`Limpiar Filtros`}
          </Button>
        </CardContent>
      </Card>
    )
  };
  
  return (
    data && data?.length > 0 && (
      <Card sx={FilterStyled?.card}>
        <CardContent>
          <FilterList sx={FilterStyled?.filterListItem?.filterList} label="Ciudad" icon={<LocationCityRoundedIcon sx={FilterStyled?.filterListItem?.iconStyled}/>}>
            {ciudadOrden?.length > 0 ? ciudadOrden?.map((item) => (
              <FilterListItem
                key={item?.ciudadOrden}
                source='ciudadOrden.eq'
                value={{ ciudadOrden: { eq: item?.ciudadOrden }}}
                onChange={(value) => console.log("value ciudadOrden", value)}
                sx={{ ml: { lg: -5.5, xl: -5 }, width: { lg: 165, xl: 180 }}}
                label={<Typography sx={FilterStyled?.filterListItem?.label}><CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{item?.ciudadOrden || ''}</Typography>}
              />
            )) : <span/>}
          </FilterList>
          <Divider sx={{ mt: 2 }}/>
          <FilterList sx={FilterStyled?.filterListItem?.filterList} label="Clientes" icon={<Groups3Icon sx={FilterStyled?.filterListItem?.iconStyled}/>}>
            {clienteOrden?.length > 0 ? clienteOrden?.map((item) => (
              <FilterListItem
                source='clienteOrden.eq'
                key={item?.clienteOrden}
                value={{ clienteOrden: { eq: item?.clienteOrden }}}
                sx={{ ml: { lg: -5, xl: -5 }, width: { lg: 160, xl: 170 }}}
                onChange={(value) => console.log("value clienteOrden", value)}
                label={<Typography sx={FilterStyled?.filterListItem?.label}><CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{item?.clienteOrden || ''}</Typography>}
              />
            )) : <span/>}
          </FilterList>
          <Divider sx={{ mt:2 }}/>
          <FilterList sx={FilterStyled?.filterListItem?.filterList} label="Salida Orden" icon={<ChecklistRtlIcon sx={FilterStyled?.filterListItem?.iconStyled}/>}>
            <FilterListItem
              style={FilterStyled?.filterListItem?.style}
              value={{ salidaProd: { eq: true }}}
              sx={FilterStyled?.filterListItem?.sx}
              onChange={(value) => console.log("Salida Pendiente Sí", value)}
              label={<Typography sx={FilterStyled?.filterListItem?.label}><CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{'Sí'}</Typography>}
            />
            <FilterListItem
              style={FilterStyled?.filterListItem?.style}
              value={{ salidaProd: { eq: false }}}
              sx={FilterStyled?.filterListItem?.sx}
              onChange={(value) => console.log("Salida Pendiente No", value)}
              label={<Typography sx={FilterStyled?.filterListItem?.label}><CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{'No'}</Typography>}
            />
          </FilterList>
          <Divider sx={{ mt:2 }}/>
          <FilterList sx={FilterStyled?.filterListItem?.filterList} label="Reposición" icon={<ProductionQuantityLimitsIcon sx={FilterStyled?.filterListItem?.iconStyled}/>}>
            <FilterListItem
              style={FilterStyled?.filterListItem?.style}
              value={{ reposicion: { eq: true }}}
              onChange={(value) => console.log("Reposición Sí", value)}
              sx={FilterStyled?.filterListItem?.sx}
              label={<Typography sx={FilterStyled?.filterListItem?.label}><CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{'Sí'}</Typography>}
            />
            <FilterListItem
              style={FilterStyled?.filterListItem?.style}
              value={{ reposicion: { eq: false }}}
              onChange={(value) => console.log("Reposición No", value)}
              sx={FilterStyled?.filterListItem?.sx}
              label={<Typography sx={FilterStyled?.filterListItem?.label}><CircleIcon sx={FilterStyled?.filterListItem?.iconLabel}/>{'No'}</Typography>}
            />
          </FilterList>
        </CardContent>
      </Card>
    )
  );
};