import { 
  useNotify,
  useGetOne,
  useGetList,
  NumberInput,
  SelectInput,
  PasswordInput, 
  ReferenceInput, 
  useDataProvider, 
  useRecordContext,
  AutocompleteInput,
} from 'react-admin';
import { Grid, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { DialogCajaStock } from './DialogCajaStock';
import { RadioButonMaterial } from './TypeMaterial';
import { DialogStylized } from '../style/ExposicionStyles';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export const InputsMedidas = ({ onCajaChange, onMaterial, onCajaManual }) => {

  const { getValues } = useFormContext();
  const record = useRecordContext();
  const referencia = record?.referencia;
  const [cajaManual, setCajaManual] = useState('');
  const [selectedCaja, setSelectedCaja] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState('');
  
  const { data: stock } = useGetList('stocks', {
    meta: { searchable: 'true' },
    filter: { materialID: { eq: referencia }}, 
    sort: { field: 'consecutivo', order: 'DESC' }
  });
  
  const filterCajaActivaIngresada = stock?.filter(caja => caja?.activa === true && caja?.ingresada === true) || [];
  const filterCajaActiva = stock?.filter(caja => caja?.activa === true && (caja?.ingresada === false || caja?.ingresada === null || caja?.ingresada === undefined)) || [];
  const filterCajaIngresada = stock?.filter(caja => caja?.activa === false && caja?.ingresada === true) || [];
  const maxConsecutivoIngresada = filterCajaActivaIngresada?.length > 0 ? Math.max(...filterCajaActivaIngresada?.map(caja => caja?.consecutivo)) : -1;

  let nextConsecutiveBox = filterCajaActiva?.find(caja => caja?.consecutivo === maxConsecutivoIngresada + 1);

  if (!nextConsecutiveBox) {
    nextConsecutiveBox = filterCajaIngresada?.find(caja => caja?.consecutivo === maxConsecutivoIngresada + 1);
  };

  if (!nextConsecutiveBox && filterCajaActiva?.length > 0) {
    nextConsecutiveBox = filterCajaActiva[0];
  };
  
  const handleCajaChange = (value) => {
    const selected = filterCajaActivaIngresada?.find(caja => caja?.id === value);
    setCajaManual(''); 
    onCajaChange(selected);
    setSelectedCaja(selected);
  };

  const handleCajaManual = (event) => {
    const cajaValue = event.target.value;
    setCajaManual(cajaValue?.toString());
    setSelectedCaja('');
    onCajaManual(cajaValue?.toString());
  };

  const handleMaterialChange = (value) => {
    setSelectedMaterial(value);
    onMaterial(value)
  };

  const helperText = (
    <div style={{ fontWeight: 'bold' }}>
      <Tooltip title={'Referencia de caja inexistente'}>
        <span>
          {`No hay una caja para la referencia ${referencia}. Por favor, ingrese el número de la caja manualmente.`}
        </span>
      </Tooltip>
    </div>
  );

  // const valuesInput = getValues([ 'responsableName', 'responsableId', 'responsablePin', 'taras', 'cajaId', 
  //   'anchoLamina', 'largoLamina', 'desperdicio', 'desperdicioPerc', 'anchoRetal', 'largoRetal',
  // ]); console.log("Valores:", valuesInput);
  
  return (
    <>
      <Grid item xs={12} md={12}>
        <UserInput source='responsablePin' idField='responsableId' nameField='responsableName' label='Responsable'/>
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput min={1} fullWidth label='Tara' source="taras" sx={DialogStylized?.layout?.inputs}/>
      </Grid>
      <Grid container columnSpacing={1}>
        {filterCajaActivaIngresada && Object?.values(filterCajaActivaIngresada)?.length > 0 ? (
          <Grid item xs={10.5} sm={11.2} md={10.5} lg={10.5}>
            <ReferenceInput source='cajaId' reference='stocks'>
              <AutocompleteInput 
                fullWidth
                size='small'
                label='Caja'
                source='cajaId'
                optionText='consecutivo'
                onChange={handleCajaChange}
                sx={DialogStylized?.layout?.inputs}
                choices={Object?.values(filterCajaActivaIngresada) || []}
              />
            </ReferenceInput>
          </Grid>
        ):(
          <Grid item container rowSpacing={1} xs={12} sm={12} md={12} lg={12}>
            {cajaManual ? (
              <span/>
            ) : (
              <Grid item xs={0.01}>
                <Tooltip title={'Ingrese la caja manualmente, no hay cajas existentes.'}>
                  <PriorityHighIcon sx={DialogStylized?.layout?.priorityHigh}/>
                </Tooltip>  
              </Grid>
            )}
            <Grid item xs={11.99} sm={11.99} md={11.99} lg={11.99}>
              <NumberInput 
                min={1} 
                fullWidth
                size='small'
                label='Caja'
                source='cajaId'
                onChange={handleCajaManual}
                parse={v => (v !== null && v !== undefined) ? v?.toString() : ''}
                helperText={cajaManual ? 'Ingrese el número de la caja' : helperText}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={1.5} sm={0.5} md={1.5} lg={1.5}>
          {filterCajaActivaIngresada && filterCajaActivaIngresada?.length > 0 ? ( 
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
              <DialogCajaStock selectedCaja={selectedCaja}/>
            </Grid>
          ):(
            <span/>
          )}
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11}>
          <RadioButonMaterial cajaManual={cajaManual} selectedCaja={selectedCaja} onChangeMaterial={handleMaterialChange}/>
        </Grid>
      </Grid>
    </>
  );
};

export const UserInput = ({ idField, source, label, nameField }) => {

  const notify = useNotify();
  const [user, setUser] = useState({});
  const { setValue } = useFormContext();
  const dataProvider = useDataProvider();
  const [changedPin, setChangedPin] = useState(false);
  const [responsablePin, setResponsablePin] = useState(null);

  useEffect(() => {
    if (changedPin) {
      dataProvider?.getOne('userExposicionByPins', {
        pin: responsablePin
      }, { enabled: changedPin })
      .then(({ data }) => {
        if (data && data?.id) {
          setUser(data);
          setValue(idField, data.id);
        }
      })
      .catch(error => {
        console.log(error);
        notify('PIN del responsable está incorrecto o no se encontró.', { type: 'warning' });
      });
    }
  }, [responsablePin, changedPin, idField]);

  useEffect(() => {
    if (user && user?.id) {
      const nameResponsable = `${user?.nombres} ${user?.apellidos}`;
      setValue(idField, user?.id);
      setValue(nameField, nameResponsable);
    }
    setChangedPin(false);
  }, [user, nameField, idField]);

  const handlePinChange = (e) => {
    const newPinValue = e.target.value?.trim();
    setResponsablePin(newPinValue);
    setChangedPin(true);

    if (newPinValue) {
      setValue(source, newPinValue);
    };
  };

  return (
    <PasswordInput
      fullWidth
      label={label}
      source={source}
      onBlur={handlePinChange}
      autoComplete='chrome-off'
      helperText='Ingrese el PIN'
      sx={DialogStylized?.layout?.inputs}
    />
  );
};

export const UserAnulacion = ({ onUserSelect }) => {
  
  const { setValue } = useFormContext();
  const userId = localStorage.getItem('user');
  const { data: usuario, loading } = useGetOne('usuarios', { id: userId });

  useEffect(() => {
    if (usuario) {
      const nameResponsable = `${usuario.nombres} ${usuario.apellidos}`;
      setValue('responsableId', userId);
      setValue('responsableName', nameResponsable);
      onUserSelect({ id: userId, name: nameResponsable });
    }
  }, [usuario, setValue, userId, onUserSelect]);

  if (loading) return (
    <div>
      {`Buscando responsable...`}
    </div>
  );

  return (
    <ReferenceInput source='responsableId' reference='usuarios' perPage={1} defaultValue={userId}>
      <SelectInput
        fullWidth
        size='small'
        label='Responsable'
        source='responsableId'
        optionText={user => `${user.nombres} ${user.apellidos}`}
        choices={usuario ? [{ id: userId, nombres: usuario.nombres, apellidos: usuario.apellidos }] : []}
      />
    </ReferenceInput>
  );
};

export const UserReposicion = ({ onUserSelect }) => {

  const { setValue } = useFormContext();
  const userId = localStorage?.getItem('user');
  const { data: usuario } = useGetOne('usuarios', { id: userId });
  const { data: users } = useGetList('usuarios', { pagination: { page: 1, perPage: 50 }, meta: { searchable: 'true' }});
  const sedeUser = usuario?.sedes || [];

  const filteredUsers = users?.filter(user => {
    const roles = user?.roles || [];
    const sedes = user?.sedes || [];
    const hasValidRole = roles?.includes('coord') || roles?.includes('diseno') || roles?.includes('produccion') 
    || roles?.includes('calidad') || roles?.includes('diseno_externo') || roles?.includes('admin');
    const hasCommonSede = sedes?.some(sede => sedeUser?.includes(sede));
    return hasValidRole && hasCommonSede;
  });

  const userChoices = filteredUsers?.map(user => ({ id: user?.id, nombres: user?.nombres, apellidos: user?.apellidos }));

  useEffect(() => {
    if (usuario) {
      setValue('responsableId', userId);
      setValue('responsableName', `${usuario?.nombres} ${usuario?.apellidos}`);
    };
  }, [usuario, setValue, userId]);

  const handleUserChange = (userId) => {
    console.log('userId', userId);

    if (!userId) {
      setValue('responsableId', '');
      setValue('responsableName', '');
      return;
    };

    const selectedUser = filteredUsers?.find(user => user?.id === userId);
    if (selectedUser) {
      const nameResponsable = `${selectedUser.nombres} ${selectedUser?.apellidos}`;
      setValue('responsableId', userId);
      setValue('responsableName', nameResponsable);
      onUserSelect({ id: userId, name: nameResponsable });
    };
  };
  
  return (
    <ReferenceInput source='UserReposicion' reference='usuarios' required perPage={50}>
      <AutocompleteInput
        fullWidth
        size='small'
        label='Responsable'
        source='UserReposicion'
        onChange={handleUserChange}
        choices={userChoices ? userChoices : []}
        optionText={user => `${user?.nombres} ${user?.apellidos}`}
      />
    </ReferenceInput>
  );
};