import {
  Datagrid,
  TextField,
  useGetMany,
  useRecordContext,
  ReferenceManyField,
} from "react-admin";
import { Typography } from '@mui/material';
import { ReposicionExpo } from "./ReposicionExpo";
import { ImageCDI } from "../../exposicion/components/ImageCDI";
import { ShowInfoMedidas } from "../../exposicion/layouts/ShowInfoMedidas";
import { ResponsableView } from "../../exposicion/components/VariousComponets";
import { ListStyled, StyledDatagridShowExposicion } from "../styles/OrdenSalidaStyles";


export const ShowExposicion = () => {

  const record = useRecordContext();
  const op = record?.op || 0;
  const expId = record?.expID || [];
  const version = record?.version || 0;
  const reposicion = record?.reposicion;
  const { data: expo } = useGetMany('exposicions', { ids: expId });
  const expoReposicion = expo?.some(exposicion => exposicion?.reposicion);
  const shouldShowRedoExpo = (!expoReposicion && !reposicion) || (expoReposicion && !reposicion);
  const expoMap = Array?.from(new Set(expo?.map(name => name?.expoName?.toUpperCase()?.replace(/[^A-Z\s]/g, ''))));

  const title = (
    <Typography sx={StyledDatagridShowExposicion?.title}>
      {`${expoMap} ${op}-${version}`}
    </Typography>
  );

  return (
    <ReferenceManyField reference='exposicions' target='id'>
      <Datagrid
        data={expo}
        rowClick={false}
        sx={StyledDatagridShowExposicion?.datagrid}
        bulkActionButtons={shouldShowRedoExpo ? <ReposicionExpo title={title}/> : false}
      >
        <ImageCDI source='imageExp' label='IMAGEN' style={{ marginLeft: '-5rem' }}/>
        <TextField source='referencia' label='REFERENCIA' sx={ListStyled?.componentFont}/>
        <ResponsableView source='responsable' label='RESPONSABLE' sx={ListStyled?.componentFont}/>
        <ShowInfoMedidas source='exposiciones' label='EXPOSICIONES' sx={ListStyled?.componentFont}/>
      </Datagrid>
    </ReferenceManyField>
  );
};