
import React, { useEffect, useState } from 'react';
import { InvoiceVoidLayout } from "./InvoiceVoidLayout";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { SimpleForm, useRecordContext, useNotify, useRefresh, Toolbar, useRedirect, useUpdate, useGetList, CreateButton } from 'react-admin';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import {  useFormContext, useWatch } from 'react-hook-form';
import EditNoteIcon from '@mui/icons-material/EditNote';



const VoidInvoiceButton = () => {

    const record = useRecordContext();
    const notify = useNotify();

 
    return(
        <EditInDialogButton  title={`Anulación Factura #${record.alegraNumeracion}`} maxWidth="lg" label='' ButtonProps={{disabled: record&&record.estado==='void'}} inline icon={<BlockOutlinedIcon color={record&&record.estado==='void'?'light':'error'}/>} id={record.id}>
            <SimpleForm 
                sx={{minWidth:'1000px'}} 
                toolbar={<CustomToolbar />}
            >
                <InvoiceVoidLayout record={record}/>
            </SimpleForm>
        </EditInDialogButton>
        );
    }

const CustomToolbar = () => {
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { getValues , setValue } = useFormContext();
    const [updateStatus, setUpdateStatus] = useState('idle');
    const creditNoteData = useWatch({ name: 'creditNoteData' });

    const [update, { isPending, error }] = useUpdate();
    const notify = useNotify();
    const record = useRecordContext();
    const { data: clientes } = useGetList('clientes', {filter: {id:{eq:record?.clienteId}}, meta: {searchable: true}});
    // const  clientes =[[{id:1, name:'Cliente 1'}]];
        
        ///start here///////////////////
        /// DEFINE WHAT PARAMETERS ARE NEEDED TO DELETE/UPDATE IN THE OLD RECORD, AND TO CREATE FROM THE NEW RECORD


    let recordModified = {record: {...record, clientData:{}}};
    
    if (clientes && clientes.length > 0) {
        recordModified.record['clientData'] = clientes[0];
        recordModified.record['observaciones'] = record.observaciones+' - REEMPLAZA LA FACTURA '+record.alegraNumeracion;
    }

    const handleDuplicate = () => {
        recordModified.record['isDuplicate'] = true;
        recordModified.record['previousInvoiceID'] = record.id;
        delete recordModified.record.id;
        
        console.log('handleDuplicate', recordModified);
        let { productFact, remisiones,  ordenes, ...dataCleaned } = record;
        dataCleaned.estado = 'void';
        dataCleaned.creditNoteID = creditNoteData?.id||'';
        recordModified.record['kindOfDuplicate'] = 'duplicate'
        //update('facturas', {id: record.id, data: {...dataCleaned}})

        redirect('create', 'facturas', undefined, {}, { ...recordModified });
    }
    const handleReMake = () => {
        console.log('handleRemake', recordModified);
        let { productFact, remisiones,  ordenes, ...dataCleaned } = record;
        dataCleaned.estado = 'void';
        dataCleaned.creditNoteID = creditNoteData?.id||'';
        const data = remakeData(recordModified.record)
        //update('facturas', {id: record.id, data: {...dataCleaned}})
        console.log('Remake DATA', data)
        redirect('create', 'facturas', undefined, {}, { ...data });
    }

    if(creditNoteData&&creditNoteData?.invoices[0].fullNumber !== record?.alegraNumeracion){
        notify('La factura no coincide con la nota crédito', {type:'warning'});
    }
    if (error) { 
        console.log(error)
        notify(`Error al anular la factura: ${error[0]?.message}`, {type:'error'});
        }

    return (
        <Toolbar>
            <Button onClick={handleDuplicate}  variant='outlined' disabled={!creditNoteData&&creditNoteData?.invoices[0].fullNumber !== record?.alegraNumeracion } startIcon = {isPending ? <CircularProgress/>:<ContentCopyIcon/>}>
                Duplicar
            </Button>
            <Button onClick={handleReMake}  variant='outlined' disabled={!creditNoteData&&creditNoteData?.invoices[0].fullNumber !== record?.alegraNumeracion } startIcon = {isPending ? <CircularProgress/>:<EditNoteIcon/>}>
                Modificar
            </Button>
        </Toolbar>
    );
}   




const remakeData = (record) => {
    const user = localStorage.getItem('user')
    const { productFact, itemsFact, remisiones,  ordenes, ...dataCleaned } = record;
    const newItemsFact = [...itemsFact, ...record.productFact.items].map(item => { 
    const { idItem, odc, alegraItemId, orden, iva, type, plateType, material, calibre, ordenVersion, descripcion,
         valorUnitario, version, ancho, largo, cantidad, cajaNro, caja, area, valorTotal } = item;

    return {idItem, odc:odc.trim(), alegraItemId, orden, iva, type, plateType, material, calibre, ordenVersion, 
        descripcion, valorUnitario, version, ancho, largo, cantidad, cajaNro, caja, area, valorTotal}
    })
    console.log('NEW ITEMS FACT', newItemsFact)



    const objState = {
        record: {
            usuarioID: user,
            clienteId: record.clienteId || null,
            vendedorAlegraId: record.vendedorAlegraId || null,
            vendedorID: record.vendedorID || null,
            ciudadId : record.ciudadId || null,
            applyIva : record.applyIva || null,
            clienteName: record.clienteName || null,
            clientData: record.clientData,
            alegraClienteId: record.alegraClienteId || null,
            productFact: {items:[]},
            formaPago: record.clientData.datosFacturacion.formaPago || null,
            remisionesIDs: [],
            observaciones: record.observaciones,
            itemsFact: newItemsFact,
            emails: record.emails || [],
            kindOfDuplicate: 'remake'
        }
    }
    console.log('REMAKE DATA', objState)
    return objState

}

export default VoidInvoiceButton;