import React, { useEffect, useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RuleRoundedIcon from '@mui/icons-material/RuleRounded';
import { removeDuplicates } from '../../../utils/utilsExposicion';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Card, Divider, CardContent, Typography } from '@mui/material'; 
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import SensorOccupiedRoundedIcon from '@mui/icons-material/SensorOccupiedRounded';
import { FilterExpoStyled, filterListItemStyled } from '../style/ExposicionStyles';
import { Button, FilterList, useListContext, useUnselectAll, FilterListItem, SearchInput, useGetOne, TextInput } from 'react-admin';

export const ExposicionFilter = [ 
    <SearchInput alwaysOn source='q'/>,
    <TextInput alwaysOn source='expoName.eq' label='Buscar Exposición'/>
];

const cityMap = { 
    'cali': 'CALI',
    'env': 'ENVIGADO',
    'baq': 'BARRANQUILLA'
};

export const FiltersExposicion = () => {

    const userId = localStorage?.getItem('user');
    const { data: usuario } = useGetOne('usuarios', { id: userId });
    const [isPendienteTrue, setIsPendienteTrue] = useState(false);
    const { data, total, setFilters, filterValues } = useListContext();
    const unselectAll = useUnselectAll('exposicions');
    const cajas = removeDuplicates(data, 'cajaId');
    const calibre = removeDuplicates(data, 'calibre');
    const responsableExpo = removeDuplicates(data, 'responsableId');
    const responsableAnulacion = removeDuplicates(data, 'responsableId');
    const responsableReposicion = removeDuplicates(data, 'reposicionName');

    const handlePendienteChange = (value) => {
        setIsPendienteTrue(value.pendienteSalida?.eq === true);
        console.log('Pendiente Salida Sí', value);
    };

    const sedeUsuario = usuario?.sedes || []; 

    useEffect(() => {
        if (filterValues) {
            unselectAll();
        }
    }, [filterValues, data, unselectAll]);

    if (!data && data?.length === 0) {
        return (
            <Card sx={FilterExpoStyled?.card}>
                <CardContent>
                    <Button onClick={() => setFilters({})}>
                        {`Limpiar Filtros`}
                    </Button>
                </CardContent>
            </Card>
        );
    };

    const valuePendiente = {
        si: { pendienteSalida: { eq: true }, anulada: { eq: false }, reposicion: { eq: false }},
        no: { pendienteSalida: { eq: false }, anulada: { eq: false }, reposicion: { eq: false }}
    };

    const valueAnulacion = {
        si: { anulada: { eq: true }, reposicion: { eq: false }, pendienteSalida: { eq: true }},
        no: { anulada: { eq: false }, reposicion: { eq: false }, pendienteSalida: { eq: true }}
    };

    const valueReposicion = {
        si: { reposicion: { eq: true }, anulada: { eq: false }, pendienteSalida: { eq: true }},
        no: { reposicion: { eq: false }, anulada: { eq: false }, pendienteSalida: { eq: true }}
    };

    return (
        <>
            <Card sx={FilterExpoStyled?.card}>
                <CardContent sx={FilterExpoStyled?.cardContent}>
                    <Typography sx={FilterExpoStyled?.typography} variant='body2'>
                        {`Exposiciones #${total}` || 0}
                    </Typography>
                    <Divider sx={{ mt: 2 }}/>
                    <FilterList icon={<LocationCityRoundedIcon sx={FilterExpoStyled?.iconCity}/>} label='Ciudades' style={FilterExpoStyled?.filterList}>
                        {sedeUsuario?.includes('baq') && (
                            <FilterListItem
                                value={{ ciudad: { eq: cityMap['baq']}}}
                                sx={filterListItemStyled?.cityPosition}
                                onChange={(value) => console.log('Ciudad seleccionada ->', value)}
                                label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{'Barranquilla'}</Typography>}
                            />
                        )}
                        {sedeUsuario?.includes('env') && (
                            <FilterListItem
                                value={{ ciudad: { eq: cityMap['env']}}}
                                sx={filterListItemStyled?.cityPosition}
                                onChange={(value) => console.log('Ciudad seleccionada ->', value)}
                                label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{'Envigado'}</Typography>}
                            />
                        )}
                        {sedeUsuario?.includes('cali') && (
                            <FilterListItem
                                value={{ ciudad: { eq: cityMap['cali']}}}
                                sx={filterListItemStyled?.cityPosition}
                                onChange={(value) => console.log('Ciudad seleccionada ->', value)}
                                label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{'Cali'}</Typography>}
                            />
                        )}
                    </FilterList>
                    <Divider sx={{ mt: 2 }}/>
                    {responsableExpo?.some(item => item?.anulada === false) && (
                        <>
                            <FilterList sx={FilterExpoStyled?.stylizedFilterList?.filterList} style={{ fontSize: '14px' }} label='Responsable' icon={<PersonRoundedIcon color='success' sx={FilterExpoStyled?.stylizedFilterList?.iconStyled}/>}>
                                {responsableExpo?.filter(item => item?.anulada === false)?.map((item) => (
                                    <FilterListItem
                                        key={item?.responsableName}
                                        source='responsableName?.eq'
                                        sx={filterListItemStyled?.responsePosition}
                                        value={{ responsableName: { eq: item?.responsableName }}}
                                        onChange={(value) => console.log('value responsableName', value)}
                                        label={<Typography sx={{ ...FilterExpoStyled?.stylizedFilterList?.label, marginLeft: { lg: '1em', xl: '0.5em' }}}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{item?.responsableName || ''}</Typography>}
                                    />
                                ))}
                            </FilterList>
                            <Divider sx={{ mt: 2 }}/>
                        </>
                    )}
                    {responsableAnulacion?.some(item => item?.anulada === true) && (
                        <>
                            <FilterList sx={FilterExpoStyled?.stylizedFilterList?.filterList} style={{ fontSize: '14px'}} label='Anulación' icon={<PersonOffRoundedIcon color='warning' sx={FilterExpoStyled?.stylizedFilterList?.iconStyled}/>}>
                                {responsableAnulacion?.filter(item => item?.anulada === true)?.map((item) => (
                                    <FilterListItem
                                        key={item?.responsableName}
                                        source='responsableName?.eq'
                                        sx={filterListItemStyled?.responsePosition}
                                        value={{ responsableName: { eq: item?.responsableName }}}
                                        onChange={(value) => console.log('value responsableName', value)}
                                        label={<Typography sx={{ ...FilterExpoStyled?.stylizedFilterList?.label, marginLeft: { lg: '1em', xl: '0.5em' }}}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{item?.responsableName || ''}</Typography>}
                                    />
                                ))}
                            </FilterList>
                            {responsableAnulacion?.some(item => item?.anulada === true) ? (
                                <Divider sx={{ mt: 2 }} />
                            ):(
                                <span/>
                            )}
                        </>
                    )}
                    {responsableReposicion && responsableReposicion?.length > 0 && (
                        <>
                            <FilterList sx={FilterExpoStyled?.stylizedFilterList?.filterList} style={{ fontSize: '14px'}} label='Reposición' icon={<SensorOccupiedRoundedIcon color='error' sx={FilterExpoStyled?.stylizedFilterList?.iconStyled}/>}>
                                {responsableReposicion?.map((item) => (
                                    <FilterListItem
                                        key={item?.reposicionName}
                                        source='reposicionName?.eq'
                                        sx={filterListItemStyled?.responsePosition}
                                        value={{ reposicionName: { eq: item?.reposicionName }}}
                                        onChange={(value) => console.log('value reposicionName', value)}
                                        label={<Typography sx={{ ...FilterExpoStyled?.stylizedFilterList?.label, marginLeft: { lg: '1em', xl: '0.8em' }}}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{item?.reposicionName || ''}</Typography>}
                                    />
                                ))}
                            </FilterList>
                            {responsableReposicion?.length > 0 ? (
                                <Divider sx={{ mt: 2 }} />
                            ):(
                                <span/>
                            )}
                        </>
                    )}
                    <FilterList sx={FilterExpoStyled?.stylizedFilterList?.filterList} style={{ fontSize: '12px' }} label='Pendientes' icon={<RuleRoundedIcon color='success' sx={FilterExpoStyled?.stylizedFilterList?.iconStyled}/>}>
                        <FilterListItem
                            value={valuePendiente?.si}
                            onChange={handlePendienteChange}
                            style={{ marginLeft: '-0.7em' }}
                            sx={FilterExpoStyled.stylizedFilterList?.iconStyled}
                            label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{'Sí'}</Typography>}
                        />
                        <FilterListItem
                            value={valuePendiente?.no}
                            style={{ marginLeft: '-0.7em' }}
                            sx={FilterExpoStyled.stylizedFilterList?.iconStyled}
                            onChange={(value) => { setIsPendienteTrue(false); console.log('Pendiente Salida No', value)}}
                            label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{'No'}</Typography>}
                        />
                    </FilterList>
                    <Divider sx={{ mt: 2 }}/>
                    <FilterList sx={FilterExpoStyled?.stylizedFilterList?.filterList} style={{ fontSize: '12px' }} label='Anuladas' icon={<RuleRoundedIcon color='warning' sx={FilterExpoStyled?.stylizedFilterList?.iconStyled}/>}>
                        <FilterListItem
                            value={valueAnulacion?.si}
                            style={{ marginLeft: '-0.7em' }}
                            onChange={(value) => console.log('Exposicion Anulada Sí', value)}
                            sx={!isPendienteTrue ? { '& .MuiListItemSecondaryAction-root': { display: 'none' }} : {}}
                            label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{'Sí'}</Typography>}
                        />
                        <FilterListItem
                            value={valueAnulacion?.no}
                            style={{ marginLeft: '-0.7em' }}
                            onChange={(value) => console.log('Exposicion Anulada No', value)}
                            sx={!isPendienteTrue ? { '& .MuiListItemSecondaryAction-root': { display: 'none' }} : {}}
                            label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{'No'}</Typography>}                        
                        />
                    </FilterList>
                    <Divider sx={{ mt: 2 }}/>
                    <FilterList sx={FilterExpoStyled?.stylizedFilterList?.filterList} style={{ fontSize: '12px' }} label='Reposiciones' icon={<RuleRoundedIcon color='error' sx={FilterExpoStyled?.stylizedFilterList?.iconStyled}/>}>
                        <FilterListItem
                            style={{ marginLeft: '-0.7em' }}
                            value={valueReposicion?.si}
                            onChange={(value) => console.log('Reposicion Sí', value)}
                            sx={!isPendienteTrue ? { '& .MuiListItemSecondaryAction-root': { display: 'none' }} : {}}
                            label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{'Sí'}</Typography>}                            
                        />
                        <FilterListItem
                            style={{ marginLeft: '-0.7em' }}
                            value={valueReposicion?.no}
                            onChange={(value) => console.log('Reposicion No', value)}
                            sx={!isPendienteTrue ? { '& .MuiListItemSecondaryAction-root': { display: 'none' }} : {}}
                            label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{'No'}</Typography>}
                        />
                    </FilterList>
                    <Divider sx={{ mt: 2 }}/>
                    {calibre && calibre?.length > 0 && (
                        <>
                            <FilterList sx={FilterExpoStyled?.stylizedFilterList?.filterList} style={{ fontSize: '14px' }} label='Calibre' icon={<ListAltIcon sx={FilterExpoStyled?.stylizedFilterList?.iconStyled}/>}>
                                {calibre?.map((item) => (
                                    <FilterListItem
                                        key={item?.calibre}
                                        source='calibre.eq'
                                        sx={{ marginLeft: '-1em' }} 
                                        value={{ calibre: { eq: item?.calibre }}}
                                        onChange={(value) => console.log('value calibre', value)}
                                        label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{item?.calibre || ''}</Typography>}
                                    />
                                ))}
                            </FilterList>
                            {cajas && cajas?.length > 0 ? (
                                <Divider sx={{ mt: 2 }}/>
                            ):(
                                <span/>
                            )}
                        </> 
                    )}
                    {cajas && cajas?.length > 0 && (
                        <FilterList sx={FilterExpoStyled?.stylizedFilterList?.filterList} style={{ fontSize: '14px' }} label='Cajas' icon={<AllInboxOutlinedIcon sx={FilterExpoStyled?.stylizedFilterList?.iconStyled}/>}>
                            {cajas?.map((item) => {
                                const cajaRegex = item?.cajaId?.match(/-(\d+)$/);
                                const caja = cajaRegex ? cajaRegex[1] : item?.cajaId;
                                return (
                                    <FilterListItem
                                        key={item?.cajaId}
                                        source='cajaId?.eq'
                                        sx={{ marginLeft: '-1em' }}
                                        value={{ cajaId: { eq: item?.cajaId }}}
                                        onChange={(value) => console.log('value cajaId', value)}
                                        label={<Typography sx={FilterExpoStyled?.stylizedFilterList?.label}><CircleIcon sx={FilterExpoStyled?.stylizedFilterList?.iconLabel}/>{caja || ''}</Typography>}
                                    />
                                );
                            })}
                        </FilterList>
                    )}
                </CardContent>
            </Card>
        </>
    )
};