import React from 'react';
import  pdfMake  from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { logo } from './logoB64';
import { formatCurrencyString } from '../../utils';
import { num2Str } from './num2str';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePDF = (data, client) => {
  const rows = generateEmptyLines(data.itemsFact)
  

  const docDefinition = {
    pageSize: { height: 5.5*25.4*2.835, width: 8.5*25.4*2.835 },//media carta en puntos
    pageOrientation: 'landscape',
    pageMargins: [15, 10, 15, 25],
    footer: function(currentPage, pageCount) {
      return { text: `${data.alegraNumeracion}       P. ${currentPage} / ${pageCount}`, alignment: 'right', margin:[0, 0, 20, 0], color: 'grey', fontSize: 6 };
    },
    content: [
      {
        columns: [
          {
            stack: [
              {
                image: logo,
                width: 260,
                alignment: 'center'
              },
              { text: 'NIT: 800.256.139-6 IVA Régimen Común', fontSize: 6, style: 'bold' },
              { text: 'Medellin: Carrera 49 A # 48 sur 60 Bod. 121 Tel: (604) 44 88 508 - Cel: 312 222 7546', fontSize: 6, style: 'bold' },
              { text: 'Barranquilla: Via 40 # 85 - 470 Of.15A - Cel:321 475 3304', fontSize: 6, style: 'bold' },
              { text: 'Cali: Calle 15 # 35 - 75, Yumbo - Cels: 312 776 3711 - 311 632 1368', fontSize: 6, style: 'bold' },
              { text: 'www.grafiflex.com - E-mail: servicliente@grafiflex.com', fontSize: 6, style: 'bold' }
            ],
            alignment: 'center',
            width: 260
          },
          {
            columns: [
              { width: '*', text: '' },
              {
                stack: [
                  { text: 'REMISIÓN', style: 'subheader', margin : [0, 20, 0, 0]  },
                  { text: data.alegraNumeracion, style: 'header' },
                  { text: `Fecha: ${new Date(data.fechaCrea).toLocaleDateString('es-CO')}`,  }
                ],
                alignment: 'center'
              }
            ],
            margin: [0, 0, 0, 0]
          }
        ]
      },
      {
        columns: [
          {
            width: 200,
            text: [
              { text: 'Cliente:\n', style: 'bold' },
              { text: client.name, style: 'bold', fontSize: client.name.length > 27 ? client.name.length > 35 ? 8.5 : 9 : 10}
            ]
          },
          {
            width: '*',
            text: [
              { text: 'Nit.\n', style: 'bold' },
              { text:client.identification, fontSize: client.name.length > 27 ? client.name.length > 35 ? 8.5 : 9 : 10 }
            ]
          },
          {
            width: 200,
            text: [
              { text: 'Dirección\n', style: 'bold' },
              {text:`${client.address.address}\n`, fontSize: (client.address.address.length  > 27 || client.name.length > 27) ? (client.address.address.length  > 35 || client.name.length > 35) ? 8.5 : 9 : 10}
              
              
            ]
          },
          {
            width: 'auto',
            text: [
              { text: 'Tel:\n', style: 'bold' },
              {text: client.phonePrimary , fontSize: client.name.length > 27 ? client.name.length > 35 ? 8.5 : 9 : 10}
            ]
          }
        ]
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 0,
            x2: 8.5*25.4*2.835-15-15, // Width of the page minus the margins
            y2: 0,
            lineWidth: 5,
            lineColor: 'lightgray'
          }
        ],
        margin: [0, 10, 0, 10] // Adjust the margin as needed
      },
      {
        table: {
          headerRows: 1,
          widths: [40, 30, 65, 150, 30, 60, 35, 45, 50],
          body: [
            [
              { text: 'Orden', style: 'tableHeader' },
              { text: 'OC', style: 'tableHeader' },
              { text: 'Producto', style: 'tableHeader' },
              { text: 'Descripción', style: 'tableHeader' },
              { text: 'Cant', style: 'tableHeader' },
              { text: 'Dimensiones', style: 'tableHeader' },
              { text: 'Area', style: 'tableHeader' },
              { text: 'V. Unit.', style: 'tableHeader' },
              { text: 'Total', style: 'tableHeader' }
            ],
            ...rows
          ]
        },
        layout: 'noBorders'
      },
      {
        table: {
          widths: ['*', '25%'],
          body: [
            [{
              text: num2Str(data.total),
              fillColor: 'lightgray',
              fontSize: num2Str(data.total).length > 70 ? num2Str(data.total).length > 90 ? 6.5 : 8 : 9,
              border: [false, false, false, false],
              margin: [5, 0, 0, 0],
              height: 10 // You can adjust the height as needed
            },
            {
              text: `Area: ${data.totalArea} cm²`,
              fillColor: 'lightgray',
              style:'bold',
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
              height: 10 // You can adjust the height as needed
            }]
          ]
        },
        layout: 'noBorders'
      },
      {
        columns: [
          {
            table: {
              widths: ['*'],
                heights: [20, 10],
              body: [
                [{
                  text: data.observaciones || ' ',
                  fontSize: 8,
                  border: [false, false, false, true],
                  alignment: 'left',
                  margin: [0, 0, 0, 0],
                },
    
                ]
                ,[{text: 'Observaciones', style: 'subheader', alignment: 'center', border: [false, false, false, false]}]
              ]
            },
          },
           {
            table: {
              widths: ['*'],
                heights: [20, 10],
              body: [
                [{
                  text: ' ',
                  border: [false, false, false, true],
                  alignment: 'left',
                  margin: [0, 0, 0, 0],
                },
    
                ]
                ,[{text: 'Recibido', style: 'subheader', alignment: 'center', border: [false, false, false, false]}]
              ]
            },
          },
          {
            stack: [
              { columns: [{ text: "Subtotal: ", style: 'subheader', alignment: 'right' }, { text: formatCurrencyString(data.subtotal), alignment: 'left' }] },
              { columns: [{ text: "IVA: ", style: 'subheader', alignment: 'right' }, { text: formatCurrencyString(data.iva), alignment: 'left' }] },
              { columns: [{ text: "Total: ", style: 'subheader', alignment: 'right' }, { text: formatCurrencyString(data.total), alignment: 'left' }] }
            ],
            width: 181
          }
        ],
        margin: [0, 10, 0, 0],
        columnGap: 10
      }
    ],
    pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      return currentNode.headlineLevel === 1 && nodesOnNextPage.length > 0;
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        backgroundColor: 'gray',
        margin: [0, 0, 0, 0]
      },
      observaciones: {
        fontSize: 7,
        alignment: 'justify',
        margin: [0, 0, 0, 0]
      },
      subheader: {
        fontSize: 13,
        bold: true,
        margin: [0, 0, 0, 0]
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black'
      },
      bold: {
        bold: true
      },
      tableContent: {
        fontSize: 8 // Adjust the font size here
      },
      tableExample: {
        margin: [0, 5, 0, 15]
      }
    }
  };

  //pdfMake.createPdf(docDefinition).open({}, window.open('', `_blank`),);
  pdfMake.createPdf(docDefinition).download(`${data.alegraNumeracion}-${client.name.slice(0,8)}.pdf`);
  

};


const generateEmptyLines = (items) => {
  let count = 0;

  // Calculate the number of lines for each item description
  for (let i = 0; i < items.length; i++) {
    let lines = items[i].descripcion;
    if (lines.length > 30) {
      //count += Math.ceil(lines.length / 33);
      count++;
      const words = lines.slice(0, 30);
      items[i].descripcion = words + '...';
    } else {
      count++;
    }
  }
  console.log('COUNT', count)
  // Generate rows based on items
  
  const rows = items.map(item => [
    { text: item.ordenVersion, style: 'tableContent' },
    { text: item.odc?.trim() || ' ', style: 'tableContent' },
    { text: item&&item.caja?item.caja.slice(0, 13):item.material.slice(0,11), style: 'tableContent' },
    { text: item.descripcion, style: 'tableContent' },
    { text: item.cantidad, style: 'tableContent' },
    { text: item.ancho&&item.largo?`${item.ancho}x${item.largo}`:'', style: 'tableContent' },
    { text: item.area?item.area:'', style: 'tableContent' },
    { text: item.valorUnitario, style: 'tableContent' },
    { text: formatCurrencyString(item.valorTotal), style: 'tableContent' }
  ]);
  // JD4584 MARQUILLA AZZORTI SIN TALLA
  // JD5232 MARQUILLA IMPORTADORES 3.....
  // Insert empty rows if needed
  if (count <= 10) {
    let emptyLines = 9-count;
    for (let i = 0; i < emptyLines; i++) {
      rows.push([
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' }
      ]);
    }
  } else if (count >= 11 && count <= 17) {
    //const linesPerRow = Math.floor(count / rows.length);
    const insertPosition =  rows.length - 2;
    const newItem = [
      { text: ' ', style: 'tableContent' },
      { text: ' ', style: 'tableContent' },
      { text: ' ', style: 'tableContent' },
      { text: ' ', style: 'tableContent' },
      { text: ' ', style: 'tableContent' },
      { text: ' ', style: 'tableContent' },
      { text: ' ', style: 'tableContent' },
      { text: ' ', style: 'tableContent' },
      { text: ' ', style: 'tableContent', pageBreak: 'after' }
    ];
    rows.splice(insertPosition, 0, newItem);
  } 
  else if (count > 17 && count <= 36  ) {
    const linesPerRow = Math.floor(count / rows.length);
    const insertPosition =  count > 35 ? 40 / linesPerRow : count<22? rows.length - 2 : 0;
    if(insertPosition !== 0){
      const newItem = [
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent', pageBreak: 'after' }
      ];
      rows.splice(insertPosition, 0, newItem);
    }
  }
  else{
    //no added lines, no pagebreak
  }

  return rows;
};


