import { Box, Grid, Typography } from '@mui/material';

export const ChatBubbleObserv = ({ observation }) => {
  const bubbleStyle = {
    margin: 'auto',
    padding: '1em',
    minWidth: '20px',
    maxWidth: '360px',
    display: 'flex',
    borderRadius: '15px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)',
    backgroundColor: observation?.PRIORIDAD === 1 ? 'rgba(25, 255, 25, 0.4)' : 'lightblue', 
  };

  const textStyle = {
    color: 'black',
    textAlign: 'center',
    fontSize: { lg: '0.78em', xl: '1em'},
    fontWeight: observation?.PRIORIDAD === 1 ? 'bold' : 'normal',
  };

  const ordenInfoStyle = {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: { lg: '0.78em', xl: '1em'},
  };
  
  const userInfoStyle = {
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: { lg: '0.78em', xl: '1em'},
  };

  return (
    <Box sx={bubbleStyle}>
      <Typography variant='body3' sx={{ display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={ordenInfoStyle}>{`${observation?.NUMERO}-${observation?.VERSIONOP}`}</Box>
        <Box sx={userInfoStyle}>{observation?.USUARIO}</Box>
      </Typography>
      <Typography variant='body2' sx={textStyle}>{observation?.OBSERV}</Typography>
    </Box>
  );
};

export const ChatBubble = ({observaciones}) => {
  return(
    <>
      {observaciones?.length > 0 ? (
        <Box sx={{ margin: '1em', width: '100%' }}>
          <Grid container spacing={2} justifyContent='center'>
            {observaciones?.map((observation, idx) => (
              <Grid item xs={12} lg={6} key={idx}>
                <ChatBubbleObserv observation={observation}/>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box/>
      )}
    </>
  )
};