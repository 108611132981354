const ListStyled = {
  datagrid: {
    '& .RaDatagrid-headerCell': {
      fontSize: '13px', fontWeight: 'bold', textAlign: 'center'
    },
    '& .column-estado': { 
      flexGrow: 1, 
      minWidth: '20px', 
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '95px', xl: '100px'},
    },
    '& .column-imageExp': { 
      flexGrow: 1,
      minWidth: '20px', 
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '100px', xl: '100px'},
    },
    '& .column-expoName': {
      lexGrow: 1,
      minWidth: '40px',
      overflow: 'hidden',
      textAlign: 'center',
      wordWrap: 'break-word',
      textOverflow: 'ellipsis',
      overflowWrap: 'break-word',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '125px', sm: '125px', md: '125px', lg: '125px', xl: '130px'},
    },
    '& .column-plateName': { 
      flexGrow: 1, 
      minWidth: '20px', 
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '95px', xl: '100px'},
    },
    '& .column-areaTotalLen': { 
      flexGrow: 1,
      minWidth: '20px', 
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '95px', xl: '100px'},
    },
    '& .column-referencia': { 
      flexGrow: 1, 
      minWidth: '20px', 
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '95px', xl: '100px'},
    },
    '& .column-dateExposed': { 
      flexGrow: 1,
      minWidth: '20px', 
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '100px', xl: '100px'},
    },
    '& .column-updatedAt': { 
      flexGrow: 1,
      minWidth: '20px', 
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '100px', xl: '100px'},
    },
    '& .column-responsable': { 
      flexGrow: 1,
      minWidth: '20px',
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '95px', xl: '100px'},
    },
    '& .column-gestion': { 
      flexGrow: 1,
      minWidth: '20px', 
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '95px', xl: '100px'},
    },
    '& .column-reverse': { 
      flexGrow: 1, 
      minWidth: '20px', 
      textAlign: 'center',
      fontSize: { xs: '9px', sm: '9.5px', md: '10px', lg: '10px', xl: '12px'},
      maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '95px', xl: '100px'},
    },
  },

  iconStateSize: {
    fontSize: { xs: '18px', sm: '18px', md: '18px', lg: '20px', xl: '24px'},
  },

  componentFont: {
    fontSize: { xs: '11.5px', sm: '12px', md: '12.5px', lg: '11px', xl: '14px'}
  },

  responsable: {
    fontSize: { xs: '11.5px', sm: '12px', md: '12.5px', lg: '11px', xl: '13.5px'}
  },

  topToolBar: {
    marginTop:'10px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  labelProps:{
    fontSize: '11px',
    marginTop: '-1.5rem',
    marginLeft: '-4.5rem',
  },

  switchSx: {
    top: 10, 
    left: -8, 
    transform: 'scale(0.75)',
    '& .MuiSwitch-switchBase.Mui-checked' : { 
      color: 'white'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { 
      backgroundColor: 'black',
    },
  },
  
  medidasInfoFont: {
    fontWeight:'bold',
    fontSize: { xs: '11px', sm: '11px', md: '11px', lg: '11px', xl: '14px' }
  },

  saveButton: {
    fontSize: { xs: '11px', sm: '11px', md: '11px', lg: '10.5px', xl: '13px' },
    marginLeft: { xs: '11px', sm: '11px', md: '15em', lg: '65.973em', xl: '100.4em' },
  },

  radioButton: {
    '& .MuiRadio-root': {
      padding: '4px',
    },
    '& .MuiFormControlLabel-root': {
      marginRight: { xs: 1.5, sm: 1.5, md: 2, lg: 1, xl: 3 }
    },
    '& .MuiTypography-root': {
      fontSize: { xs: '0.75rem', sm: '0.75rem', md: '0.75rem', lg: '0.75rem', xl: '0.9rem' },
    },
    '& .MuiFormLabel-root': {
      fontSize: { xs: '0.9em', sm: '0.9em', md: '0.9em', lg: '1em', xl: '1.2em' },
    }
  },
};

const LenStyled = {
  datagrid: {
    '& .column-image': { 
      minWidth: '20px',
      fontWeight: 'bold',
      textAlign: 'center', 
      maxWidth: { xs: '40px', sm: '40px', md: '40px', lg: '40px', xl: '50px'},
      fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'}, 
    },
    '& .column-pantone': { 
      minWidth: '20px',
      fontWeight: 'bold',
      textAlign: 'center', 
      maxWidth: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px'},
      fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'},
    },
    '& .column-uuid': {
      minWidth: '20px',
      overflow: 'hidden',
      fontWeight: 'bold',
      textAlign: 'center',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      maxWidth: { xs: '80px', sm: '80px', md: '80px', lg: '70px', xl: '80px'},
      fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'},
    },    
    '& .column-name': {
      minWidth: '20px',
      overflow: 'hidden',
      fontWeight: 'bold',
      textAlign: 'center',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      maxWidth: { xs: '80px', sm: '80px', md: '80px', lg: '70px', xl: '80px'},
      fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'}, 
    },    
    '& .column-lenWidth': { 
      minWidth: '20px', 
      fontWeight: 'bold',
      textAlign: 'center', 
      maxWidth: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px'},
      fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'}, 
    },
    '& .column-lenHeight': { 
      minWidth: '20px', 
      fontWeight: 'bold',
      textAlign: 'center', 
      maxWidth: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px'},
      fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'},
    },
    '& .column-area': { 
      minWidth: '20px',
      fontWeight: 'bold',
      textAlign: 'center',
      maxWidth: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px'},
      fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'},
    },
    '& .column-lenId': { 
      minWidth: '20px',
      overflow: 'hidden',
      fontWeight: 'bold',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px'},
      fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'},
    },
  },
  
  componentStyled: {
    fontWeight: 'bold',
    fontSize: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '14px'}
  },
  
  accordion: {
    boxShadow: 'none',
    
    lenEdit: {
      width: '80%'
    },

    accordionSummary: {
      marginLeft: { xs: '0.6em',  sm: '0.7em',  md: '0.8em',  lg: '0.3em',  xl: '0.1em'},
    },
  
    reportIcon: {
      marginTop: '3px',
      fontSize: '1.7rem',
      marginLeft: '1.5rem',
    },
  
    warningIcon: {
      color: '#EC9B25',
      marginTop: '3px',
      fontSize: '1.7rem',
      marginLeft: '1.5rem',
    },
  
    newReleasesIcon: {
      color: '#A39C00',
      marginTop: '3px',
      fontSize: '1.7rem',
      marginLeft: '1.5rem',
    },
  
    beenHereIcon: {
      color: '#39A300',
      marginTop: '3px',
      fontSize: '1.7rem',
      marginLeft: '1.5rem',
    },

    typography: { 
      fontWeight: 'bold',
      marginTop: { xs: '0.6em',  sm: '0.7em',  md: '0.8em',  lg: '0.3em',  xl: '0.1em'},
      fontSize: { xs: '0.6em',  sm: '0.7em',  md: '0.8em',  lg: '0.89em',  xl: '1.2em'},
    },
  },
};

const DialogStylized = {
  dialog: {
    dialogTitle : {
      display: 'flex', 
      marginTop:'-1em',
      fontWeight: 'bold',
      alignItems: 'center',
      justifyContent: 'center',
    },
    box1HasConditions: {
      fontSize: { xs: '14.5px', sm: '14.5px', md: '15.5px', lg: '16px', xl: '20px'},
    },
    button: { 
      width: { xs: '70px', sm: '75px', md: '80px', lg: '80px', xl: '95px'},
      fontSize: { xs: '7px', sm: '8px', md: '8.5px', lg: '9.5px', xl: '12px'}
    },
    box1: {
      marginRight: '2rem', 
      fontSize: { xs: '13.5px', sm: '13.5px', md: '13.5px', lg: '14px', xl: '18px'}
    },
    box2: {
      flex: 1,
      marginTop:' auto',
      textAlign: 'right',
      marginBottom: 'auto',
    },
    gridImageView: {
      '& img': { 
        minHeight: { xs: 140, sm: 160, md: 180, lg: 200, xl: 260 }
      },
      margintop: { xs: '1em', sm: '1em', md: '1em', lg: '1em', xl: '1.5em'},
    },
    gridInfoExpo: {
      marginTop: { xs: '1em', sm: '1em', md: '1em', lg: '1em', xl: '1.3em'},
      fontSize: { xs: '12px', sm: '12px', md: '12px', lg: '12px', xl: '16px'},
    },
    dialogActions: {
      zIndex: 2,
      bottom: 0, 
      position: 'sticky', 
      backgroundColor: 'white',
    },
  },

  layout: {
    inputs: {
      '& .MuiInputBase-root': {
        height: { xs: '35px', sm: '35px', md: '35px', lg: '35px', xl: '40px'},
        fontSize: { xs: '13px', sm: '13px', md: '13px', lg: '13px', xl: '16px'}
      },
      '& .MuiFormLabel-root': {
        lineHeight: { xs: '1.5', sm: '1.5', md: '1.5', lg: '1.5', xl: '1.5'},
        fontSize: { xs: '12px', sm: '12px', md: '12px', lg: '12px', xl: '16px'}
      },
      '& .MuiFormHelperText-root': {
        fontSize: { xs: '9.5px', sm: '9.5px', md: '9.5px', lg: '9.5px', xl: '12px'}
      },
    },
    priorityHigh: {
      fontSize:'20px',
      color: '#D11000', 
      marginTop: '10px', 
      marginLeft: '-1em', 
    },
    gridLayout: {
      marginTop: { xs: '3em', sm: '3em', md: '3em', lg: '2.5em', xl: '4em' },
    },
  },
  
  showInfoMedidas: {
    dialog: {
      icon: {
        fontSize: { xs: '9.5px', sm: '10px', md: '10.5px', lg: '11px', xl: '12px'}
      },
      buttonProps: {
        sx: {
          width: { xs: '55px', sm: '55px', md: '55px', lg: '55px', xl: '95px'},
          fontSize: { xs: '7px', sm: '8px', md: '8.5px', lg: '9.5px', xl: '12px'},
        },
      },
    },
    title: {
      fontSize: '20px',
      fontWeight: 'bold', 
      textAlign: 'center', 
    },
    grid1: {
      marginTop: '2rem', 
      marginLeft: '-1em',
      textAlign: 'center', 
    }
  },
};

const FilterExpoStyled = {
  card: {
    mt: 1.5,
    mr: 0,
    order: -1,
    width: { sx: 140, xs: 140, md: 140, lg: 140, xl: 170 },
  },

  iconCity: {
    fontSize: '15px',
  },

  filterListItem: {
    fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '9px', xl: '14px'},
  },

  typography: {
    textAlign: 'center',
    fontSize: { xs: '11px', sm: '11px', md: '11px', lg: '12px', xl: '14px'},
  },

  filterList: {
    fontSize: { xs: '6px', sm: '6.5px', md: '10px', lg: '13px', xl: '15px'}
  },

  stylizedFilterList : {
    cityPosition: {
      ml: { lg: -3.85, xl: -3 }, 
      width: { lg: 143.5, xl: 170 },
    },
    responsePosition: {
      ml: { lg: -4.25, xl: -5 }, 
      width: { lg: 143.5, xl: 170 },
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      fontSize: { xs: '11px', sm: '11px', md: '11px', lg: '12px', xl: '14px'}
    },
    iconStyled: {
      fontSize: 15,
      marginLeft: { xs: '-0.8em', sm: '-0.8em', md: '-0.8em', lg: '-0.8em', xl: '-0.5em'}
    },
    iconLabel: {
      fontSize: 6, 
      marginRight: 1.5,
    },
    filterList: {
      marginLeft: { xs: '1em', sm: '1em', md: '1em', lg: '0.3em', xl: '1em'}
    }
  },
};

const DialogCaja = {
  justifyContent: 'center',
  marginLeft: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '-1.8em', xl: '-1.5em' },
  marginTop: { xs: '-0.3em', sm: '-0.3em', md: '-0.3em', lg: '-0.65em', xl: '-0.85em' },

  iconButton: {
    fontSize: 
    window.innerWidth < 600 ? '30px' : 
    window.innerWidth < 960 ? '35px' : 
    window.innerWidth < 1280 ? '35px' : 
    window.innerWidth < 1920 ? '40px' : '50px',
  }
};

const ImageExpoStyle = {
  imageStyle : {
    margin: '0px',
    padding: '0px', 
    display: 'flex',
    justifyContent: 'center'
  },

  gridStyle : {
    'img': {
      minWidth: '26.5%',
      minHeight: { xs: 460, sm: 460, md: 460, lg: 460, xl: 640 },
    }
  },

  iconButtonStyle : {
    color: 'black',
    backgroundColor: 'white',
  },

  sx: {
    '& img': {
      maxHeight: { xs: 65, sm: 70, md: 75, lg: 80, xl: 100 },
    }
  },
};

const ToolBarStyled = {
  fontSize: { xs: '11px', sm: '11px', md: '11px', lg: '11px', xl: '13px' },
};

const filterListItemStyled = {
  cityPosition: {
    ml: { lg: -3.85, xl: -3 }, 
    width: { lg: 143.5, xl: 170 },
  },
  responsePosition: {
    ml: { lg: -4.25, xl: -5 }, 
    width: { lg: 143.5, xl: 178 },
  },
};

export { DialogCaja, FilterExpoStyled, filterListItemStyled, ListStyled, LenStyled, DialogStylized, ToolBarStyled, ImageExpoStyle };