const ListStyled = {
    iconStateSize: {
        alignItems: 'center',
        fontSize: { xs: '18px', sm: '18px', md: '18px', lg: '20px', xl: '24px'},
    },
    componentFont: {
        alignItems: 'center',
        fontSize: { xs: '11px', sm: '11px', md: '11px', lg: '11.3px', xl: '14px'}
    },
};

const BubbleStyles = {
    body : {
        margin: 'auto',
        padding: '1em',
        maxWidth: '90%',
        display: 'flex',
        borderRadius: '15px',
        flexDirection: 'column',
        backgroundColor: 'lightyellow',
        justifyContent: 'space-between',
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)',
    },
      
    textStyle : {
        color: 'black',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: { lg: '0.78em', xl: '1em'},
    },
};

const FilterStyled = {
    card: {
        mt: 2,
        mr: 0,
        order: -1,
        marginLeft: {lg: '-0.5em', xl: '-0.5em'},
        width: { xs: 125, sm: 125, md: 125, lg: 145, xl: 170 },
    },
  
    filterListItem : {
        label: {
            display: 'flex',
            alignItems: 'center',
            fontSize: { xs: '11px', sm: '11px', md: '11px', lg: '11px', xl: '13.5px'}
        },
        iconStyled: {
            fontSize: 16,
            marginLeft: { xs: '-0.8em', sm: '-0.8em', md: '-0.8em', lg: '-0.8em', xl: '-0.5em'}
        },
        iconLabel: {
            fontSize: 6, 
            marginRight: 1.5
        },
        filterList: {
            marginLeft: { xs: '1em', sm: '1em', md: '1em', lg: '0.3em', xl: '1em'}
        },
        style: {
            marginLeft: '-0.7em'
        },
        sx: {
            ml: { lg: -5, xl: -5 }, 
            width: { lg: 130, xl: 140 }
        }
    },
};

const ToolBarStyled = {
    toolBar: {
        top: 13, 
        left: -2, 
        transform: 'scale(0.75)', 
        '& .MuiSwitch-switchBase.Mui-checked': { color: 'white' },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: 'black' },
    },
    formControlLabel: {
        marginLeft: '15px',
    },
    typography: {
        fontSize: '11px',
        marginTop: '-1rem', 
        marginLeft: '-4rem', 
    }
};

const AddDiferencia = {
    sxButton: {
        alignItems: 'center',
        justifyContent: 'center',
        width: { xs: '65px', sm: '65px', md: '65px', lg: '65px', xl: '85px'},
        fontSize: { xs: '9.5px', sm: '9.5px', md: '19.5px', lg: '9.5px', xl: '12px'},
    },
    styleButton: {
        alignItems: 'center', marginLeft: '-1em'
    },
    dialogTitleStyle: {
        display: 'flex', color: 'whitesmoke', background: 'black', alignItems: 'center', justifyContent: 'center',
    },
    typography: {
        fontWeight: 'bold', alignItems: 'center', justifyContent: 'center',
    },
    dialogActions: {
        position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1
    },
    errorButton: {
        background: 'red', color: 'white', fontWeight: 'bold',
    },
};

const DialogSalidaStyled = {
    dialogTitle: {
        display: 'flex',
        marginTop: '-0.75em',
        alignItems: 'center',
        justifyContent: 'center',
    },
    typography: {
        fontWeight: 'bold',
    },
    dialogActions: {
        position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1,
    },
};

const StyledDatagridOrden = {
    '& .RaDatagrid-headerCell': {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    
    '& .column-estado': { 
        flexGrow: 1,
        minWidth: '20px',
        textAlign: 'center',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '60px', xl: '50px'}, 
    },
    '& .column-op': { 
        flexGrow: 1,
        minWidth: '20px', 
        textAlign: 'center',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '60px', xl: '60px'}, 
    },
    '& .column-version': { 
        flexGrow: 1, 
        minWidth: '20px', 
        textAlign: 'center',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '60px', xl: '60px'}, 
    },
    '& .column-nameJob': { 
        flexGrow: 1,
        minWidth: '20px',
        overflow: 'hidden',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',        
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '150px', xl: '210px'}, 
    },
    '& .column-tipoJob': { 
        minWidth: '20px',
        overflow: 'hidden',
        fontWeight: 'bold',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '200px', xl: '160px'}, 
    },
    '& .column-clienteOrden': { 
        flexGrow: 1,
        minWidth: '20px',
        overflow: 'hidden',
        textAlign: 'center',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '110px', xl: '120px'}, 
    },
    '& .column-totalPlanchasExp': { 
        flexGrow: 1,
        minWidth: '20px', 
        textAlign: 'center',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '65px', xl: '60px'}, 
    },
    '& .column-totalPlanchasOrden': { 
        flexGrow: 1, 
        minWidth: '20px', 
        textAlign: 'center',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '65px', xl: '60px'}, 
    },
    '& .column-calibresExp': { 
        flexGrow: 1,
        minWidth: '20px', 
        textAlign: 'center',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '65px', xl: '60px'}, 
    },
    '& .column-calibreOrden': { 
        flexGrow: 1,
        minWidth: '20px', 
        textAlign: 'center',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '65 px', xl: '60px'}, 
    },
    '& .column-salidaOrdenes': { 
        flexGrow: 1, 
        minWidth: '20px',
        textAlign: 'center',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '60px', xl: '60px'}, 
    },
    '& .column-reverseSalida': { 
        flexGrow: 1, 
        minWidth: '20px',
        textAlign: 'center',
        fontSize: { xs: '9px', sm: '10px', md: '10px', lg: '10px', xl: '12px'},
        maxWidth: { xs: '95px', sm: '95px', md: '95px', lg: '100px', xl: '60px'}, 
    },
};

const OrdenMaterialStyled = {
    fontSize: { lg: '11.5px', xl: '14px'}
};
  
const StyledDatagridShowExposicion = {
    title: {
        textAlign: 'center', fontWeight: 'bold', fontSize: '20px',
    },
    datagrid: {
        '& .RaDatagrid-headerCell': {
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: { xs: '8px', sm: '8px', md: '10px', lg: '11px', xl: '11px'},
        },
        '& .column-imageExp': { 
            minWidth: '20px',
            textAlign: 'center',
            maxWidth: { xs: '85px', sm: '85px', md: '85px', lg: '50px', xl: '100px'},
        },
        '& .column-referencia': { 
            minWidth: '20px',
            textAlign: 'center',
            maxWidth: { xs: '85px', sm: '85px', md: '85px', lg: '50px', xl: '100px'},
        },
        '& .column-responsable': { 
            minWidth: '20px',
            textAlign: 'center',
            maxWidth: { xs: '85px', sm: '85px', md: '85px', lg: '50px', xl: '100px'},
        },
        '& .column-exposiciones': { 
            minWidth: '20px',
            textAlign: 'center',
            maxWidth: { xs: '85px', sm: '85px', md: '85px', lg: '50px', xl: '100px'},
        },
    },
};


export { ListStyled, FilterStyled, StyledDatagridOrden, DialogSalidaStyled, StyledDatagridShowExposicion, ToolBarStyled, BubbleStyles, AddDiferencia, OrdenMaterialStyled };