import { Grid } from '@mui/material';
import { ImageCDI } from './ImageCDI';
import React, { useState } from 'react';
import { LenContent } from './LenContent';
import Divider from '@mui/material/Divider';
import { InputsMedidas } from './InputsMedidas';
import { generateClient } from 'aws-amplify/api';
import { listOrdenByOp } from '../../../graphql/queries';
import { DialogStylized } from '../style/ExposicionStyles';
import { AnulacionSolicitud } from '../layouts/TypeSolicitud';
import { ShowInfoExposicion } from '../layouts/ShowInfoExposicion';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { validarExistenciaPin } from '../../../utils/utilsExposicion';
import { useNotify, useGetList, useDataProvider, useRecordContext, SaveButton, SimpleForm, Toolbar, useRedirect, useRefresh } from 'react-admin';

export const ExposicionForm = ({ expo }) => {

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const client = generateClient();
  const record = useRecordContext();
  const referencia = record?.referencia;
  const reposicion = record?.reposicion;
  const dataProvider = useDataProvider();
  const nombrePlancha = record?.plateName;
  const hasRepoExpo = reposicion === true;
  const [cajaManual, setCajaManual] = useState(null);
  const [selectedCaja, setSelectedCaja] = useState(null);
  const [selectMaterial, setSelectMaterial] = useState('');
  const lenEmpty = Array?.isArray(record?.len) && record?.len?.length === 0;
  const filterAble = record && record?.pendienteSalida === true && record?.anulada === false;

  const { data: clientes } = useGetList('clientes', { pagination: { page: 1, perPage: 500 }, sort: { field: 'nit_cedula', order: 'DESC'}});
  const { data: cajas } = useGetList('stocks', { meta: { searchable: 'true' }, sort: { field: 'consecutivo', order: 'DESC' }, filter: { materialID: { eq: referencia }, activa: { eq: true }}});

  const updateExposicion = async (values) => {
    try {
      if ((values?.responsablePin == null || values?.responsableId == null || values?.responsableName == null) && values?.cajaId == null) {
        notify('Error: Los valores críticos no pueden estar vacíos o sin definir.', { type: 'error' });
        return;
      };

      let dataUpdate = {
        id: record?.id,
        responsablePin: values?.responsablePin || record?.responsablePin,
        responsableId: values?.responsableId || record?.responsableId,
        responsableName: values?.responsableName || record?.responsableName,
        cajaId: values?.cajaId || record?.cajaId,
        taras: values?.taras || record?.taras,
        pendienteSalida: false,
      };
  
      if (selectMaterial === 'laminaCompleta') {
        const areaLamina = parseFloat(record?.areaTotalLen) || 0;
        const anchoLamina = parseFloat(selectedCaja?.anchoLamina) || 0;
        const largoLamina = parseFloat(selectedCaja?.largoLamina) || 0;
        const areaCaja = anchoLamina * largoLamina || 0;
        const desperdicioLamina = areaCaja > 0 ? (areaCaja - areaLamina) : 0;
        const perDesperdicioLamina = areaCaja > 0 ? (desperdicioLamina / areaCaja) * 100 : 0;
  
        dataUpdate = {
          ...dataUpdate,
          anchoLamina: parseFloat(anchoLamina) || record?.anchoLamina || 0,
          largoLamina: parseFloat(largoLamina) || record?.largoLamina || 0,
          desperdicio: parseFloat(desperdicioLamina) || 0,
          desperdicioPerc: parseFloat(perDesperdicioLamina) || 0,
        };
      } else if (selectMaterial === 'retal') {
        const area = values?.anchoRetal * values?.largoRetal || 0;
        const desperdicio = area > 0 ? area - (record?.areaTotalLen || 0) : 0;
        const desperdicioPerc = area > 0 ? (desperdicio / area) * 100 : 0;
  
        dataUpdate = {
          ...dataUpdate,
          anchoRetal: parseFloat(values?.anchoRetal) || record?.anchoRetal || 0,
          largoRetal: parseFloat(values?.largoRetal) || record?.largoRetal || 0,
          desperdicio: parseFloat(desperdicio) || 0,
          desperdicioPerc: parseFloat(desperdicioPerc) || 0,
        };
      }

      const exposicionUpdateResult = await dataProvider.update('exposicions', {
        id: record?.id,
        data: dataUpdate,
        previousData: record,
      });
  
      notify('Exposición añadida correctamente', 'info');
      console.log('Antes de actualizar la Exposición: ', record);
      console.log('Después de actualizar la Exposición: ', values);
      return exposicionUpdateResult;
  
    } catch (error) {
      notify('Error al actualizar las Exposiciones:', { type: 'error' });
      throw error;
    }
  };
  
  const procesarOrden = async (caja, cajaManual, values) => {
    try {
      const calibre = record?.calibre;
      const lenData = record?.len;
      const cajaOrden = parseInt(caja, 10) || parseInt(cajaManual);

      let opArray = [];
      for (let i = 0; i < lenData?.length; i++) {
        const op = parseInt(lenData[i]?.op, 10);
        const totalPlanchasExp = 1;
        const opIndex = opArray.findIndex((item) => item.op === op);

        if (opIndex === -1) {
          opArray.push({ op, calibre: parseInt(calibre, 10), totalPlanchasExp, ordenMaterial: [], infoRetal: [] });
        } else {
          opArray[opIndex].totalPlanchasExp += 1;
        };
  
        const uuid = record?.id?.slice(0, 8)+'-'+Math?.floor(Math?.random()*1000);
        const opAncho = parseFloat(lenData[i]?.lenWidth)/10;
        const opLargo = parseFloat(lenData[i]?.lenHeight)/10;
        const area = parseFloat((lenData[i]?.area)*100)/10;
        const cantidad = 1;
        const total = parseInt(opAncho * opLargo * cantidad);

        opArray[opIndex === -1 ? opArray?.length - 1 : opIndex]?.ordenMaterial?.push({
          uuid,
          opAncho,
          opLargo,
          cantidad,
          caja: cajaOrden,
          area,
          total,
        });
      };

      opArray?.forEach(opItem => {
        let infoRetal = {
          uuid: record?.id?.slice(25, 33)+'-'+Math?.floor(Math?.random()*1000),
          typeMaterial: selectMaterial === 'retal' ? 'Retal' : selectMaterial === 'laminaCompleta' ? 'Lamina Completa' : '',
        };
      
        if (selectMaterial === 'laminaCompleta') {
          const areaLamina = record?.areaTotalLen || 0;
          const anchoLamina = selectedCaja?.anchoLamina || 0;
          const largoLamina = selectedCaja?.largoLamina || 0;
          const areaCaja = anchoLamina * largoLamina || 0;
          const desperdicioLamina = areaCaja > 0 ? (areaCaja - areaLamina) : 0;
          const perDesperdicioLamina = areaCaja > 0 ? (desperdicioLamina / areaCaja) * 100 : 0;
      
          infoRetal = {
            ...infoRetal,
            anchoLamina: anchoLamina || record?.anchoLamina || 0,
            largoLamina: largoLamina || record?.largoLamina || 0,
            desperdicio: parseFloat(desperdicioLamina) || 0,
            porcentajeDesp: parseFloat(perDesperdicioLamina) || 0,
          };
        } else if (selectMaterial === 'retal') {
          const areaRetal = values?.anchoRetal * values?.largoRetal || 0;
          const desperdicioRetal = areaRetal > 0 ? areaRetal - (record?.areaTotalLen || 0) : 0;
          const perDesperdicioRetal = areaRetal > 0 ? (desperdicioRetal / areaRetal) * 100 : 0;
      
          infoRetal = {
            ...infoRetal,
            anchoRetal: values?.anchoRetal || record?.anchoRetal || 0,
            largoRetal: values?.largoRetal || record?.largoRetal || 0,
            desperdicio: parseFloat(desperdicioRetal) || 0,
            porcentajeDesp: parseFloat(perDesperdicioRetal) || 0,
          };
        }
        opItem.infoRetal.push(infoRetal);
      });
  
      opArray?.forEach(op => {
        const consolidatedOrdenMaterial = op.ordenMaterial?.reduce((acc, item) => {
          const existingItem = acc?.find(
            accItem => accItem?.opAncho === item?.opAncho && accItem?.opLargo === item?.opLargo && accItem?.caja === item?.caja
          );
          if (existingItem) {
            existingItem.cantidad += item.cantidad;
            existingItem.total = parseInt(existingItem?.opAncho * existingItem?.opLargo * existingItem?.cantidad);
          } else {
            acc?.push(item);
          }
          return acc;
        }, []);
  
        op.ordenMaterial = consolidatedOrdenMaterial;
      });
  
      const ordenSalidaResults = await Promise.all(
        opArray?.map(async (op) => {
          return await ordenSalidaExecute(op?.op, op?.calibre, op?.totalPlanchasExp, op?.ordenMaterial, op?.infoRetal);
        })
      );
  
      return ordenSalidaResults;
    } catch (error) {
      console.error('Error al procesar la orden de salida:', error);
      throw error; 
    }
  };

  const ordenSalidaExecute = async (op, calibre, totalPlanchasExp, newOrdenMaterial, infoMaterial) => {
    try {
      const params = {
        filter: { 'NUMERO': op },
        pagination: { page: 1, perPage: 10 },
        sort: { 'field': 'VERSIONOP', 'order': 'DESC' },
      };

      const dataSqlOrdenes = await dataProvider.getList('sqlOrdenes', params);

      const odc = dataSqlOrdenes.data[0]?.ORDENCPR;
      const cliente = dataSqlOrdenes.data[0]?.CLIENTE;
      const ciudadOp = dataSqlOrdenes.data[0]?.CIUDAD;
      const versionOp = dataSqlOrdenes.data[0]?.VERSIONOP;
      const disenador = dataSqlOrdenes.data[0]?.DISENHAFAC;
      const nombreTrabajo = dataSqlOrdenes.data[0]?.TRABAJO;
      const disenadorId = dataSqlOrdenes.data[0]?.DISENHADOR;
      const planchasColor = dataSqlOrdenes.data[0]?.PLANCOLOR;
      const calibreOrden = dataSqlOrdenes.data[0]?.COORDCALIBRE;
      const totalPlanchaOrden = dataSqlOrdenes.data[0]?.TOTALPLAN;
      const clienteId = dataSqlOrdenes.data[0]?.CODCLIENTE?.trim();
      
      const len = dataSqlOrdenes.data[0]?.LEN;
      const lens = dataSqlOrdenes.data[0]?.LENS;
      const arte = dataSqlOrdenes.data[0]?.ARTE;
      const prueba = dataSqlOrdenes.data[0]?.PRUEBA;
      const copiado = dataSqlOrdenes.data[0]?.COPIADO;
      const repeticion = dataSqlOrdenes.data[0].REPITE;
      const difiarte = dataSqlOrdenes?.data[0]?.DIFIARTE;
      const openPrint = dataSqlOrdenes.data[0]?.OPENPRINT;
      const reposicion = dataSqlOrdenes.data[0]?.REPOSICION;
      const finalizacion = dataSqlOrdenes.data[0]?.FINALIZA;
      const fotopolimero = dataSqlOrdenes.data[0]?.FOTOPOLIM;
      const trescolores = dataSqlOrdenes.data[0]?.TRESCOLORES;

      let tipoTrabajoArr = [];
      if (finalizacion === 1) {
        if (trescolores === 1) {
          tipoTrabajoArr.push('FINALIZACION_BAJA');
        } else {
          tipoTrabajoArr.push('FINALIZACION_ALTA');
        };
      } else if (openPrint === 1) {
        tipoTrabajoArr.push('OPENPRINT');
      } else if (copiado === 1) {
        tipoTrabajoArr.push('COPIADO');
      } else if (repeticion === 1) {
        tipoTrabajoArr.push('REPETICION');
      } else if (len === 1) {
        tipoTrabajoArr.push('LEN');
      };

      let productos = [];
      if (fotopolimero === 1) {
        productos.push('FOTOPOLIMERO');
      };
      if (lens === 1) {
        productos.push('LENS');
      };
      if (prueba === 1) {
        productos.push('PRUEBA');
      };
      if (arte === 1) {
        if (difiarte === 3) {
          productos.push('ARTE_BAJO');
        } else if (difiarte === 2) {
          productos.push('ARTE_MEDIO');
        } else if (difiarte === 1) {
          productos.push('ARTE_ALTO');
        }
      };
      if (reposicion === 1) {
        productos.push('REPOSICION');
      };

      const tipoTrabajo = tipoTrabajoArr?.length > 0 ? tipoTrabajoArr?.join('/') : 'Sin tipo de trabajo';
      const tipoProducto = productos?.length > 0 ? productos?.join('/') : 'Sin tipo de producto';
      const tipoJob = hasRepoExpo ? `${tipoTrabajo}/${'REPOSICION'}` : `${tipoTrabajo}/${tipoProducto}`;

      console.log('tipoJob', tipoJob);

      let cityJob = '';
      if (ciudadOp === 'Barr') {  
        cityJob = 'BARRANQUILLA';
      } else if (ciudadOp === 'Mede') {
        cityJob = 'ENVIGADO';
      } else if (ciudadOp === 'Cali') {
        cityJob = 'CALI';
      } else {
        cityJob = 'No hay ciudad';
      }
      const ciudadOrden = cityJob;
      const calibreValueMap = { 
        1: 45, 
        2: 67, 
        3: 100, 
        4: 112, 
        5: 155, 
        6: 31, 
        7: 38 
      };
  
      let calibreValue;
      if (calibreValueMap?.hasOwnProperty(calibreOrden)) {
        calibreValue = calibreValueMap[calibreOrden];
      } else {
        calibreValue = 0;
      };
  
      const result = await client?.graphql({
        variables: {
          op: op,
        },
        query: listOrdenByOp, 
      });
  
      const calibreValueOp = calibre;
      const existingOrdenSalida = result?.data?.listOrdenByOp?.items[0];
      
      let expIdCreate = [record.id];
      let expIDUpdate = [...expIdCreate];

      let sobremedida;

      if (cliente === 'FLEXICREATIVOS' || cliente === 'DITAR S.A.') {
        const sobremedidaClient = [];
        clientes?.forEach(client => {
          const nit = client?.nit_cedula;
          const razonSocial = client?.razonSocial;
          const sobremedidaValor = client?.datosFacturacion?.sobremedida;
          if (sobremedidaValor !== undefined && sobremedidaValor !== null) {
            sobremedidaClient.push({ razonSocial, sobremedida: sobremedidaValor, nit });
          };
        });
      
        const filterDifferentSm = sobremedidaClient?.filter(
          sm => sm?.razonSocial === 'FLEXICREATIVOS' || sm?.razonSocial === 'DITAR S.A.'
        );
      
        if (filterDifferentSm?.length > 0) {
          sobremedida = filterDifferentSm[0]?.sobremedida;
          console.log('sobremedida 1', sobremedida);
        };
      } else {
        sobremedida = 2;
      };
      
      sobremedida = Number(sobremedida) || 0;
      
      const roundHalf = (value, step = 1.0) => {
        const inv = 1.0 / step;
        return Math.round(value * inv) / inv;
      };
      
      const calcularSobreMedida = (medida, sobremedida) => {
        return roundHalf(medida + sobremedida, 0.5);
      };
      
      newOrdenMaterial?.forEach(item => {
        const opAncho = Number(item.opAncho) || 0;
        const opLargo = Number(item.opLargo) || 0;
        item.opAncho = calcularSobreMedida(opAncho, sobremedida);
        item.opLargo = calcularSobreMedida(opLargo, sobremedida);
      });
            
      if (existingOrdenSalida) {
        if (existingOrdenSalida?.totalPlanchasExp === existingOrdenSalida?.totalPlanchasOrden) {
          console.log(`La orden ${op} ya está completa.`);
        };
        if (existingOrdenSalida?.totalPlanchasExp + totalPlanchasExp > existingOrdenSalida?.totalPlanchasOrden) {
          console.log(`La ${op} supera el total planchas expuestas para la orden.`);
          notify(`La ${op} supera el total planchas expuestas para la orden`, { type: 'warning' });
        };
        if (existingOrdenSalida?.calibreOrden !== calibreValueOp) {
          console.log(`La orden ${op} no se puede completar porque el calibre de la orden no coincide con el calibre de la exposición.`);
          notify(`La orden ${op} no se puede completar porque el calibre de la orden no coincide con el calibre de la exposición.`, { type: 'error' });
          throw new Error('El calibre de la orden debe ser igual al calibre de la exposición');
        };
      
        const updatedInfoRetal = existingOrdenSalida?.infoRetal ? [...existingOrdenSalida.infoRetal] : [];
        updatedInfoRetal.push(...infoMaterial);
        
        const updatedOrdenMaterial = [...existingOrdenSalida.ordenMaterial];
      
        newOrdenMaterial?.forEach(newItem => {
          const existingItemIndex = updatedOrdenMaterial?.findIndex(item =>
            item.caja === newItem.caja && item.opAncho === newItem.opAncho && item.opLargo === newItem.opLargo
          );
          if (existingItemIndex === -1) {
            updatedOrdenMaterial.push(newItem);
          } else {
            updatedOrdenMaterial[existingItemIndex].cantidad += newItem.cantidad;
            updatedOrdenMaterial[existingItemIndex].total = parseInt(
              updatedOrdenMaterial[existingItemIndex]?.opAncho * updatedOrdenMaterial[existingItemIndex]?.opLargo * updatedOrdenMaterial[existingItemIndex]?.cantidad
            );
          };
        });
      
        const updatedExpID = [...new Set([...existingOrdenSalida.expID, ...expIDUpdate])];
        const updatedOrden = await dataProvider.update('ordenSalidas', {
          id: existingOrdenSalida?.id,
          data: {
            id: existingOrdenSalida?.id,
            totalPlanchasExp: existingOrdenSalida?.totalPlanchasExp + totalPlanchasExp,
            version: existingOrdenSalida?.version,
            totalPlanchasOrden: totalPlanchaOrden,
            calibreOrden: calibreValue,
            calibresExp: existingOrdenSalida?.calibresExp,
            ordenMaterial: updatedOrdenMaterial,
            reposicion: hasRepoExpo ? true : false,
            expID: updatedExpID,
            infoRetal: updatedInfoRetal,
          },
        });
        return updatedOrden;
      } else {
        const newOrden = await dataProvider.create('ordenSalidas', {
          data: {
            op: op,
            version: versionOp,
            clienteOrdenId: clienteId,
            clienteOrden: cliente,
            nameJob: nombreTrabajo,
            ciudadOrden: ciudadOrden,
            tipoJob: tipoJob,
            vendedorId: disenadorId,
            vendedor: disenador,
            salidaProd: false,
            ordenMaterial: newOrdenMaterial,
            totalPlanchasOrden: totalPlanchaOrden,
            calibreOrden: calibreValue,
            totalPlanchasExp: totalPlanchasExp,
            calibresExp: calibreValueOp,
            refMaterial: referencia,
            refPlanchas: nombrePlancha,
            qtyLen: len,
            reposicion: hasRepoExpo ? true : false,
            planchasXColor: planchasColor,
            ordenCompra: odc,
            expID: expIdCreate,
            infoRetal: infoMaterial,
          }
        });
        return newOrden;
      }
    } catch (error) {
      console.error('Error al crear o actualizar la Orden Producción:', error.message);
      notify(`Error ${error.message} al crear o actualizar la Orden Producción.`, { type: 'error' });
      throw error;
    };
  };
  
  const handleSubmit = async (values) => {
    try {
      const { taras, cajaId, responsablePin, anchoRetal, largoRetal, responsableId, largoLamina, anchoLamina } = values || {};
      
      if (!responsableId) {
        notify('No se encontró un responsable.', { type: 'warning'});
        return;
      };

      if (!taras) {
        notify('Se requiere digitar el número de tara para continuar.', { type: 'warning'});
        return;
      };

      if (!cajaId && !cajaManual) {
        notify('Se requiere seleccionar o digitar una caja para continuar.', { type: 'warning'});
        return;
      };

      if (!selectMaterial) {
        notify('Se requiere seleccionar el material para continuar.', { type: 'warning'});
        return;
      };

      if (selectMaterial === 'laminaCompleta') {
        if (!largoLamina) {
          notify('Valor del largo de la lámina es erróneo.', { type: 'warning' });
          return;
        }
        if (!anchoLamina) {
          notify('Valor del ancho de la lámina es erróneo.', { type: 'warning' });
          return;
        }
      } else if (selectMaterial === 'retal') {
        if (!anchoRetal) {
          notify('Se requiere digitar el valor del ancho del retal.', { type: 'warning' });
          return;
        }
        if (!largoRetal) {
          notify('Se requiere digitar el valor del largo del retal.', { type: 'warning' });
          return;
        }
      };

      if (record?.desperdicio === undefined || record?.desperdicioPerc === undefined) {
        notify('Los valores calculados del desperdicio son inválidos', { type: 'warning' });
        return;
      };

      const pinExists = await validarExistenciaPin(responsablePin, client, notify);
      if (!pinExists) {
        notify('El pin del responsable no se ha encontrado.', { type: 'error' });
        return;
      };
      
      const cajaValue = cajaId || cajaManual;
      const cajaRegex = cajaValue?.match(/-(\d+)$/);
      const caja = cajaRegex ? cajaRegex[1] : null;
    
      if (!cajaValue) {
        notify('No se pudo extraer datos de la caja.', { type: 'error' });
        return;
      };
    
      const cajaEncontrada = cajas?.find(caja => caja?.id === cajaValue);
      if (!cajaEncontrada) {
        notify('No se encontró una caja similar a la seleccionada.', { type: 'error' });
        return;
      };
    
      const ordenSalidaResults = await procesarOrden(caja, cajaManual, values);
      console.log('ordenSalidaResults', ordenSalidaResults);
    
      if (ordenSalidaResults) {
        await updateExposicion(values);
        notify('Medidas añadidas correctamente', { type: 'success' });
        redirect('list', 'exposicions');
        refresh();
      } else {
        refresh();
        notify('Error al procesar la orden de salida.', { type: 'error' });
        throw new Error('Error al procesar la orden de salida.');
      };
    } catch (error) {
      console.error('No se pudieron añadir las medidas debido a un error, verifique los datos.', error);
      notify(`No se pudieron añadir las medidas debido a un error: ${error.message}. Verifique la Exposición.`, { type: 'error' });
      redirect('list', 'exposicions');
    };
  };

  return (
    filterAble && expo ? ( 
      <>
        <SimpleForm toolbar={<SimpleFormActions expo={expo}/>} resource='exposicions' onSubmit={handleSubmit}>
          <Grid container flexDirection='row' justifyContent='space-evenly'>
            <Grid item xs={6} sm={4} md={3.5} lg={2.5} xl={2.5} sx={DialogStylized?.dialog?.gridImageView}>
              <ImageCDI source='imageExp' label=''/>
            </Grid>
            <Divider orientation='vertical' flexItem variant='middle'/>
            <Grid item xs={6} sm={4} md={4.5} lg={4.5} xl={4.5} sx={DialogStylized?.dialog?.gridInfoExpo}>
              <ShowInfoExposicion/>
            </Grid>
            {lenEmpty ? (
              <span/>
            ):(
              <>
                <Divider orientation='vertical' flexItem variant='middle'/>
                <Grid mt={selectedCaja ? '2em' : { lg:' 2.1em', xl: '3em' }} item xs={8} sm={10} md={3} lg={3} xl={3}>
                  <InputsMedidas onCajaManual={(value)=>setCajaManual(value)} onCajaChange={(caja)=>setSelectedCaja(caja)} onMaterial={(value)=>setSelectMaterial(value)}/>
                </Grid>
              </>
            )}
            <Grid mt='0.5em' item xs={12} sm={12} md={12} lg={12} xl={12}>
              <LenContent/>
            </Grid>
          </Grid>
        </SimpleForm>
      </>
    ):(
      <span/>
    )
  );
};

const SimpleFormActions = ({ ...props }) => {

  const record = useRecordContext();
  const pendienteSalida = record?.pendienteSalida;
  const disabledButton = pendienteSalida === false;
  const lenEmpty = Array?.isArray(record?.len) && record?.len?.length === 0;

  return (
    <Toolbar {...props}>
      {lenEmpty ? (
        <div style={{ marginLeft: 'auto' }}>
          <AnulacionSolicitud expo={record}/>
        </div>
      ):(
        <SaveButton size='small' label='Agregar Medidas' disabled={disabledButton} icon={<SendAndArchiveIcon/>} sx={{ marginLeft: 'auto' }}/>
      )}
    </Toolbar>
  );
};