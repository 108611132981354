/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContactoCliente = /* GraphQL */ `
  query GetContactoCliente($id: ID!) {
    getContactoCliente(id: $id) {
      id
      nombre
      email
      cargo
      enviarFactura
      rol
      cel
      clienteID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listContactoClientes = /* GraphQL */ `
  query ListContactoClientes(
    $filter: ModelContactoClienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactoClientes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nombre
        email
        cargo
        enviarFactura
        rol
        cel
        clienteID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contactoByClienteId = /* GraphQL */ `
  query ContactoByClienteId(
    $clienteID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactoClienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactoByClienteId(
      clienteID: $clienteID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nombre
        email
        cargo
        enviarFactura
        rol
        cel
        clienteID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsuario = /* GraphQL */ `
  query GetUsuario($id: ID!) {
    getUsuario(id: $id) {
      id
      cedula
      nombres
      apellidos
      nombreCompleto
      email
      roles
      cognitoUserId
      devices
      sedes
      huellas {
        items {
          id
          userID
          userName
          finger
          device
          fingerDeviceID
          registered_at
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      pin
      alegraVendedorId
      fingerDevices
      createdAt
      updatedAt
    }
  }
`;
export const listUsuarios = /* GraphQL */ `
  query ListUsuarios(
    $filter: ModelUsuarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsuarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cedula
        nombres
        apellidos
        nombreCompleto
        email
        roles
        cognitoUserId
        devices
        sedes
        huellas {
          items {
            id
            userID
            userName
            finger
            device
            fingerDeviceID
            registered_at
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pin
        alegraVendedorId
        fingerDevices
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserExposicionByPin = /* GraphQL */ `
  query GetUserExposicionByPin(
    $pin: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUsuarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserExposicionByPin(
      pin: $pin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cedula
        nombres
        apellidos
        nombreCompleto
        email
        roles
        cognitoUserId
        devices
        sedes
        huellas {
          items {
            id
            userID
            userName
            finger
            device
            fingerDeviceID
            registered_at
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pin
        alegraVendedorId
        fingerDevices
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUsuarios = /* GraphQL */ `
  query SearchUsuarios(
    $filter: SearchableUsuarioFilterInput
    $sort: [SearchableUsuarioSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUsuarioAggregationInput]
  ) {
    searchUsuarios(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        cedula
        nombres
        apellidos
        nombreCompleto
        email
        roles
        cognitoUserId
        devices
        sedes
        huellas {
          items {
            id
            userID
            userName
            finger
            device
            fingerDeviceID
            registered_at
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pin
        alegraVendedorId
        fingerDevices
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getHuella = /* GraphQL */ `
  query GetHuella($id: ID!) {
    getHuella(id: $id) {
      id
      userID
      userName
      finger
      device
      fingerDeviceID
      registered_at
      status
      createdAt
      updatedAt
    }
  }
`;
export const listHuellas = /* GraphQL */ `
  query ListHuellas(
    $filter: ModelHuellaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHuellas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        userName
        finger
        device
        fingerDeviceID
        registered_at
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listHuellasByUserId = /* GraphQL */ `
  query ListHuellasByUserId(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHuellaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHuellasByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        userName
        finger
        device
        fingerDeviceID
        registered_at
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCliente = /* GraphQL */ `
  query GetCliente($id: ID!) {
    getCliente(id: $id) {
      id
      alegraId
      nit_cedula
      codigo
      ciudad
      sector
      marcas {
        items {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      digitoVerificacion
      estado
      daysToPay
      daysAvgToPay
      maquinas {
        nombre
        tipoDesarrollo
        tipoMaquina
        paso
        anchoBanda
        velocidad
        plancha
        lpis
      }
      razonSocial
      departamento
      pais
      cotiza
      sucursalEncargada
      direccion
      primerNombre
      segundoNombre
      primerApellido
      segundoApellido
      tipoDocumento
      datosFacturacion {
        formaPago
        iva
        variacionTRM
        validarCxC
        retefuenteType
        retefuentePorc
        reteIvaBool
        reteIcaPorc
        reteIcaBool
        exportacion
        precioOrden {
          tipoOrden
          unidades
          valor
        }
        regimenAdq
        sobremedida
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        retencionesIDs
        tipoPersonaAdq
        emailFE
      }
      productos {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          clienteID
          nombre
          marca {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          marcaID
          ordenes {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          productoNuevo
          productoExistente
          productoRefID
          mainFile {
            bucket
            region
            key
          }
          mainFileThumbnail {
            bucket
            region
            key
          }
          auxFiles {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          marcaProductosId
        }
        nextToken
      }
      ordenes {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          odc
          clienteID
          prioridad
          tipoOrden
          cooimpreso
          calibre
          ResponsableActualID
          estado
          maquina
          montaje {
            pistas
            repeticiones
            rodillo
            radio
            paso
            gapPistas
            gapRepeticiones
            anchoBobina
            desarrollo
            truncado
            enfrentado
            cantidadTruncado
            prodAncho
            prodAlto
            tipoCorte
            posicionSalida
            embobinado
            email
          }
          numeroOPant
          versionOPant
          dueTime
          productoID
          producto {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          nombreAnt
          rip {
            calibre
            elongacion
            lpi
            emulsion
            colores {
              nombre
              libro
              angulo
              index
              punto
              lpi
              bumpUp
              microCell
            }
            marcas
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      indicativoPais
      indicativoCiudad
      telefono1
      telefono2
      observaciones
      createdAt
      updatedAt
    }
  }
`;
export const listClientes = /* GraphQL */ `
  query ListClientes(
    $filter: ModelClienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const clientesByRazonSocial = /* GraphQL */ `
  query ClientesByRazonSocial(
    $razonSocial: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientesByRazonSocial(
      razonSocial: $razonSocial
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchClientes = /* GraphQL */ `
  query SearchClientes(
    $filter: SearchableClienteFilterInput
    $sort: [SearchableClienteSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableClienteAggregationInput]
  ) {
    searchClientes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getMarca = /* GraphQL */ `
  query GetMarca($id: ID!) {
    getMarca(id: $id) {
      id
      nombre
      productor
      clienteID
      productos {
        items {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          clienteID
          nombre
          marca {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          marcaID
          ordenes {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          productoNuevo
          productoExistente
          productoRefID
          mainFile {
            bucket
            region
            key
          }
          mainFileThumbnail {
            bucket
            region
            key
          }
          auxFiles {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          marcaProductosId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMarcas = /* GraphQL */ `
  query ListMarcas(
    $filter: ModelMarcaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarcas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
        productor
        clienteID
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const marcasByCliente = /* GraphQL */ `
  query MarcasByCliente(
    $clienteID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMarcaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    marcasByCliente(
      clienteID: $clienteID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nombre
        productor
        clienteID
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProducto = /* GraphQL */ `
  query GetProducto($id: ID!) {
    getProducto(id: $id) {
      id
      cliente {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      clienteID
      nombre
      marca {
        id
        nombre
        productor
        clienteID
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      marcaID
      ordenes {
        items {
          id
          productoID
          ordenID
          producto {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          orden {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productoNuevo
      productoExistente
      productoRefID
      mainFile {
        bucket
        region
        key
      }
      mainFileThumbnail {
        bucket
        region
        key
      }
      auxFiles {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      marcaProductosId
    }
  }
`;
export const listProductos = /* GraphQL */ `
  query ListProductos(
    $filter: ModelProductoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        clienteID
        nombre
        marca {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        marcaID
        ordenes {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        productoNuevo
        productoExistente
        productoRefID
        mainFile {
          bucket
          region
          key
        }
        mainFileThumbnail {
          bucket
          region
          key
        }
        auxFiles {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        marcaProductosId
      }
      nextToken
    }
  }
`;
export const productosByCliente = /* GraphQL */ `
  query ProductosByCliente(
    $clienteID: ID!
    $nombre: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productosByCliente(
      clienteID: $clienteID
      nombre: $nombre
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        clienteID
        nombre
        marca {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        marcaID
        ordenes {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        productoNuevo
        productoExistente
        productoRefID
        mainFile {
          bucket
          region
          key
        }
        mainFileThumbnail {
          bucket
          region
          key
        }
        auxFiles {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        marcaProductosId
      }
      nextToken
    }
  }
`;
export const getOrden = /* GraphQL */ `
  query GetOrden($id: ID!) {
    getOrden(id: $id) {
      id
      cliente {
        id
        alegraId
        nit_cedula
        codigo
        ciudad
        sector
        marcas {
          items {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        digitoVerificacion
        estado
        daysToPay
        daysAvgToPay
        maquinas {
          nombre
          tipoDesarrollo
          tipoMaquina
          paso
          anchoBanda
          velocidad
          plancha
          lpis
        }
        razonSocial
        departamento
        pais
        cotiza
        sucursalEncargada
        direccion
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        datosFacturacion {
          formaPago
          iva
          variacionTRM
          validarCxC
          retefuenteType
          retefuentePorc
          reteIvaBool
          reteIcaPorc
          reteIcaBool
          exportacion
          precioOrden {
            tipoOrden
            unidades
            valor
          }
          regimenAdq
          sobremedida
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          retencionesIDs
          tipoPersonaAdq
          emailFE
        }
        productos {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          nextToken
        }
        ordenes {
          items {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        indicativoPais
        indicativoCiudad
        telefono1
        telefono2
        observaciones
        createdAt
        updatedAt
      }
      odc
      clienteID
      prioridad
      tipoOrden
      cooimpreso
      calibre
      ResponsableActualID
      estado
      maquina
      montaje {
        pistas
        repeticiones
        rodillo
        radio
        paso
        gapPistas
        gapRepeticiones
        anchoBobina
        desarrollo
        truncado
        enfrentado
        cantidadTruncado
        prodAncho
        prodAlto
        tipoCorte
        posicionSalida
        embobinado
        email
      }
      numeroOPant
      versionOPant
      dueTime
      productoID
      producto {
        items {
          id
          productoID
          ordenID
          producto {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            clienteID
            nombre
            marca {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            marcaID
            ordenes {
              nextToken
            }
            productoNuevo
            productoExistente
            productoRefID
            mainFile {
              bucket
              region
              key
            }
            mainFileThumbnail {
              bucket
              region
              key
            }
            auxFiles {
              bucket
              region
              key
            }
            createdAt
            updatedAt
            marcaProductosId
          }
          orden {
            id
            cliente {
              id
              alegraId
              nit_cedula
              codigo
              ciudad
              sector
              digitoVerificacion
              estado
              daysToPay
              daysAvgToPay
              razonSocial
              departamento
              pais
              cotiza
              sucursalEncargada
              direccion
              primerNombre
              segundoNombre
              primerApellido
              segundoApellido
              tipoDocumento
              indicativoPais
              indicativoCiudad
              telefono1
              telefono2
              observaciones
              createdAt
              updatedAt
            }
            odc
            clienteID
            prioridad
            tipoOrden
            cooimpreso
            calibre
            ResponsableActualID
            estado
            maquina
            montaje {
              pistas
              repeticiones
              rodillo
              radio
              paso
              gapPistas
              gapRepeticiones
              anchoBobina
              desarrollo
              truncado
              enfrentado
              cantidadTruncado
              prodAncho
              prodAlto
              tipoCorte
              posicionSalida
              embobinado
              email
            }
            numeroOPant
            versionOPant
            dueTime
            productoID
            producto {
              nextToken
            }
            nombreAnt
            rip {
              calibre
              elongacion
              lpi
              emulsion
              marcas
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      nombreAnt
      rip {
        calibre
        elongacion
        lpi
        emulsion
        colores {
          nombre
          libro
          angulo
          index
          punto
          lpi
          bumpUp
          microCell
        }
        marcas
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrdens = /* GraphQL */ `
  query ListOrdens(
    $filter: ModelOrdenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        odc
        clienteID
        prioridad
        tipoOrden
        cooimpreso
        calibre
        ResponsableActualID
        estado
        maquina
        montaje {
          pistas
          repeticiones
          rodillo
          radio
          paso
          gapPistas
          gapRepeticiones
          anchoBobina
          desarrollo
          truncado
          enfrentado
          cantidadTruncado
          prodAncho
          prodAlto
          tipoCorte
          posicionSalida
          embobinado
          email
        }
        numeroOPant
        versionOPant
        dueTime
        productoID
        producto {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        nombreAnt
        rip {
          calibre
          elongacion
          lpi
          emulsion
          colores {
            nombre
            libro
            angulo
            index
            punto
            lpi
            bumpUp
            microCell
          }
          marcas
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordenesByCliente = /* GraphQL */ `
  query OrdenesByCliente(
    $clienteID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrdenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordenesByCliente(
      clienteID: $clienteID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        odc
        clienteID
        prioridad
        tipoOrden
        cooimpreso
        calibre
        ResponsableActualID
        estado
        maquina
        montaje {
          pistas
          repeticiones
          rodillo
          radio
          paso
          gapPistas
          gapRepeticiones
          anchoBobina
          desarrollo
          truncado
          enfrentado
          cantidadTruncado
          prodAncho
          prodAlto
          tipoCorte
          posicionSalida
          embobinado
          email
        }
        numeroOPant
        versionOPant
        dueTime
        productoID
        producto {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        nombreAnt
        rip {
          calibre
          elongacion
          lpi
          emulsion
          colores {
            nombre
            libro
            angulo
            index
            punto
            lpi
            bumpUp
            microCell
          }
          marcas
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRemission = /* GraphQL */ `
  query GetRemission($id: ID!) {
    getRemission(id: $id) {
      id
      alegraId
      alegraNumeracion
      formaPago
      vendedorID
      facturaID
      factura {
        id
        remisionesIDs
        remisiones {
          items {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        alegraId
        formaPago
        emails
        alegraNumeracion
        ciudadId
        vendedorID
        vendedorAlegraId
        clienteId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        applyIva
        clienteName
        observaciones
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        totalSinRetenciones
        stamp {
          barCodeContent
          cufe
          date
          legalStatus
          warnings
        }
        sharedID
        creditNoteID
        kindOfDuplicate
        previousInvoiceID
        createdAt
        updatedAt
      }
      emails
      ciudadId
      vendedorAlegraId
      estado
      fechaCrea
      fechaVence
      fechaEdita
      clienteId
      applyIva
      clienteName
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      valorPendiente
      observaciones
      usuarioID
      usuarioEdita
      pdf
      pdfAlt
      url
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            remisiones {
              nextToken
            }
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            stamp {
              barCodeContent
              cufe
              date
              legalStatus
              warnings
            }
            sharedID
            creditNoteID
            kindOfDuplicate
            previousInvoiceID
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRemissions = /* GraphQL */ `
  query ListRemissions(
    $filter: ModelRemissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRemissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        alegraId
        alegraNumeracion
        formaPago
        vendedorID
        facturaID
        factura {
          id
          remisionesIDs
          remisiones {
            items {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            nextToken
          }
          alegraId
          formaPago
          emails
          alegraNumeracion
          ciudadId
          vendedorID
          vendedorAlegraId
          clienteId
          estado
          fechaCrea
          fechaVence
          fechaEdita
          applyIva
          clienteName
          observaciones
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          valorPendiente
          usuarioID
          usuarioEdita
          pdf
          pdfAlt
          url
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          totalSinRetenciones
          stamp {
            barCodeContent
            cufe
            date
            legalStatus
            warnings
          }
          sharedID
          creditNoteID
          kindOfDuplicate
          previousInvoiceID
          createdAt
          updatedAt
        }
        emails
        ciudadId
        vendedorAlegraId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        observaciones
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchRemissions = /* GraphQL */ `
  query SearchRemissions(
    $filter: SearchableRemissionFilterInput
    $sort: [SearchableRemissionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRemissionAggregationInput]
  ) {
    searchRemissions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        alegraId
        alegraNumeracion
        formaPago
        vendedorID
        facturaID
        factura {
          id
          remisionesIDs
          remisiones {
            items {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            nextToken
          }
          alegraId
          formaPago
          emails
          alegraNumeracion
          ciudadId
          vendedorID
          vendedorAlegraId
          clienteId
          estado
          fechaCrea
          fechaVence
          fechaEdita
          applyIva
          clienteName
          observaciones
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          valorPendiente
          usuarioID
          usuarioEdita
          pdf
          pdfAlt
          url
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          totalSinRetenciones
          stamp {
            barCodeContent
            cufe
            date
            legalStatus
            warnings
          }
          sharedID
          creditNoteID
          kindOfDuplicate
          previousInvoiceID
          createdAt
          updatedAt
        }
        emails
        ciudadId
        vendedorAlegraId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        observaciones
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCotizacion = /* GraphQL */ `
  query GetCotizacion($id: ID!) {
    getCotizacion(id: $id) {
      id
      alegraId
      emails
      consecutivo
      vendedorID
      ciudadId
      vendedorAlegraId
      pagoTotal
      valorPago
      valorPendiente
      estado
      formaPago
      fechaCrea
      fechaVence
      fechaEdita
      fechaPagado
      fechaAbono
      abonos {
        valor
        fecha
        usuarioID
      }
      clienteId
      applyIva
      clienteName
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      observaciones
      usuarioID
      usuarioEdita
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            remisiones {
              nextToken
            }
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            stamp {
              barCodeContent
              cufe
              date
              legalStatus
              warnings
            }
            sharedID
            creditNoteID
            kindOfDuplicate
            previousInvoiceID
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCotizacions = /* GraphQL */ `
  query ListCotizacions(
    $filter: ModelCotizacionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCotizacions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        alegraId
        emails
        consecutivo
        vendedorID
        ciudadId
        vendedorAlegraId
        pagoTotal
        valorPago
        valorPendiente
        estado
        formaPago
        fechaCrea
        fechaVence
        fechaEdita
        fechaPagado
        fechaAbono
        abonos {
          valor
          fecha
          usuarioID
        }
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        observaciones
        usuarioID
        usuarioEdita
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCotizacions = /* GraphQL */ `
  query SearchCotizacions(
    $filter: SearchableCotizacionFilterInput
    $sort: [SearchableCotizacionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCotizacionAggregationInput]
  ) {
    searchCotizacions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        alegraId
        emails
        consecutivo
        vendedorID
        ciudadId
        vendedorAlegraId
        pagoTotal
        valorPago
        valorPendiente
        estado
        formaPago
        fechaCrea
        fechaVence
        fechaEdita
        fechaPagado
        fechaAbono
        abonos {
          valor
          fecha
          usuarioID
        }
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        observaciones
        usuarioID
        usuarioEdita
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getProductFact = /* GraphQL */ `
  query GetProductFact($id: ID!) {
    getProductFact(id: $id) {
      id
      idItem
      ciudad
      clienteID
      clienteName
      remisionID
      remisionNro
      remision {
        id
        alegraId
        alegraNumeracion
        formaPago
        vendedorID
        facturaID
        factura {
          id
          remisionesIDs
          remisiones {
            items {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            nextToken
          }
          alegraId
          formaPago
          emails
          alegraNumeracion
          ciudadId
          vendedorID
          vendedorAlegraId
          clienteId
          estado
          fechaCrea
          fechaVence
          fechaEdita
          applyIva
          clienteName
          observaciones
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          valorPendiente
          usuarioID
          usuarioEdita
          pdf
          pdfAlt
          url
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          totalSinRetenciones
          stamp {
            barCodeContent
            cufe
            date
            legalStatus
            warnings
          }
          sharedID
          creditNoteID
          kindOfDuplicate
          previousInvoiceID
          createdAt
          updatedAt
        }
        emails
        ciudadId
        vendedorAlegraId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        observaciones
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cotizaNro
      cotizaID
      cotiza {
        id
        alegraId
        emails
        consecutivo
        vendedorID
        ciudadId
        vendedorAlegraId
        pagoTotal
        valorPago
        valorPendiente
        estado
        formaPago
        fechaCrea
        fechaVence
        fechaEdita
        fechaPagado
        fechaAbono
        abonos {
          valor
          fecha
          usuarioID
        }
        clienteId
        applyIva
        clienteName
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        observaciones
        usuarioID
        usuarioEdita
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      facturaNro
      facturaID
      factura {
        id
        remisionesIDs
        remisiones {
          items {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        alegraId
        formaPago
        emails
        alegraNumeracion
        ciudadId
        vendedorID
        vendedorAlegraId
        clienteId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        applyIva
        clienteName
        observaciones
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        totalSinRetenciones
        stamp {
          barCodeContent
          cufe
          date
          legalStatus
          warnings
        }
        sharedID
        creditNoteID
        kindOfDuplicate
        previousInvoiceID
        createdAt
        updatedAt
      }
      odc
      alegraItemId
      orden
      iva
      type
      plateType
      material
      calibre
      ordenVersion
      descripcion
      valorUnitario
      version
      ancho
      largo
      cantidad
      cajaNro
      caja
      area
      valorTotal
      vendedorID
      pagado
      createdAt
      updatedAt
    }
  }
`;
export const listProductFacts = /* GraphQL */ `
  query ListProductFacts(
    $filter: ModelProductFactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductFacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idItem
        ciudad
        clienteID
        clienteName
        remisionID
        remisionNro
        remision {
          id
          alegraId
          alegraNumeracion
          formaPago
          vendedorID
          facturaID
          factura {
            id
            remisionesIDs
            remisiones {
              nextToken
            }
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            stamp {
              barCodeContent
              cufe
              date
              legalStatus
              warnings
            }
            sharedID
            creditNoteID
            kindOfDuplicate
            previousInvoiceID
            createdAt
            updatedAt
          }
          emails
          ciudadId
          vendedorAlegraId
          estado
          fechaCrea
          fechaVence
          fechaEdita
          clienteId
          applyIva
          clienteName
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          valorPendiente
          observaciones
          usuarioID
          usuarioEdita
          pdf
          pdfAlt
          url
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        cotizaNro
        cotizaID
        cotiza {
          id
          alegraId
          emails
          consecutivo
          vendedorID
          ciudadId
          vendedorAlegraId
          pagoTotal
          valorPago
          valorPendiente
          estado
          formaPago
          fechaCrea
          fechaVence
          fechaEdita
          fechaPagado
          fechaAbono
          abonos {
            valor
            fecha
            usuarioID
          }
          clienteId
          applyIva
          clienteName
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          observaciones
          usuarioID
          usuarioEdita
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        facturaNro
        facturaID
        factura {
          id
          remisionesIDs
          remisiones {
            items {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            nextToken
          }
          alegraId
          formaPago
          emails
          alegraNumeracion
          ciudadId
          vendedorID
          vendedorAlegraId
          clienteId
          estado
          fechaCrea
          fechaVence
          fechaEdita
          applyIva
          clienteName
          observaciones
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          valorPendiente
          usuarioID
          usuarioEdita
          pdf
          pdfAlt
          url
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          totalSinRetenciones
          stamp {
            barCodeContent
            cufe
            date
            legalStatus
            warnings
          }
          sharedID
          creditNoteID
          kindOfDuplicate
          previousInvoiceID
          createdAt
          updatedAt
        }
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
        vendedorID
        pagado
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchProductFacts = /* GraphQL */ `
  query SearchProductFacts(
    $filter: SearchableProductFactFilterInput
    $sort: [SearchableProductFactSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProductFactAggregationInput]
  ) {
    searchProductFacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        idItem
        ciudad
        clienteID
        clienteName
        remisionID
        remisionNro
        remision {
          id
          alegraId
          alegraNumeracion
          formaPago
          vendedorID
          facturaID
          factura {
            id
            remisionesIDs
            remisiones {
              nextToken
            }
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            stamp {
              barCodeContent
              cufe
              date
              legalStatus
              warnings
            }
            sharedID
            creditNoteID
            kindOfDuplicate
            previousInvoiceID
            createdAt
            updatedAt
          }
          emails
          ciudadId
          vendedorAlegraId
          estado
          fechaCrea
          fechaVence
          fechaEdita
          clienteId
          applyIva
          clienteName
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          valorPendiente
          observaciones
          usuarioID
          usuarioEdita
          pdf
          pdfAlt
          url
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        cotizaNro
        cotizaID
        cotiza {
          id
          alegraId
          emails
          consecutivo
          vendedorID
          ciudadId
          vendedorAlegraId
          pagoTotal
          valorPago
          valorPendiente
          estado
          formaPago
          fechaCrea
          fechaVence
          fechaEdita
          fechaPagado
          fechaAbono
          abonos {
            valor
            fecha
            usuarioID
          }
          clienteId
          applyIva
          clienteName
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          observaciones
          usuarioID
          usuarioEdita
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        facturaNro
        facturaID
        factura {
          id
          remisionesIDs
          remisiones {
            items {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            nextToken
          }
          alegraId
          formaPago
          emails
          alegraNumeracion
          ciudadId
          vendedorID
          vendedorAlegraId
          clienteId
          estado
          fechaCrea
          fechaVence
          fechaEdita
          applyIva
          clienteName
          observaciones
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          valorPendiente
          usuarioID
          usuarioEdita
          pdf
          pdfAlt
          url
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          retenciones {
            id
            name
            type
            percentage
            valor
            valorBase
          }
          totalSinRetenciones
          stamp {
            barCodeContent
            cufe
            date
            legalStatus
            warnings
          }
          sharedID
          creditNoteID
          kindOfDuplicate
          previousInvoiceID
          createdAt
          updatedAt
        }
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
        vendedorID
        pagado
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getFactura = /* GraphQL */ `
  query GetFactura($id: ID!) {
    getFactura(id: $id) {
      id
      remisionesIDs
      remisiones {
        items {
          id
          alegraId
          alegraNumeracion
          formaPago
          vendedorID
          facturaID
          factura {
            id
            remisionesIDs
            remisiones {
              nextToken
            }
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            stamp {
              barCodeContent
              cufe
              date
              legalStatus
              warnings
            }
            sharedID
            creditNoteID
            kindOfDuplicate
            previousInvoiceID
            createdAt
            updatedAt
          }
          emails
          ciudadId
          vendedorAlegraId
          estado
          fechaCrea
          fechaVence
          fechaEdita
          clienteId
          applyIva
          clienteName
          alegraClienteId
          ordenes {
            numero
            odc
            version
            applyIva
            tipoProducto
            ciudad {
              idCiudad
              name
              short
              alegraID
              oldOP
              costCenterId
              user
            }
            observaciones {
              NUMERO
              OBSERV
              FECHACREA
              TIPO
              ACTIVO
              VERSIONOP
              PRIORIDAD
              REGISTRO
              USUARIO
              idObserv
            }
            descripcion
            tipoPlancha
            material
            calibre
            referenciaPlanchas
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            planchasxColor
            qtyLen
            totalPlanchas
          }
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          totalArea
          subtotal
          iva
          total
          valorPendiente
          observaciones
          usuarioID
          usuarioEdita
          pdf
          pdfAlt
          url
          productFact {
            items {
              id
              idItem
              ciudad
              clienteID
              clienteName
              remisionID
              remisionNro
              cotizaNro
              cotizaID
              facturaNro
              facturaID
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
              vendedorID
              pagado
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      alegraId
      formaPago
      emails
      alegraNumeracion
      ciudadId
      vendedorID
      vendedorAlegraId
      clienteId
      estado
      fechaCrea
      fechaVence
      fechaEdita
      applyIva
      clienteName
      observaciones
      alegraClienteId
      ordenes {
        numero
        odc
        version
        applyIva
        tipoProducto
        ciudad {
          idCiudad
          name
          short
          alegraID
          oldOP
          costCenterId
          user
        }
        observaciones {
          NUMERO
          OBSERV
          FECHACREA
          TIPO
          ACTIVO
          VERSIONOP
          PRIORIDAD
          REGISTRO
          USUARIO
          idObserv
        }
        descripcion
        tipoPlancha
        material
        calibre
        referenciaPlanchas
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        planchasxColor
        qtyLen
        totalPlanchas
      }
      itemsFact {
        idItem
        odc
        alegraItemId
        orden
        iva
        type
        plateType
        material
        calibre
        ordenVersion
        descripcion
        valorUnitario
        version
        ancho
        largo
        cantidad
        cajaNro
        caja
        area
        valorTotal
      }
      totalArea
      subtotal
      iva
      total
      valorPendiente
      usuarioID
      usuarioEdita
      pdf
      pdfAlt
      url
      productFact {
        items {
          id
          idItem
          ciudad
          clienteID
          clienteName
          remisionID
          remisionNro
          remision {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          cotizaNro
          cotizaID
          cotiza {
            id
            alegraId
            emails
            consecutivo
            vendedorID
            ciudadId
            vendedorAlegraId
            pagoTotal
            valorPago
            valorPendiente
            estado
            formaPago
            fechaCrea
            fechaVence
            fechaEdita
            fechaPagado
            fechaAbono
            abonos {
              valor
              fecha
              usuarioID
            }
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            observaciones
            usuarioID
            usuarioEdita
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          facturaNro
          facturaID
          factura {
            id
            remisionesIDs
            remisiones {
              nextToken
            }
            alegraId
            formaPago
            emails
            alegraNumeracion
            ciudadId
            vendedorID
            vendedorAlegraId
            clienteId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            applyIva
            clienteName
            observaciones
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            totalSinRetenciones
            stamp {
              barCodeContent
              cufe
              date
              legalStatus
              warnings
            }
            sharedID
            creditNoteID
            kindOfDuplicate
            previousInvoiceID
            createdAt
            updatedAt
          }
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
          vendedorID
          pagado
          createdAt
          updatedAt
        }
        nextToken
      }
      retenciones {
        id
        name
        type
        percentage
        valor
        valorBase
      }
      totalSinRetenciones
      stamp {
        barCodeContent
        cufe
        date
        legalStatus
        warnings
      }
      sharedID
      creditNoteID
      kindOfDuplicate
      previousInvoiceID
      createdAt
      updatedAt
    }
  }
`;
export const listFacturas = /* GraphQL */ `
  query ListFacturas(
    $filter: ModelFacturaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacturas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        remisionesIDs
        remisiones {
          items {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        alegraId
        formaPago
        emails
        alegraNumeracion
        ciudadId
        vendedorID
        vendedorAlegraId
        clienteId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        applyIva
        clienteName
        observaciones
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        totalSinRetenciones
        stamp {
          barCodeContent
          cufe
          date
          legalStatus
          warnings
        }
        sharedID
        creditNoteID
        kindOfDuplicate
        previousInvoiceID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchFacturas = /* GraphQL */ `
  query SearchFacturas(
    $filter: SearchableFacturaFilterInput
    $sort: [SearchableFacturaSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableFacturaAggregationInput]
  ) {
    searchFacturas(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        remisionesIDs
        remisiones {
          items {
            id
            alegraId
            alegraNumeracion
            formaPago
            vendedorID
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            emails
            ciudadId
            vendedorAlegraId
            estado
            fechaCrea
            fechaVence
            fechaEdita
            clienteId
            applyIva
            clienteName
            alegraClienteId
            ordenes {
              numero
              odc
              version
              applyIva
              tipoProducto
              descripcion
              tipoPlancha
              material
              calibre
              referenciaPlanchas
              planchasxColor
              qtyLen
              totalPlanchas
            }
            itemsFact {
              idItem
              odc
              alegraItemId
              orden
              iva
              type
              plateType
              material
              calibre
              ordenVersion
              descripcion
              valorUnitario
              version
              ancho
              largo
              cantidad
              cajaNro
              caja
              area
              valorTotal
            }
            totalArea
            subtotal
            iva
            total
            valorPendiente
            observaciones
            usuarioID
            usuarioEdita
            pdf
            pdfAlt
            url
            productFact {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        alegraId
        formaPago
        emails
        alegraNumeracion
        ciudadId
        vendedorID
        vendedorAlegraId
        clienteId
        estado
        fechaCrea
        fechaVence
        fechaEdita
        applyIva
        clienteName
        observaciones
        alegraClienteId
        ordenes {
          numero
          odc
          version
          applyIva
          tipoProducto
          ciudad {
            idCiudad
            name
            short
            alegraID
            oldOP
            costCenterId
            user
          }
          observaciones {
            NUMERO
            OBSERV
            FECHACREA
            TIPO
            ACTIVO
            VERSIONOP
            PRIORIDAD
            REGISTRO
            USUARIO
            idObserv
          }
          descripcion
          tipoPlancha
          material
          calibre
          referenciaPlanchas
          itemsFact {
            idItem
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
          }
          planchasxColor
          qtyLen
          totalPlanchas
        }
        itemsFact {
          idItem
          odc
          alegraItemId
          orden
          iva
          type
          plateType
          material
          calibre
          ordenVersion
          descripcion
          valorUnitario
          version
          ancho
          largo
          cantidad
          cajaNro
          caja
          area
          valorTotal
        }
        totalArea
        subtotal
        iva
        total
        valorPendiente
        usuarioID
        usuarioEdita
        pdf
        pdfAlt
        url
        productFact {
          items {
            id
            idItem
            ciudad
            clienteID
            clienteName
            remisionID
            remisionNro
            remision {
              id
              alegraId
              alegraNumeracion
              formaPago
              vendedorID
              facturaID
              emails
              ciudadId
              vendedorAlegraId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              observaciones
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              createdAt
              updatedAt
            }
            cotizaNro
            cotizaID
            cotiza {
              id
              alegraId
              emails
              consecutivo
              vendedorID
              ciudadId
              vendedorAlegraId
              pagoTotal
              valorPago
              valorPendiente
              estado
              formaPago
              fechaCrea
              fechaVence
              fechaEdita
              fechaPagado
              fechaAbono
              clienteId
              applyIva
              clienteName
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              observaciones
              usuarioID
              usuarioEdita
              createdAt
              updatedAt
            }
            facturaNro
            facturaID
            factura {
              id
              remisionesIDs
              alegraId
              formaPago
              emails
              alegraNumeracion
              ciudadId
              vendedorID
              vendedorAlegraId
              clienteId
              estado
              fechaCrea
              fechaVence
              fechaEdita
              applyIva
              clienteName
              observaciones
              alegraClienteId
              totalArea
              subtotal
              iva
              total
              valorPendiente
              usuarioID
              usuarioEdita
              pdf
              pdfAlt
              url
              totalSinRetenciones
              sharedID
              creditNoteID
              kindOfDuplicate
              previousInvoiceID
              createdAt
              updatedAt
            }
            odc
            alegraItemId
            orden
            iva
            type
            plateType
            material
            calibre
            ordenVersion
            descripcion
            valorUnitario
            version
            ancho
            largo
            cantidad
            cajaNro
            caja
            area
            valorTotal
            vendedorID
            pagado
            createdAt
            updatedAt
          }
          nextToken
        }
        retenciones {
          id
          name
          type
          percentage
          valor
          valorBase
        }
        totalSinRetenciones
        stamp {
          barCodeContent
          cufe
          date
          legalStatus
          warnings
        }
        sharedID
        creditNoteID
        kindOfDuplicate
        previousInvoiceID
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMarcacion = /* GraphQL */ `
  query GetMarcacion($id: ID!) {
    getMarcacion(id: $id) {
      id
      fechaHora
      usuarioId
      type
      huellaId
      userName
      device
      turnoID
      createdAt
      updatedAt
    }
  }
`;
export const listMarcacions = /* GraphQL */ `
  query ListMarcacions(
    $filter: ModelMarcacionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarcacions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fechaHora
        usuarioId
        type
        huellaId
        userName
        device
        turnoID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchMarcacions = /* GraphQL */ `
  query SearchMarcacions(
    $filter: SearchableMarcacionFilterInput
    $sort: [SearchableMarcacionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMarcacionAggregationInput]
  ) {
    searchMarcacions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        fechaHora
        usuarioId
        type
        huellaId
        userName
        device
        turnoID
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getTurno = /* GraphQL */ `
  query GetTurno($id: ID!) {
    getTurno(id: $id) {
      id
      marcacionesIds
      Marcaciones {
        items {
          id
          fechaHora
          usuarioId
          type
          huellaId
          userName
          device
          turnoID
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      userName
      inicio
      fin
      tPausas
      totalHoras
      horasLaboradas
      hOrdinaria
      rNocturno
      rDominicalNocturno
      hExtraDiurna
      hOrdinariaDominical
      hExtraDominicalDiurna
      hExtraDominicalNocturna
      hExtraNocturna
      hExtrasTotal
      hLibre
      hPendiente
      TotalHorasExtrasConRecargos
      liquidado
      periodoID
      createdAt
      updatedAt
    }
  }
`;
export const listTurnos = /* GraphQL */ `
  query ListTurnos(
    $filter: ModelTurnoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTurnos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        marcacionesIds
        Marcaciones {
          items {
            id
            fechaHora
            usuarioId
            type
            huellaId
            userName
            device
            turnoID
            createdAt
            updatedAt
          }
          nextToken
        }
        userId
        userName
        inicio
        fin
        tPausas
        totalHoras
        horasLaboradas
        hOrdinaria
        rNocturno
        rDominicalNocturno
        hExtraDiurna
        hOrdinariaDominical
        hExtraDominicalDiurna
        hExtraDominicalNocturna
        hExtraNocturna
        hExtrasTotal
        hLibre
        hPendiente
        TotalHorasExtrasConRecargos
        liquidado
        periodoID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchTurnos = /* GraphQL */ `
  query SearchTurnos(
    $filter: SearchableTurnoFilterInput
    $sort: [SearchableTurnoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTurnoAggregationInput]
  ) {
    searchTurnos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        marcacionesIds
        Marcaciones {
          items {
            id
            fechaHora
            usuarioId
            type
            huellaId
            userName
            device
            turnoID
            createdAt
            updatedAt
          }
          nextToken
        }
        userId
        userName
        inicio
        fin
        tPausas
        totalHoras
        horasLaboradas
        hOrdinaria
        rNocturno
        rDominicalNocturno
        hExtraDiurna
        hOrdinariaDominical
        hExtraDominicalDiurna
        hExtraDominicalNocturna
        hExtraNocturna
        hExtrasTotal
        hLibre
        hPendiente
        TotalHorasExtrasConRecargos
        liquidado
        periodoID
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getPeriodo = /* GraphQL */ `
  query GetPeriodo($id: ID!) {
    getPeriodo(id: $id) {
      id
      inicio
      fin
      userId
      totalHoras
      totalPausas
      totalHorasLaboradas
      totalTiempoLibre
      totalHERecargos
      diasLaborables
      diasLaborados
      horasLaborables
      horasLaboradas
      diasIncapacidad
      diasVacaciones
      diasLicenciaRemunerada
      diasLicenciaNoRemunerada
      Turnos {
        items {
          id
          marcacionesIds
          Marcaciones {
            items {
              id
              fechaHora
              usuarioId
              type
              huellaId
              userName
              device
              turnoID
              createdAt
              updatedAt
            }
            nextToken
          }
          userId
          userName
          inicio
          fin
          tPausas
          totalHoras
          horasLaboradas
          hOrdinaria
          rNocturno
          rDominicalNocturno
          hExtraDiurna
          hOrdinariaDominical
          hExtraDominicalDiurna
          hExtraDominicalNocturna
          hExtraNocturna
          hExtrasTotal
          hLibre
          hPendiente
          TotalHorasExtrasConRecargos
          liquidado
          periodoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPeriodos = /* GraphQL */ `
  query ListPeriodos(
    $filter: ModelPeriodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeriodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        inicio
        fin
        userId
        totalHoras
        totalPausas
        totalHorasLaboradas
        totalTiempoLibre
        totalHERecargos
        diasLaborables
        diasLaborados
        horasLaborables
        horasLaboradas
        diasIncapacidad
        diasVacaciones
        diasLicenciaRemunerada
        diasLicenciaNoRemunerada
        Turnos {
          items {
            id
            marcacionesIds
            Marcaciones {
              nextToken
            }
            userId
            userName
            inicio
            fin
            tPausas
            totalHoras
            horasLaboradas
            hOrdinaria
            rNocturno
            rDominicalNocturno
            hExtraDiurna
            hOrdinariaDominical
            hExtraDominicalDiurna
            hExtraDominicalNocturna
            hExtraNocturna
            hExtrasTotal
            hLibre
            hPendiente
            TotalHorasExtrasConRecargos
            liquidado
            periodoID
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHorasAcumuladas = /* GraphQL */ `
  query GetHorasAcumuladas($userId: ID!) {
    getHorasAcumuladas(userId: $userId) {
      tiempoLibre
      tiempoPendiente
      tiempoExtra
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listHorasAcumuladas = /* GraphQL */ `
  query ListHorasAcumuladas(
    $userId: ID
    $filter: ModelHorasAcumuladasFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHorasAcumuladas(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tiempoLibre
        tiempoPendiente
        tiempoExtra
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMaterial = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      alegraId
      applyIva
      valorVenta
      alegraCategory
      proveedor
      referencia
      calibre
      bodega
      monedaReferencia
      valorUsd
      valorUpchargeUsd
      valorTotalUsd
      valorCop
      valorUpchargeCop
      valorTotalCop
      unidadesUsd
      unidadesCop
      trm
      fechaTrm
      stock {
        items {
          id
          materialID
          alegraItemId
          anchoLamina
          largoLamina
          cantidad
          areaTotal
          areaRestante
          consecutivo
          cajaNumero
          fechaIngreso
          fechaSalida
          retales {
            items {
              id
              stockID
              ancho
              largo
              cantidad
              areaTotal
              fechaIngreso
              fechaSalida
              createdAt
              updatedAt
              stockRetalesId
            }
            nextToken
          }
          lote
          ingresada
          activa
          cerrada
          usuarioIngresa
          usuarioActiva
          usuarioCierra
          createdAt
          updatedAt
          materialStockId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMaterials = /* GraphQL */ `
  query ListMaterials(
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        alegraId
        applyIva
        valorVenta
        alegraCategory
        proveedor
        referencia
        calibre
        bodega
        monedaReferencia
        valorUsd
        valorUpchargeUsd
        valorTotalUsd
        valorCop
        valorUpchargeCop
        valorTotalCop
        unidadesUsd
        unidadesCop
        trm
        fechaTrm
        stock {
          items {
            id
            materialID
            alegraItemId
            anchoLamina
            largoLamina
            cantidad
            areaTotal
            areaRestante
            consecutivo
            cajaNumero
            fechaIngreso
            fechaSalida
            retales {
              nextToken
            }
            lote
            ingresada
            activa
            cerrada
            usuarioIngresa
            usuarioActiva
            usuarioCierra
            createdAt
            updatedAt
            materialStockId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchMaterials = /* GraphQL */ `
  query SearchMaterials(
    $filter: SearchableMaterialFilterInput
    $sort: [SearchableMaterialSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMaterialAggregationInput]
  ) {
    searchMaterials(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        alegraId
        applyIva
        valorVenta
        alegraCategory
        proveedor
        referencia
        calibre
        bodega
        monedaReferencia
        valorUsd
        valorUpchargeUsd
        valorTotalUsd
        valorCop
        valorUpchargeCop
        valorTotalCop
        unidadesUsd
        unidadesCop
        trm
        fechaTrm
        stock {
          items {
            id
            materialID
            alegraItemId
            anchoLamina
            largoLamina
            cantidad
            areaTotal
            areaRestante
            consecutivo
            cajaNumero
            fechaIngreso
            fechaSalida
            retales {
              nextToken
            }
            lote
            ingresada
            activa
            cerrada
            usuarioIngresa
            usuarioActiva
            usuarioCierra
            createdAt
            updatedAt
            materialStockId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getStock = /* GraphQL */ `
  query GetStock($id: ID!) {
    getStock(id: $id) {
      id
      materialID
      alegraItemId
      anchoLamina
      largoLamina
      cantidad
      areaTotal
      areaRestante
      consecutivo
      cajaNumero
      fechaIngreso
      fechaSalida
      retales {
        items {
          id
          stockID
          stock {
            id
            materialID
            alegraItemId
            anchoLamina
            largoLamina
            cantidad
            areaTotal
            areaRestante
            consecutivo
            cajaNumero
            fechaIngreso
            fechaSalida
            retales {
              nextToken
            }
            lote
            ingresada
            activa
            cerrada
            usuarioIngresa
            usuarioActiva
            usuarioCierra
            createdAt
            updatedAt
            materialStockId
          }
          ancho
          largo
          cantidad
          areaTotal
          fechaIngreso
          fechaSalida
          createdAt
          updatedAt
          stockRetalesId
        }
        nextToken
      }
      lote
      ingresada
      activa
      cerrada
      usuarioIngresa
      usuarioActiva
      usuarioCierra
      createdAt
      updatedAt
      materialStockId
    }
  }
`;
export const listStocks = /* GraphQL */ `
  query ListStocks(
    $filter: ModelStockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        materialID
        alegraItemId
        anchoLamina
        largoLamina
        cantidad
        areaTotal
        areaRestante
        consecutivo
        cajaNumero
        fechaIngreso
        fechaSalida
        retales {
          items {
            id
            stockID
            stock {
              id
              materialID
              alegraItemId
              anchoLamina
              largoLamina
              cantidad
              areaTotal
              areaRestante
              consecutivo
              cajaNumero
              fechaIngreso
              fechaSalida
              lote
              ingresada
              activa
              cerrada
              usuarioIngresa
              usuarioActiva
              usuarioCierra
              createdAt
              updatedAt
              materialStockId
            }
            ancho
            largo
            cantidad
            areaTotal
            fechaIngreso
            fechaSalida
            createdAt
            updatedAt
            stockRetalesId
          }
          nextToken
        }
        lote
        ingresada
        activa
        cerrada
        usuarioIngresa
        usuarioActiva
        usuarioCierra
        createdAt
        updatedAt
        materialStockId
      }
      nextToken
    }
  }
`;
export const searchStocks = /* GraphQL */ `
  query SearchStocks(
    $filter: SearchableStockFilterInput
    $sort: [SearchableStockSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableStockAggregationInput]
  ) {
    searchStocks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        materialID
        alegraItemId
        anchoLamina
        largoLamina
        cantidad
        areaTotal
        areaRestante
        consecutivo
        cajaNumero
        fechaIngreso
        fechaSalida
        retales {
          items {
            id
            stockID
            stock {
              id
              materialID
              alegraItemId
              anchoLamina
              largoLamina
              cantidad
              areaTotal
              areaRestante
              consecutivo
              cajaNumero
              fechaIngreso
              fechaSalida
              lote
              ingresada
              activa
              cerrada
              usuarioIngresa
              usuarioActiva
              usuarioCierra
              createdAt
              updatedAt
              materialStockId
            }
            ancho
            largo
            cantidad
            areaTotal
            fechaIngreso
            fechaSalida
            createdAt
            updatedAt
            stockRetalesId
          }
          nextToken
        }
        lote
        ingresada
        activa
        cerrada
        usuarioIngresa
        usuarioActiva
        usuarioCierra
        createdAt
        updatedAt
        materialStockId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getRetal = /* GraphQL */ `
  query GetRetal($id: ID!) {
    getRetal(id: $id) {
      id
      stockID
      stock {
        id
        materialID
        alegraItemId
        anchoLamina
        largoLamina
        cantidad
        areaTotal
        areaRestante
        consecutivo
        cajaNumero
        fechaIngreso
        fechaSalida
        retales {
          items {
            id
            stockID
            stock {
              id
              materialID
              alegraItemId
              anchoLamina
              largoLamina
              cantidad
              areaTotal
              areaRestante
              consecutivo
              cajaNumero
              fechaIngreso
              fechaSalida
              lote
              ingresada
              activa
              cerrada
              usuarioIngresa
              usuarioActiva
              usuarioCierra
              createdAt
              updatedAt
              materialStockId
            }
            ancho
            largo
            cantidad
            areaTotal
            fechaIngreso
            fechaSalida
            createdAt
            updatedAt
            stockRetalesId
          }
          nextToken
        }
        lote
        ingresada
        activa
        cerrada
        usuarioIngresa
        usuarioActiva
        usuarioCierra
        createdAt
        updatedAt
        materialStockId
      }
      ancho
      largo
      cantidad
      areaTotal
      fechaIngreso
      fechaSalida
      createdAt
      updatedAt
      stockRetalesId
    }
  }
`;
export const listRetals = /* GraphQL */ `
  query ListRetals(
    $filter: ModelRetalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stockID
        stock {
          id
          materialID
          alegraItemId
          anchoLamina
          largoLamina
          cantidad
          areaTotal
          areaRestante
          consecutivo
          cajaNumero
          fechaIngreso
          fechaSalida
          retales {
            items {
              id
              stockID
              ancho
              largo
              cantidad
              areaTotal
              fechaIngreso
              fechaSalida
              createdAt
              updatedAt
              stockRetalesId
            }
            nextToken
          }
          lote
          ingresada
          activa
          cerrada
          usuarioIngresa
          usuarioActiva
          usuarioCierra
          createdAt
          updatedAt
          materialStockId
        }
        ancho
        largo
        cantidad
        areaTotal
        fechaIngreso
        fechaSalida
        createdAt
        updatedAt
        stockRetalesId
      }
      nextToken
    }
  }
`;
export const getExposicion = /* GraphQL */ `
  query GetExposicion($id: ID!) {
    getExposicion(id: $id) {
      id
      plateName
      dateExposed
      plateType
      ciudad
      calibre
      len {
        lenId
        lenWidth
        lenHeight
        name
        uuid
        area
        image {
          bucket
          region
          key
        }
        op
        version
        reposicion
      }
      areaTotalLen
      imageExp {
        bucket
        region
        key
      }
      expoName
      taras
      responsablePin
      responsableId
      responsableName
      anulada
      responsableReposicion
      reposicionName
      reposicion
      referencia
      cajas
      cajaId
      caja {
        id
        ancho
        largo
        cantidadLaminas
        areaTotal
        calibre
        consecutivo
        areaRestante
        createdAt
        updatedAt
      }
      motivo
      pendienteSalida
      isPlate
      desperdicio
      desperdicioPerc
      anchoRetal
      largoRetal
      anchoLamina
      largoLamina
      ordenesSalida {
        items {
          id
          exposicionID
          ordenSalidaID
          exposicion {
            id
            plateName
            dateExposed
            plateType
            ciudad
            calibre
            len {
              lenId
              lenWidth
              lenHeight
              name
              uuid
              area
              op
              version
              reposicion
            }
            areaTotalLen
            imageExp {
              bucket
              region
              key
            }
            expoName
            taras
            responsablePin
            responsableId
            responsableName
            anulada
            responsableReposicion
            reposicionName
            reposicion
            referencia
            cajas
            cajaId
            caja {
              id
              ancho
              largo
              cantidadLaminas
              areaTotal
              calibre
              consecutivo
              areaRestante
              createdAt
              updatedAt
            }
            motivo
            pendienteSalida
            isPlate
            desperdicio
            desperdicioPerc
            anchoRetal
            largoRetal
            anchoLamina
            largoLamina
            ordenesSalida {
              nextToken
            }
            createdAt
            updatedAt
          }
          ordenSalida {
            id
            op
            version
            clienteOrdenId
            clienteOrden
            nameJob
            ciudadOrden
            tipoJob
            vendedorId
            vendedor
            salidaProd
            exposiciones {
              nextToken
            }
            ordenMaterial {
              uuid
              opAncho
              opLargo
              cantidad
              caja
              area
              total
            }
            comprobanteProd {
              bucket
              region
              key
            }
            observaciones
            totalPlanchasOrden
            calibreOrden
            totalPlanchasExp
            calibresExp
            motivoDiferencia
            motivoReposicion
            reposicion
            responsableRepo
            refMaterial
            refPlanchas
            qtyLen
            planchasXColor
            ordenCompra
            expID
            infoRetal {
              uuid
              typeMaterial
              desperdicio
              porcentajeDesp
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
            }
            pinSalida
            responsableSalida
            responsableNombre
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listExposicions = /* GraphQL */ `
  query ListExposicions(
    $filter: ModelExposicionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExposicions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        plateName
        dateExposed
        plateType
        ciudad
        calibre
        len {
          lenId
          lenWidth
          lenHeight
          name
          uuid
          area
          image {
            bucket
            region
            key
          }
          op
          version
          reposicion
        }
        areaTotalLen
        imageExp {
          bucket
          region
          key
        }
        expoName
        taras
        responsablePin
        responsableId
        responsableName
        anulada
        responsableReposicion
        reposicionName
        reposicion
        referencia
        cajas
        cajaId
        caja {
          id
          ancho
          largo
          cantidadLaminas
          areaTotal
          calibre
          consecutivo
          areaRestante
          createdAt
          updatedAt
        }
        motivo
        pendienteSalida
        isPlate
        desperdicio
        desperdicioPerc
        anchoRetal
        largoRetal
        anchoLamina
        largoLamina
        ordenesSalida {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              pinSalida
              responsableSalida
              responsableNombre
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchExposicions = /* GraphQL */ `
  query SearchExposicions(
    $filter: SearchableExposicionFilterInput
    $sort: [SearchableExposicionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableExposicionAggregationInput]
  ) {
    searchExposicions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        plateName
        dateExposed
        plateType
        ciudad
        calibre
        len {
          lenId
          lenWidth
          lenHeight
          name
          uuid
          area
          image {
            bucket
            region
            key
          }
          op
          version
          reposicion
        }
        areaTotalLen
        imageExp {
          bucket
          region
          key
        }
        expoName
        taras
        responsablePin
        responsableId
        responsableName
        anulada
        responsableReposicion
        reposicionName
        reposicion
        referencia
        cajas
        cajaId
        caja {
          id
          ancho
          largo
          cantidadLaminas
          areaTotal
          calibre
          consecutivo
          areaRestante
          createdAt
          updatedAt
        }
        motivo
        pendienteSalida
        isPlate
        desperdicio
        desperdicioPerc
        anchoRetal
        largoRetal
        anchoLamina
        largoLamina
        ordenesSalida {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              pinSalida
              responsableSalida
              responsableNombre
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getSalidaOrden = /* GraphQL */ `
  query GetSalidaOrden($id: ID!) {
    getSalidaOrden(id: $id) {
      id
      opSalida
      versionSalida
      clienteSalidaId
      clienteSalidaName
      trabajoSalida
      ciudadSalida
      totalPlanchas
      tipoTrabajo
      fechaSalida
      vendedorId
      vendedorName
      materialOrden {
        anchoSalida
        largoSalida
        cantidadPlanchas
        cajaExpo
        areaSalida
        totalOrden
        observaciones
        valorUnitarioOrden
      }
      refMaterial
      refPlanchas
      lenQty
      calibreOrden
      planchasXColor
      odc
      createdAt
      updatedAt
    }
  }
`;
export const listSalidaOrdens = /* GraphQL */ `
  query ListSalidaOrdens(
    $filter: ModelSalidaOrdenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalidaOrdens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        opSalida
        versionSalida
        clienteSalidaId
        clienteSalidaName
        trabajoSalida
        ciudadSalida
        totalPlanchas
        tipoTrabajo
        fechaSalida
        vendedorId
        vendedorName
        materialOrden {
          anchoSalida
          largoSalida
          cantidadPlanchas
          cajaExpo
          areaSalida
          totalOrden
          observaciones
          valorUnitarioOrden
        }
        refMaterial
        refPlanchas
        lenQty
        calibreOrden
        planchasXColor
        odc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchSalidaOrdens = /* GraphQL */ `
  query SearchSalidaOrdens(
    $filter: SearchableSalidaOrdenFilterInput
    $sort: [SearchableSalidaOrdenSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSalidaOrdenAggregationInput]
  ) {
    searchSalidaOrdens(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        opSalida
        versionSalida
        clienteSalidaId
        clienteSalidaName
        trabajoSalida
        ciudadSalida
        totalPlanchas
        tipoTrabajo
        fechaSalida
        vendedorId
        vendedorName
        materialOrden {
          anchoSalida
          largoSalida
          cantidadPlanchas
          cajaExpo
          areaSalida
          totalOrden
          observaciones
          valorUnitarioOrden
        }
        refMaterial
        refPlanchas
        lenQty
        calibreOrden
        planchasXColor
        odc
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCaja = /* GraphQL */ `
  query GetCaja($id: ID!) {
    getCaja(id: $id) {
      id
      ancho
      largo
      cantidadLaminas
      areaTotal
      calibre
      consecutivo
      areaRestante
      createdAt
      updatedAt
    }
  }
`;
export const listCajas = /* GraphQL */ `
  query ListCajas(
    $filter: ModelCajaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCajas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ancho
        largo
        cantidadLaminas
        areaTotal
        calibre
        consecutivo
        areaRestante
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrdenSalida = /* GraphQL */ `
  query GetOrdenSalida($id: ID!) {
    getOrdenSalida(id: $id) {
      id
      op
      version
      clienteOrdenId
      clienteOrden
      nameJob
      ciudadOrden
      tipoJob
      vendedorId
      vendedor
      salidaProd
      exposiciones {
        items {
          id
          exposicionID
          ordenSalidaID
          exposicion {
            id
            plateName
            dateExposed
            plateType
            ciudad
            calibre
            len {
              lenId
              lenWidth
              lenHeight
              name
              uuid
              area
              op
              version
              reposicion
            }
            areaTotalLen
            imageExp {
              bucket
              region
              key
            }
            expoName
            taras
            responsablePin
            responsableId
            responsableName
            anulada
            responsableReposicion
            reposicionName
            reposicion
            referencia
            cajas
            cajaId
            caja {
              id
              ancho
              largo
              cantidadLaminas
              areaTotal
              calibre
              consecutivo
              areaRestante
              createdAt
              updatedAt
            }
            motivo
            pendienteSalida
            isPlate
            desperdicio
            desperdicioPerc
            anchoRetal
            largoRetal
            anchoLamina
            largoLamina
            ordenesSalida {
              nextToken
            }
            createdAt
            updatedAt
          }
          ordenSalida {
            id
            op
            version
            clienteOrdenId
            clienteOrden
            nameJob
            ciudadOrden
            tipoJob
            vendedorId
            vendedor
            salidaProd
            exposiciones {
              nextToken
            }
            ordenMaterial {
              uuid
              opAncho
              opLargo
              cantidad
              caja
              area
              total
            }
            comprobanteProd {
              bucket
              region
              key
            }
            observaciones
            totalPlanchasOrden
            calibreOrden
            totalPlanchasExp
            calibresExp
            motivoDiferencia
            motivoReposicion
            reposicion
            responsableRepo
            refMaterial
            refPlanchas
            qtyLen
            planchasXColor
            ordenCompra
            expID
            infoRetal {
              uuid
              typeMaterial
              desperdicio
              porcentajeDesp
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
            }
            pinSalida
            responsableSalida
            responsableNombre
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ordenMaterial {
        uuid
        opAncho
        opLargo
        cantidad
        caja
        area
        total
      }
      comprobanteProd {
        bucket
        region
        key
      }
      observaciones
      totalPlanchasOrden
      calibreOrden
      totalPlanchasExp
      calibresExp
      motivoDiferencia
      motivoReposicion
      reposicion
      responsableRepo
      refMaterial
      refPlanchas
      qtyLen
      planchasXColor
      ordenCompra
      expID
      infoRetal {
        uuid
        typeMaterial
        desperdicio
        porcentajeDesp
        anchoRetal
        largoRetal
        anchoLamina
        largoLamina
      }
      pinSalida
      responsableSalida
      responsableNombre
      createdAt
      updatedAt
    }
  }
`;
export const listOrdenSalidas = /* GraphQL */ `
  query ListOrdenSalidas(
    $filter: ModelOrdenSalidaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdenSalidas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        op
        version
        clienteOrdenId
        clienteOrden
        nameJob
        ciudadOrden
        tipoJob
        vendedorId
        vendedor
        salidaProd
        exposiciones {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              pinSalida
              responsableSalida
              responsableNombre
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ordenMaterial {
          uuid
          opAncho
          opLargo
          cantidad
          caja
          area
          total
        }
        comprobanteProd {
          bucket
          region
          key
        }
        observaciones
        totalPlanchasOrden
        calibreOrden
        totalPlanchasExp
        calibresExp
        motivoDiferencia
        motivoReposicion
        reposicion
        responsableRepo
        refMaterial
        refPlanchas
        qtyLen
        planchasXColor
        ordenCompra
        expID
        infoRetal {
          uuid
          typeMaterial
          desperdicio
          porcentajeDesp
          anchoRetal
          largoRetal
          anchoLamina
          largoLamina
        }
        pinSalida
        responsableSalida
        responsableNombre
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOrdenByOp = /* GraphQL */ `
  query ListOrdenByOp(
    $op: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelOrdenSalidaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdenByOp(
      op: $op
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        op
        version
        clienteOrdenId
        clienteOrden
        nameJob
        ciudadOrden
        tipoJob
        vendedorId
        vendedor
        salidaProd
        exposiciones {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              pinSalida
              responsableSalida
              responsableNombre
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ordenMaterial {
          uuid
          opAncho
          opLargo
          cantidad
          caja
          area
          total
        }
        comprobanteProd {
          bucket
          region
          key
        }
        observaciones
        totalPlanchasOrden
        calibreOrden
        totalPlanchasExp
        calibresExp
        motivoDiferencia
        motivoReposicion
        reposicion
        responsableRepo
        refMaterial
        refPlanchas
        qtyLen
        planchasXColor
        ordenCompra
        expID
        infoRetal {
          uuid
          typeMaterial
          desperdicio
          porcentajeDesp
          anchoRetal
          largoRetal
          anchoLamina
          largoLamina
        }
        pinSalida
        responsableSalida
        responsableNombre
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchOrdenSalidas = /* GraphQL */ `
  query SearchOrdenSalidas(
    $filter: SearchableOrdenSalidaFilterInput
    $sort: [SearchableOrdenSalidaSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrdenSalidaAggregationInput]
  ) {
    searchOrdenSalidas(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        op
        version
        clienteOrdenId
        clienteOrden
        nameJob
        ciudadOrden
        tipoJob
        vendedorId
        vendedor
        salidaProd
        exposiciones {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              pinSalida
              responsableSalida
              responsableNombre
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ordenMaterial {
          uuid
          opAncho
          opLargo
          cantidad
          caja
          area
          total
        }
        comprobanteProd {
          bucket
          region
          key
        }
        observaciones
        totalPlanchasOrden
        calibreOrden
        totalPlanchasExp
        calibresExp
        motivoDiferencia
        motivoReposicion
        reposicion
        responsableRepo
        refMaterial
        refPlanchas
        qtyLen
        planchasXColor
        ordenCompra
        expID
        infoRetal {
          uuid
          typeMaterial
          desperdicio
          porcentajeDesp
          anchoRetal
          largoRetal
          anchoLamina
          largoLamina
        }
        pinSalida
        responsableSalida
        responsableNombre
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getOrdenProducto = /* GraphQL */ `
  query GetOrdenProducto($id: ID!) {
    getOrdenProducto(id: $id) {
      id
      productoID
      ordenID
      producto {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        clienteID
        nombre
        marca {
          id
          nombre
          productor
          clienteID
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        marcaID
        ordenes {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        productoNuevo
        productoExistente
        productoRefID
        mainFile {
          bucket
          region
          key
        }
        mainFileThumbnail {
          bucket
          region
          key
        }
        auxFiles {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        marcaProductosId
      }
      orden {
        id
        cliente {
          id
          alegraId
          nit_cedula
          codigo
          ciudad
          sector
          marcas {
            items {
              id
              nombre
              productor
              clienteID
              createdAt
              updatedAt
            }
            nextToken
          }
          digitoVerificacion
          estado
          daysToPay
          daysAvgToPay
          maquinas {
            nombre
            tipoDesarrollo
            tipoMaquina
            paso
            anchoBanda
            velocidad
            plancha
            lpis
          }
          razonSocial
          departamento
          pais
          cotiza
          sucursalEncargada
          direccion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          tipoDocumento
          datosFacturacion {
            formaPago
            iva
            variacionTRM
            validarCxC
            retefuenteType
            retefuentePorc
            reteIvaBool
            reteIcaPorc
            reteIcaBool
            exportacion
            precioOrden {
              tipoOrden
              unidades
              valor
            }
            regimenAdq
            sobremedida
            retenciones {
              id
              name
              type
              percentage
              valor
              valorBase
            }
            retencionesIDs
            tipoPersonaAdq
            emailFE
          }
          productos {
            items {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            nextToken
          }
          ordenes {
            items {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            nextToken
          }
          indicativoPais
          indicativoCiudad
          telefono1
          telefono2
          observaciones
          createdAt
          updatedAt
        }
        odc
        clienteID
        prioridad
        tipoOrden
        cooimpreso
        calibre
        ResponsableActualID
        estado
        maquina
        montaje {
          pistas
          repeticiones
          rodillo
          radio
          paso
          gapPistas
          gapRepeticiones
          anchoBobina
          desarrollo
          truncado
          enfrentado
          cantidadTruncado
          prodAncho
          prodAlto
          tipoCorte
          posicionSalida
          embobinado
          email
        }
        numeroOPant
        versionOPant
        dueTime
        productoID
        producto {
          items {
            id
            productoID
            ordenID
            producto {
              id
              clienteID
              nombre
              marcaID
              productoNuevo
              productoExistente
              productoRefID
              createdAt
              updatedAt
              marcaProductosId
            }
            orden {
              id
              odc
              clienteID
              prioridad
              tipoOrden
              cooimpreso
              calibre
              ResponsableActualID
              estado
              maquina
              numeroOPant
              versionOPant
              dueTime
              productoID
              nombreAnt
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        nombreAnt
        rip {
          calibre
          elongacion
          lpi
          emulsion
          colores {
            nombre
            libro
            angulo
            index
            punto
            lpi
            bumpUp
            microCell
          }
          marcas
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrdenProductos = /* GraphQL */ `
  query ListOrdenProductos(
    $filter: ModelOrdenProductoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdenProductos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productoID
        ordenID
        producto {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          clienteID
          nombre
          marca {
            id
            nombre
            productor
            clienteID
            productos {
              nextToken
            }
            createdAt
            updatedAt
          }
          marcaID
          ordenes {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          productoNuevo
          productoExistente
          productoRefID
          mainFile {
            bucket
            region
            key
          }
          mainFileThumbnail {
            bucket
            region
            key
          }
          auxFiles {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          marcaProductosId
        }
        orden {
          id
          cliente {
            id
            alegraId
            nit_cedula
            codigo
            ciudad
            sector
            marcas {
              nextToken
            }
            digitoVerificacion
            estado
            daysToPay
            daysAvgToPay
            maquinas {
              nombre
              tipoDesarrollo
              tipoMaquina
              paso
              anchoBanda
              velocidad
              plancha
              lpis
            }
            razonSocial
            departamento
            pais
            cotiza
            sucursalEncargada
            direccion
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            tipoDocumento
            datosFacturacion {
              formaPago
              iva
              variacionTRM
              validarCxC
              retefuenteType
              retefuentePorc
              reteIvaBool
              reteIcaPorc
              reteIcaBool
              exportacion
              regimenAdq
              sobremedida
              retencionesIDs
              tipoPersonaAdq
              emailFE
            }
            productos {
              nextToken
            }
            ordenes {
              nextToken
            }
            indicativoPais
            indicativoCiudad
            telefono1
            telefono2
            observaciones
            createdAt
            updatedAt
          }
          odc
          clienteID
          prioridad
          tipoOrden
          cooimpreso
          calibre
          ResponsableActualID
          estado
          maquina
          montaje {
            pistas
            repeticiones
            rodillo
            radio
            paso
            gapPistas
            gapRepeticiones
            anchoBobina
            desarrollo
            truncado
            enfrentado
            cantidadTruncado
            prodAncho
            prodAlto
            tipoCorte
            posicionSalida
            embobinado
            email
          }
          numeroOPant
          versionOPant
          dueTime
          productoID
          producto {
            items {
              id
              productoID
              ordenID
              createdAt
              updatedAt
            }
            nextToken
          }
          nombreAnt
          rip {
            calibre
            elongacion
            lpi
            emulsion
            colores {
              nombre
              libro
              angulo
              index
              punto
              lpi
              bumpUp
              microCell
            }
            marcas
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrdenExposicion = /* GraphQL */ `
  query GetOrdenExposicion($id: ID!) {
    getOrdenExposicion(id: $id) {
      id
      exposicionID
      ordenSalidaID
      exposicion {
        id
        plateName
        dateExposed
        plateType
        ciudad
        calibre
        len {
          lenId
          lenWidth
          lenHeight
          name
          uuid
          area
          image {
            bucket
            region
            key
          }
          op
          version
          reposicion
        }
        areaTotalLen
        imageExp {
          bucket
          region
          key
        }
        expoName
        taras
        responsablePin
        responsableId
        responsableName
        anulada
        responsableReposicion
        reposicionName
        reposicion
        referencia
        cajas
        cajaId
        caja {
          id
          ancho
          largo
          cantidadLaminas
          areaTotal
          calibre
          consecutivo
          areaRestante
          createdAt
          updatedAt
        }
        motivo
        pendienteSalida
        isPlate
        desperdicio
        desperdicioPerc
        anchoRetal
        largoRetal
        anchoLamina
        largoLamina
        ordenesSalida {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              pinSalida
              responsableSalida
              responsableNombre
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      ordenSalida {
        id
        op
        version
        clienteOrdenId
        clienteOrden
        nameJob
        ciudadOrden
        tipoJob
        vendedorId
        vendedor
        salidaProd
        exposiciones {
          items {
            id
            exposicionID
            ordenSalidaID
            exposicion {
              id
              plateName
              dateExposed
              plateType
              ciudad
              calibre
              areaTotalLen
              expoName
              taras
              responsablePin
              responsableId
              responsableName
              anulada
              responsableReposicion
              reposicionName
              reposicion
              referencia
              cajas
              cajaId
              motivo
              pendienteSalida
              isPlate
              desperdicio
              desperdicioPerc
              anchoRetal
              largoRetal
              anchoLamina
              largoLamina
              createdAt
              updatedAt
            }
            ordenSalida {
              id
              op
              version
              clienteOrdenId
              clienteOrden
              nameJob
              ciudadOrden
              tipoJob
              vendedorId
              vendedor
              salidaProd
              observaciones
              totalPlanchasOrden
              calibreOrden
              totalPlanchasExp
              calibresExp
              motivoDiferencia
              motivoReposicion
              reposicion
              responsableRepo
              refMaterial
              refPlanchas
              qtyLen
              planchasXColor
              ordenCompra
              expID
              pinSalida
              responsableSalida
              responsableNombre
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ordenMaterial {
          uuid
          opAncho
          opLargo
          cantidad
          caja
          area
          total
        }
        comprobanteProd {
          bucket
          region
          key
        }
        observaciones
        totalPlanchasOrden
        calibreOrden
        totalPlanchasExp
        calibresExp
        motivoDiferencia
        motivoReposicion
        reposicion
        responsableRepo
        refMaterial
        refPlanchas
        qtyLen
        planchasXColor
        ordenCompra
        expID
        infoRetal {
          uuid
          typeMaterial
          desperdicio
          porcentajeDesp
          anchoRetal
          largoRetal
          anchoLamina
          largoLamina
        }
        pinSalida
        responsableSalida
        responsableNombre
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrdenExposicions = /* GraphQL */ `
  query ListOrdenExposicions(
    $filter: ModelOrdenExposicionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdenExposicions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exposicionID
        ordenSalidaID
        exposicion {
          id
          plateName
          dateExposed
          plateType
          ciudad
          calibre
          len {
            lenId
            lenWidth
            lenHeight
            name
            uuid
            area
            image {
              bucket
              region
              key
            }
            op
            version
            reposicion
          }
          areaTotalLen
          imageExp {
            bucket
            region
            key
          }
          expoName
          taras
          responsablePin
          responsableId
          responsableName
          anulada
          responsableReposicion
          reposicionName
          reposicion
          referencia
          cajas
          cajaId
          caja {
            id
            ancho
            largo
            cantidadLaminas
            areaTotal
            calibre
            consecutivo
            areaRestante
            createdAt
            updatedAt
          }
          motivo
          pendienteSalida
          isPlate
          desperdicio
          desperdicioPerc
          anchoRetal
          largoRetal
          anchoLamina
          largoLamina
          ordenesSalida {
            items {
              id
              exposicionID
              ordenSalidaID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        ordenSalida {
          id
          op
          version
          clienteOrdenId
          clienteOrden
          nameJob
          ciudadOrden
          tipoJob
          vendedorId
          vendedor
          salidaProd
          exposiciones {
            items {
              id
              exposicionID
              ordenSalidaID
              createdAt
              updatedAt
            }
            nextToken
          }
          ordenMaterial {
            uuid
            opAncho
            opLargo
            cantidad
            caja
            area
            total
          }
          comprobanteProd {
            bucket
            region
            key
          }
          observaciones
          totalPlanchasOrden
          calibreOrden
          totalPlanchasExp
          calibresExp
          motivoDiferencia
          motivoReposicion
          reposicion
          responsableRepo
          refMaterial
          refPlanchas
          qtyLen
          planchasXColor
          ordenCompra
          expID
          infoRetal {
            uuid
            typeMaterial
            desperdicio
            porcentajeDesp
            anchoRetal
            largoRetal
            anchoLamina
            largoLamina
          }
          pinSalida
          responsableSalida
          responsableNombre
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
