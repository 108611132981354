import * as React from 'react';
import Divider from '@mui/material/Divider';
import { Grid, Typography } from '@mui/material';
import { ImageCDI } from '../components/ImageCDI';
import { ShowInfoExposicion } from './ShowInfoExposicion';
import { DialogStylized } from '../style/ExposicionStyles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LenContentView } from '../components/LenContentView';
import { ShowInDialogButton } from '@react-admin/ra-form-layout';
import { Labeled, TextField, NumberField, FunctionField, ReferenceField, useRecordContext, SimpleShowLayout } from 'react-admin';

export const ShowInfoMedidas = () => {

    const record = useRecordContext();
    const taras = record?.taras;
    const motivo = record?.motivo;
    const cajaId = record?.cajaId;
    const cajaRegex = cajaId?.match(/-(\d+)$/);
    const caja = cajaRegex ? cajaRegex[1] : null;
    const cajaFilter = caja === null;
    const responsableId = record?.responsableId;
    const responsableName = record?.responsableName;
    const responsableReposicion = record?.responsableReposicion;
    const reposicionFilter = record?.reposicionName && responsableReposicion;
    const lenEmpty = Array?.isArray(record?.len) && record?.len?.length === 0;
    const expoName = (record?.expoName || 'EXPOSICIÓN')?.toUpperCase()?.replace(/[_-]/g, ' ');
    const title = (<Typography style={DialogStylized?.showInfoMedidas?.title}>{expoName}</Typography>);

    return (
        <ShowInDialogButton
            label='Ver'
            title={title}
            maxWidth='lg'
            icon={<VisibilityIcon sx={DialogStylized?.showInfoMedidas?.dialog?.icon}/>}
            ButtonProps={{ size:'small', sx: DialogStylized?.showInfoMedidas?.dialog?.buttonProps?.sx }}
        >
            <SimpleShowLayout>
                <Grid sx={{ textAlign: 'center' }} container spacing={{ xs: 1, md: 2 }} flexDirection='row' justifyContent='space-evenly'>
                    {lenEmpty ? (
                        <span/>
                    ):(
                        <>
                            <Grid item xs={1} sm={12} md={3} lg={2} sx={{ '& img': { minHeight: 250 }}}>
                                <ImageCDI source='imageExp' label=''/>
                            </Grid>
                            <Divider orientation='vertical' flexItem variant='middle'/>
                        </>
                    )}
                    <Grid item xs={12} sm={12} md={3} lg={4.5}>
                        <ShowInfoExposicion/>
                    </Grid>
                    {(responsableId && responsableName && cajaId && taras !== null) && (
                        <>
                            <Divider orientation='vertical' flexItem variant='middle'/>
                            <Grid item xs={12} sm={12} md={2} lg={1.5} sx={DialogStylized?.showInfoMedidas?.grid1}>
                                <Grid item sx={{ marginBottom: '2rem' }}>
                                    <Labeled>
                                        <ReferenceField label='RESPONSABLE' source='responsableId' reference='usuarios' link={false}>
                                            <FunctionField label='RESPONSABLE' style={{ fontWeight: 'bold' }} render={record => `${record?.nombres} ${record?.apellidos}`}/>
                                        </ReferenceField>
                                    </Labeled>
                                </Grid>
                                <Grid item sx={{ marginBottom: '2rem' }}>
                                    {cajaFilter ? (
                                        <Labeled>
                                            <TextField label='# CAJA' source='cajaId' style={{ fontWeight: 'bold' }} />
                                        </Labeled>
                                    ) : (
                                        <Labeled>
                                            <ReferenceField label='# CAJA' source='cajaId' reference='stocks' link={false}>
                                                <FunctionField label='CAJA' style={{ fontWeight: 'bold' }} render={record => `${record?.consecutivo}`}/>
                                            </ReferenceField>
                                        </Labeled>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Labeled>
                                        <NumberField source='taras' label='# TARA' style={{ fontWeight: 'bold' }}/>
                                    </Labeled>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {(responsableId && responsableName && (cajaId === null || taras === null)) && (
                        <>
                            <Divider orientation='vertical' flexItem variant='middle'/>
                            <Grid item rowSpacing={2} sx={{ textAlign: 'center' }}  justifyContent='space-between' xs={12} sm={12} md={2} lg={1.5} style={{ marginTop: motivo ? '2em' : '5rem' }}>
                                <Grid item mt={-0.5}>
                                    <Labeled>
                                        <ReferenceField label='RESPONSABLE ANULACIÓN' source='responsableId' reference='usuarios' link={false}>
                                            <FunctionField label='RESPONSABLE' style={{ fontWeight: 'bold' }} render={record => `${record.nombres} ${record.apellidos}`} />
                                        </ReferenceField> 
                                    </Labeled>
                                </Grid>
                                {motivo && (
                                    <Grid item style={{ marginTop: '3rem', textAlign: 'center' }}>
                                        <Labeled>
                                            <TextField source='motivo' label='MOTIVO ANULACIÓN' style={{ fontWeight: 'bold' }}/>
                                        </Labeled>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                    {reposicionFilter && (
                        <>
                            <Divider orientation='vertical' flexItem variant='middle'/>
                            <Grid item rowSpacing={2} sx={{ textAlign: 'center'}} justifyContent='space-between' xs={12} sm={12} md={2} lg={1.5} style={{ marginTop: motivo ? '2em' : '5rem' }}>
                                <Grid item>
                                    <Labeled>
                                        <ReferenceField label='RESPONSABLE REPOSICIÓN' source='responsableReposicion' reference='usuarios' link={false}>
                                            <FunctionField label='RESPONSABLE' style={{ fontWeight: 'bold' }} render={record => `${record?.nombres} ${record?.apellidos}`}/>
                                        </ReferenceField>
                                    </Labeled>
                                </Grid>
                                {motivo && (
                                    <Grid item style={{ marginTop: '3rem', textAlign: 'center' }}>
                                        <Labeled>
                                            <TextField source='motivo' label='MOTIVO REPOSICIÓN' style={{ fontWeight: 'bold'}}/>
                                        </Labeled>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid columns={{ xs: 4, sm: 8, md: 12 }} flexDirection='row' justifyContent='space-evenly'>
                    <Grid maxWidth='xl'>
                        <LenContentView/>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </ShowInDialogButton>
    );
};